import SelectOption from '../../../../components/common/CustomSelect/SelectOption';
import { OfferCategory, OfferServiceSpecialsCategory, PricingOption } from '../../../../gql/generated';

type InputTypes = OfferCategory | PricingOption | OfferServiceSpecialsCategory;

const getSelectOptions = (input: InputTypes[] | undefined) => {
  return input
    ? input.map<SelectOption>(cat => ({
        value: cat.id,
        label: cat.name,
      }))
    : [];
};

export default getSelectOptions;
