import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import Dropdown from '../../../common/Dropdown';
import DropdownContent from '../../../common/DropdownContent';
import styles from './styles.module.scss';

const HeaderLogo = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <Dropdown>
      <div className={styles.link} onClick={() => setIsComponentVisible(true)} onKeyPress={() => {}} role="presentation">
        <img src="/toy_header_logo.svg" alt="Toyota Logo" />
      </div>
      <DropdownContent ref={ref} open={isComponentVisible}>
        <ul className={styles.nav}>
          <li>
            <NavLink to="/settings">
              <FontAwesomeIcon icon={faCog} className={styles.settingsIcon} /> Settings
            </NavLink>
          </li>
        </ul>
      </DropdownContent>
    </Dropdown>
  );
};

export default HeaderLogo;
