import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface WrapperProps {
  children?: ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default Wrapper;
