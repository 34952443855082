import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../components/common/Button';
import DateFormat from '../../../../components/common/DateFormat';
import DealerGroupModal from '../DealerGroupModal';
import DealerGroupTable from '../DealerGroupTable';
import Modal from '../../../../components/common/Modal';
import ModalBody from '../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../components/common/Modal/ModalHeader';
import PageHeader from '../../../../components/layout/PageHeader';
import withUser, { WithUserProps } from '../../../../hoc/withUser';
import { DealerGroupsType, DealerGroupType } from '../../../../models/dealerGroup/DealerGroup';
import styles from './styles.module.scss';

interface DealerGroupProps extends WithUserProps {
  dealerGroups: DealerGroupsType;
  onDelete: (dealerGroup: DealerGroupType) => void;
  onCreate: (name: string, dealersList: string[]) => void;
  onUpdate: (dealerGroupId: string, rev: string, name: string, dealersList: string[]) => void;
}

const DealerGroupComponent = ({ dealerGroups, onCreate, onDelete, onUpdate }: DealerGroupProps) => {
  const [dealerGroupForDelete, setDealerGroupForDelete] = useState<DealerGroupType>(undefined);
  const [dealerGroupForEdit, setDealerGroupForEdit] = useState<DealerGroupType>(undefined);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOnDelete = (dealerGroup: DealerGroupType) => {
    if (dealerGroup) {
      onDelete(dealerGroup);
      setDealerGroupForDelete(undefined);
    }
  };

  return (
    <>
      <PageHeader>
        <h2>Dealer Groups</h2>
      </PageHeader>

      <div className={styles.dealerGroupTable}>
        <DealerGroupTable>
          <thead>
            <tr>
              <td>Dealer Group</td>
              <td>Modified by</td>
              <td>Modified date</td>
              <td aria-label="empty-td" />
            </tr>
          </thead>
          <tbody>
            {dealerGroups?.map(dealerGroup => (
              <tr key={dealerGroup?.id}>
                <td>{dealerGroup?.name}</td>
                <td>{dealerGroup?.updatedBy}</td>
                <td>
                  <DateFormat format="MM/DD/YYYY">{dealerGroup?.updated}</DateFormat>
                </td>
                <td>
                  <>
                    <button id={`edit-btn-${dealerGroup?.id}`} type="button" className={styles.tableBtn}>
                      <FontAwesomeIcon icon={faPencilAlt} onClick={() => setDealerGroupForEdit(dealerGroup)} />
                    </button>
                    <button id={`delete-btn-${dealerGroup?.id}`} type="button" className={styles.tableBtn} onClick={() => setDealerGroupForDelete(dealerGroup)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </>
                </td>
              </tr>
            ))}
          </tbody>
        </DealerGroupTable>
        <div>
          <button id="new-image-btn" type="button" className={styles.addBtn} onClick={() => setOpenModal(true)}>
            <FontAwesomeIcon icon={faPlusCircle} className={styles.addBtnIcon} /> Add New Dealer Group
          </button>
        </div>

        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <DealerGroupModal onClose={() => setOpenModal(false)} onCreate={onCreate} />
        </Modal>
        <Modal isOpen={dealerGroupForEdit !== undefined} onClose={() => setDealerGroupForEdit(undefined)}>
          <DealerGroupModal dealerGroup={dealerGroupForEdit} onClose={() => setDealerGroupForEdit(undefined)} onUpdate={onUpdate} />
        </Modal>
        <Modal isOpen={dealerGroupForDelete !== undefined} onClose={() => setDealerGroupForDelete(undefined)}>
          <ModalHeader title="Delete Dealer Group" onClose={() => setDealerGroupForDelete(undefined)} />
          <ModalBody>
            <>Are you sure you want to delete this Dealer Group?</>
          </ModalBody>
          <ModalFooter>
            <Button id="del-modal-btn" variant="primary" onClick={() => handleOnDelete(dealerGroupForDelete as DealerGroupType)}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default withUser(DealerGroupComponent);
