import { Maybe } from '../../gql/generated';

const stringArrayToMap = (array: Maybe<Maybe<string>[]>) => {
  return array?.reduce((result: any, item: any) => {
    return {
      ...result,
      [item]: true,
    };
  }, {});
};

export default stringArrayToMap;
