import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { ReactNode, useState } from 'react';
import styles from './styles.module.scss';

interface DashboardTableProps {
  title: string;
  id?: string;
  children?: ReactNode;
  collapsable?: boolean;
  defaultCollapsed?: boolean;
}

const DashboardTable = ({ id, title, children, collapsable, defaultCollapsed }: DashboardTableProps) => {
  const [collapsed, toggleCollapsed] = useState(defaultCollapsed);

  const handleOnClick = () => {
    if (collapsable) {
      toggleCollapsed(!collapsed);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <div role="presentation" className={cx(styles.titleContainer, collapsable && styles.collapsable)} onClick={handleOnClick}>
        <h3 className={styles.tableTitle}>{title}</h3>
        {collapsable && (
          <>
            <FontAwesomeIcon className={styles.chevron} icon={collapsed ? faChevronDown : faChevronUp} />
          </>
        )}
      </div>
      {!collapsed && (
        <table id={id} className={styles.table}>
          {children}
        </table>
      )}
    </div>
  );
};

export default DashboardTable;
