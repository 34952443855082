const settingsMenu = [
  {
    id: 'imageLibrary',
    text: 'Image Library',
  },

  {
    id: 'help',
    text: 'Help',
  },

  {
    id: 'disclaimers',
    text: 'Disclaimers',
  },
];

export default settingsMenu;
