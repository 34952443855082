import cx from 'clsx';
import React, { ReactNode, Ref } from 'react';
import styles from './styles.module.scss';

interface DropdownContentProps {
  open: boolean;
  children?: ReactNode;
  alignRight?: boolean;
}

const DropdownContent = React.forwardRef(({ open, children, alignRight }: DropdownContentProps, ref: Ref<HTMLDivElement>) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <div className={styles.dropdownContent}>
        <div className={cx(styles.dropdownMenu, alignRight && styles.alignRight)} ref={ref}>
          {children}
        </div>
      </div>
    </>
  );
});

export default DropdownContent;
