import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface OfferTabProps {
  selected?: boolean;
  children?: ReactNode;
}

const OfferTab = ({ selected, children }: OfferTabProps) => {
  return <li className={cx(styles.offerTab, selected && styles.selectedTab)}>{children}</li>;
};

export default OfferTab;
