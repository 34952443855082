import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface SettingsTableProps {
  children?: ReactNode;
}

const SettingsTable = ({ children }: SettingsTableProps) => {
  return <table className={styles.settingsTable}>{children}</table>;
};

export default SettingsTable;
