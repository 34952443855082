import cx from 'clsx';
import React, { InputHTMLAttributes, Ref } from 'react';
import styles from './styles.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const Input = React.forwardRef(({ className, error, ...rest }: InputProps, ref: Ref<HTMLInputElement>) => {
  return <input ref={ref} className={cx(className, styles.input, error && styles.error)} {...rest} />;
});

export default Input;
