import { Offer } from '../../gql/generated';
import { IOfferCreate } from '../../models/offers/IOffer';

const transformOfferForCreate = (offer: Offer): IOfferCreate => {
  return {
    amount: offer.amount || '',
    categoryId: offer.categoryId,
    couponCode: offer.couponCode as string,
    dealerCodes: offer.dealerCodes as string[],
    endDate: offer.endDate,
    pricingOptionId: offer.pricingOptionId || '',
    regionCodes: offer.regionCodes as string[],
    serviceSpecialsCategoryId: offer.serviceSpecialsCategoryId || '',
    startDate: offer.startDate,
    subTitle: offer.subTitle,
    title: offer.title,
    type: offer.type,
  };
};
export default transformOfferForCreate;
