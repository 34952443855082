import { Log, UserManager } from 'oidc-client-ts';

Log.setLogger(console);
Log.setLevel(Log.INFO);

const useUserManager = new UserManager({
  authority: process.env.REACT_APP_OIDC_AUTHORITY || '',
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID || '',
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI || '',
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_SIGNEDOUT_URI,
  response_type: 'code',
  scope: 'openid profile',
});

export default useUserManager;
