import SelectOption from '../../../../components/common/CustomSelect/SelectOption';
import Constants from '../../../../constants/Constants';

const NUM_REGEX = /^[0-9]+(\.[0-9]{1,2})?$/;

const getPricingAmountError = (pricingOptionId: string, pricingOptionsList: SelectOption[], amount: string) => {
  const pricingOption = pricingOptionsList.find(item => item.value === pricingOptionId);

  if (!pricingOption || pricingOption.label === Constants.PricingOptions.COMPLIMENTARY) {
    return '';
  }

  if (!NUM_REGEX.test(amount)) {
    return 'Invalid amount';
  }

  const numVal = Number(amount);
  if (pricingOption.label === Constants.PricingOptions.PERCENTAGE_AMOUNT && numVal > 100) {
    return 'Percentage amount cannot exceed 100';
  }

  return '';
};

export default getPricingAmountError;
