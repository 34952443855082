import React from 'react';
import Button from '../Button';
import Modal from '../Modal';
import ModalBody from '../Modal/ModalBody';
import ModalHeader from '../Modal/ModalHeader';
import styles from './styles.module.scss';

interface IdleTimerWarningProps {
  isOpen: boolean;
  onContinue: () => void;
}

const IdleTimerWarning = ({ isOpen, onContinue }: IdleTimerWarningProps) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title="Idle Timeout Warning" onClose={onContinue} />
      <ModalBody>
        <>Your Session is about to be timed out. Please indicate if you would like to continue:</>
      </ModalBody>
      <div className={`${styles.modalBtnWrapper}`}>
        <Button variant="primary" onClick={onContinue}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default IdleTimerWarning;
