import { faPen, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../../components/common/Button';
import ButtonGroup from '../../../../components/common/ButtonGroup';
import CustomSelect from '../../../../components/common/CustomSelect';
import SelectOption from '../../../../components/common/CustomSelect/SelectOption';
import Input from '../../../../components/common/Input';
import PageHeader from '../../../../components/layout/PageHeader';
import Wrapper from '../../../../components/layout/Wrapper';
import Constants from '../../../../constants/Constants';
import { OfferCategory } from '../../../../gql/generated';
import withUser, { WithUserProps } from '../../../../hoc/withUser';
import useSearchParams from '../../../../hooks/useSearchParams';
import { DashboardOffer } from '../../../../models/dashboard/DashboardOffer';
import truncateIds from '../../../../utils/tools/truncateIds';
import isOfferPublishable from '../../utils/isOfferPublishable';
import DashboardTable, { CtaContainer } from '../DashboardTable';
import StatusDropdown from '../DashboardTable/StatusDropdown';
import styles from './styles.module.scss';
import filterOffers from './utils/filterOffers';

interface DashboardComponentProps extends WithUserProps {
  activeOffers: DashboardOffer[];
  expiredOffers: DashboardOffer[];
  offerCategories: OfferCategory[];
  onDeleteOffer: (id: string, rev: string) => void;
  onPublish: () => void;
  onUpdateStatus: (id: string, rev: string, status: string) => void;
}

const DashboardComponent = ({ userType, activeOffers, offerCategories, expiredOffers, onDeleteOffer, onPublish, onUpdateStatus }: DashboardComponentProps) => {
  const filterParam = useSearchParams().get('filter');
  const filter = !filterParam || filterParam === 'Expired' ? 'All' : filterParam;
  const collapseExpired = filterParam !== 'Expired';

  const showPublish = activeOffers.some(item => isOfferPublishable(userType?.type || '', item.offerType, item.status));
  const [offerType, setOfferType] = useState(filter);
  const [offerCategory, setOfferCategory] = useState('All');
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState<string | null>(null);
  const [sortedOffers, setSortedOffers] = useState<DashboardOffer[]>([]);

  const offerCategoryOptions = useMemo(
    () => [
      { value: 'All', label: 'ALL' },
      ...offerCategories.map(cat => ({
        label: `${cat.name.toUpperCase()}`,
        value: cat.name,
      })),
    ],
    []
  );

  const offerTypeOptions = useMemo(
    () => [
      { value: 'All', label: 'ALL' },
      ...Object.values(Constants.OfferTypes).map(type => ({
        label: `${type.toUpperCase()}`,
        value: type,
      })),
    ],
    []
  );

  const handleOnOfferTypeSelect = (option: SelectOption) => {
    setOfferType(option.value);
  };
  const handleOnOfferCategorySelect = (option: SelectOption) => {
    setOfferCategory(option.value);
  };

  // filterData
  const filteredExpiredOffers = filterOffers(expiredOffers, offerType, offerCategory, searchText);

  // SortData
  const sortStartDateHandler = () => {
    if (sort === 'startDateDescend') {
      setSort('startDateAscend');
    } else if (sort === 'startDateAscend') {
      setSort('startDateDescend');
    } else {
      setSort('startDateDescend');
    }
  };

  useEffect(() => {
    const filteredActiveOffers = filterOffers(activeOffers, offerType, offerCategory, searchText);

    setSortedOffers(
      filteredActiveOffers.sort((a, b) => {
        const dateA = Number(new Date(a.startDate));
        const dateB = Number(new Date(b.startDate));
        if (sort === 'startDateDescend') {
          return dateB - dateA;
        }
        if (sort === 'startDateAscend') {
          return dateA - dateB;
        }
        return 0;
      })
    );
  }, [sort, offerType, offerCategory, searchText, activeOffers]);

  return (
    <>
      <div className={styles.activeContainer}>
        <Wrapper>
          <PageHeader>
            <h2>Dashboard</h2>

            {/* Publish and Create Ctas */}
            <div>
              <ButtonGroup noBottomMargin>
                {showPublish && (
                  <Button id="publish-btn" variant="primary" onClick={onPublish}>
                    Publish
                  </Button>
                )}
                <Button id="create-offer-btn" variant="primary" element="navlink" to="/createOffer">
                  Create Offer
                </Button>
              </ButtonGroup>
            </div>
          </PageHeader>

          {/* Filters */}
          <div className={styles.filtersContainer}>
            <div>
              <CustomSelect
                id="offerType-dd"
                className={styles.input}
                value={offerType}
                displayValue={`OFFER TYPE: ${offerType.toUpperCase()}`}
                onChange={handleOnOfferTypeSelect}
                options={offerTypeOptions}
                isSearchable={false}
              />
            </div>
            <div>
              <CustomSelect
                id="offerCat-dd"
                className={styles.input}
                value={offerCategory}
                displayValue={`OFFER CATEGORY: ${offerCategory.toUpperCase()}`}
                onChange={handleOnOfferCategorySelect}
                options={offerCategoryOptions}
                isSearchable={false}
              />
            </div>
            <div>
              <Input
                id="search-input"
                className={styles.input}
                value={searchText}
                placeholder="SEARCH"
                onChange={e => {
                  setSearchText(e.currentTarget.value);
                }}
              />
            </div>
          </div>

          {/* Dashboard Table */}
          <DashboardTable id="active-offers-table" title="Active Offers">
            <thead>
              <tr>
                <td>Offer Type</td>
                <td>Code</td>
                <td>Offer Category</td>
                <td>Offer Name</td>
                <td>Offer ID</td>
                <td>
                  <span className={styles.startDate} onClick={sortStartDateHandler} onKeyDown={sortStartDateHandler} role="button" tabIndex={0}>
                    Start Date
                  </span>
                  {sort === null ? null : (
                    <FontAwesomeIcon className={styles.arrowIcon} onClick={sortStartDateHandler} data-id="edit-cta" icon={sort === 'startDateDescend' ? faSortUp : faSortDown} />
                  )}
                </td>
                <td>End Date</td>
                <td>Offer Status</td>
              </tr>
            </thead>
            <tbody>
              {sortedOffers.map(offer => {
                const activeLink = `/viewOffer/info/${offer.offerId}`;

                return (
                  <tr key={offer.offerId} data-id={offer.offerId}>
                    <td>
                      <NavLink to={activeLink}>{offer.offerType}</NavLink>
                    </td>
                    <td>
                      <NavLink to={activeLink}>{offer.code}</NavLink>
                    </td>
                    <td className={styles.offerCategory}>
                      <NavLink to={activeLink}>{offer.offerCategory}</NavLink>
                    </td>
                    <td className={styles.offerName}>
                      <NavLink to={activeLink}>{offer.offerName}</NavLink>
                    </td>
                    <td>
                      <NavLink to={activeLink} title={offer.offerId}>
                        {truncateIds(offer.offerId)}
                      </NavLink>
                    </td>
                    <td>
                      <NavLink to={activeLink}>{offer.startDate}</NavLink>
                    </td>
                    <td>
                      <NavLink to={activeLink}>{offer.endDate}</NavLink>
                    </td>
                    <td>
                      <StatusDropdown
                        value={offer.status}
                        offerType={offer.offerType}
                        onDelete={() => onDeleteOffer(offer.offerId, offer.rev)}
                        onUpdate={status => onUpdateStatus(offer.offerId, offer.rev, status)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </DashboardTable>
        </Wrapper>
      </div>
      <div className={styles.expiredContainer}>
        <Wrapper>
          <DashboardTable id="expired-offers-table" title="Expired Offers" collapsable defaultCollapsed={collapseExpired}>
            <thead>
              <tr>
                <td>Offer Type</td>
                <td>Code</td>
                <td>Offer Category</td>
                <td>Offer Name</td>
                <td>Offer ID</td>
                <td>Start Date</td>
                <td>End Date</td>
                <td aria-label="empty-td" />
              </tr>
            </thead>
            <tbody>
              {filteredExpiredOffers.map(offer => {
                const expiredLink = `/viewOffer/info/${offer.offerId}`;

                return (
                  <tr className={styles.expiredRow} key={offer.offerId} data-id={offer.offerId}>
                    <td>
                      <NavLink to={expiredLink}>{offer.offerType}</NavLink>
                    </td>
                    <td>
                      <NavLink to={expiredLink}>{offer.code}</NavLink>
                    </td>
                    <td className={styles.offerCategory}>
                      <NavLink to={expiredLink}>{offer.offerCategory}</NavLink>
                    </td>
                    <td className={styles.offerName}>
                      <NavLink to={expiredLink}>{offer.offerName}</NavLink>
                    </td>
                    <td>
                      <NavLink to={expiredLink} title={offer.offerId}>
                        {truncateIds(offer.offerId)}
                      </NavLink>
                    </td>
                    <td>
                      <NavLink to={expiredLink}>{offer.startDate}</NavLink>
                    </td>
                    <td>
                      <NavLink to={expiredLink}>{offer.endDate}</NavLink>
                    </td>
                    <td>
                      <CtaContainer className={styles.ctas}>
                        <NavLink to={expiredLink}>
                          <FontAwesomeIcon data-id="edit-cta" icon={faPen} />
                        </NavLink>
                      </CtaContainer>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </DashboardTable>
        </Wrapper>
      </div>
    </>
  );
};

export default withUser(DashboardComponent);
