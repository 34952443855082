import React, { useEffect } from 'react';
import useUserManager from '../../hooks/useUserManager';
import Wrapper from '../layout/Wrapper';

interface AuthCodeHandlerProps {
  onSuccess: () => void;
  onError?: (err: Error) => void;
}

const AuthCodeHandler: React.FC<AuthCodeHandlerProps> = (props: AuthCodeHandlerProps) => {
  const { onSuccess, onError = undefined } = props;

  useEffect(() => {
    (async () => {
      try {
        const userMgr = useUserManager;
        await userMgr.signinRedirectCallback();
        onSuccess();
      } catch (err: any) {
        if (onError) {
          onError(err);
        }
      }
    })();
  }, [onSuccess, onError]);

  return (
    <Wrapper>
      <h1>Starting session...</h1>
    </Wrapper>
  );
};

export default AuthCodeHandler;
