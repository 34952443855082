import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ButtonGroupProps {
  children?: ReactNode;
  noBottomMargin?: boolean;
}

const ButtonGroup = ({ children, noBottomMargin }: ButtonGroupProps) => {
  return <div className={cx(styles.buttonGroup, noBottomMargin && styles.noBottomMargin)}>{children}</div>;
};

export default ButtonGroup;
