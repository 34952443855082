import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface SettingsContentProps {
  children?: ReactNode;
}

const SettingsContent = ({ children }: SettingsContentProps) => {
  return <div className={styles.settingsContent}>{children}</div>;
};

export default SettingsContent;
