import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const LogoutComponent = () => {
  return (
    <div className={styles.logoutContainer}>
      <div className={styles.wrapper}>
        <div>
          <Link to="/signout">
            <FontAwesomeIcon icon={faSignOutAlt} />
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LogoutComponent;
