import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { toast } from 'react-toastify';
import DataLoadingController from '../../../components/Auth/DataLoadingController';
import BlockUI from '../../../components/common/BlockUI';
import MainLayout from '../../../components/layout/MainLayout';
import Wrapper from '../../../components/layout/Wrapper';
import { Offer, UpdateOfferMutationVariables, useFetchOfferByIdQuery, useUpdateOfferMutation } from '../../../gql/generated';
import withUser, { WithUserProps } from '../../../hoc/withUser';
import InferredType from '../../../models/user/InferredType';
import getStringValue from '../../../utils/tools/getStringValue';
import OfferHeading from '../components/OfferHeading';
import tabs from '../tabs';
import Images from './components/ImagesComponent';

const ViewImagesController = ({ userType: initUserType }: WithUserProps) => {
  const userType = initUserType as InferredType;

  const [goBack, setGoBack] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { data: offerData, loading: offerLoading, error: offerError } = useFetchOfferByIdQuery({ variables: { id } });

  // const { data, loading, error } = useFetchImagesQuery({ variables: { type: userType.type } });
  const [updateOffer, { loading: updateOfferLoading }] = useUpdateOfferMutation();
  const offer = offerData?.offer;
  const readOnly = userType?.type !== offer?.type;

  /**
   * Save detail/thumbnail ids
   * @param details
   * @param thumbnail
   */
  const handleOnSave = async (redirectNext: boolean, doSave: boolean, detailsId?: string, thumbnailId?: string) => {
    setRedirect(redirectNext);
    if (doSave) {
      try {
        const { rev } = offer as Offer;
        const offerVars: UpdateOfferMutationVariables = {
          id,
          rev,
          detailsImageId: detailsId,
          thumbnailImageId: thumbnailId,
        };

        await updateOffer({ variables: offerVars });
        toast.success('Offer updated');
        setIsEditing(false);
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  if (goBack) {
    return <Redirect to={`/viewOffer/info/${offer?.id}`} />;
  }

  if (redirect) {
    return <Redirect to={`/viewOffer/marketing/${offer?.id}`} />;
  }

  return (
    <MainLayout>
      <Wrapper>
        <DataLoadingController
          component={(() => (
            <BlockUI blocking={updateOfferLoading || isEditing}>
              <OfferHeading title="View Offer" baseUrl="/viewOffer" tabs={tabs} selectedTab="images" offerId={offer?.id} />
              <Images
                offer={offer}
                defaultDetailsImageId={getStringValue(offer?.detailsImageId)}
                defaultDetailsImagePath={getStringValue(offer?.detailsImage)}
                defaultThumbnailImageId={getStringValue(offer?.thumbnailImageId)}
                defaultThumbnailImagePath={getStringValue(offer?.thumbnailImage)}
                onBack={() => setGoBack(true)}
                onSave={handleOnSave}
                readOnly={readOnly}
                setIsEditing={setIsEditing}
              />
            </BlockUI>
          ))()}
          isLoaded={!offerLoading}
          apolloErrors={[offerError]}
        />
      </Wrapper>
    </MainLayout>
  );
};

export default withUser(ViewImagesController);
