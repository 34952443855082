import { DashboardOffer } from '../../../../../models/dashboard/DashboardOffer';

const filterOffers = (activeOffers: DashboardOffer[], offerType: string, offerCategory: string, searchText: string) => {
  const loweredSearchText = searchText.toLowerCase();
  return activeOffers.filter(offer => {
    return (
      offer.isDeleted !== true &&
      (offerType === 'All' || offer.offerType === offerType) &&
      (offerCategory === 'All' || offer.offerCategory === offerCategory) &&
      (!searchText ||
        (offer.code && offer.code.toLowerCase().indexOf(loweredSearchText) !== -1) ||
        offer.offerName.toLowerCase().indexOf(loweredSearchText) !== -1 ||
        offer.offerCategory.toLowerCase().indexOf(loweredSearchText) !== -1 ||
        offer.offerType.toLowerCase().indexOf(loweredSearchText) !== -1 ||
        offer.offerId.toLowerCase().indexOf(loweredSearchText) !== -1 ||
        offer.startDate.indexOf(loweredSearchText) !== -1 ||
        offer.endDate.indexOf(loweredSearchText) !== -1)
    );
  });
};

export default filterOffers;
