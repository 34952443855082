import React, { ReactNode } from 'react';
import Label from '../../../../../components/common/Label';
import styles from './styles.module.scss';

interface MarketingLabelContainerProps {
  label: string;
  children?: ReactNode;
}

const MarketingLabelContainer = ({ label, children }: MarketingLabelContainerProps) => {
  return (
    <div className={styles.container}>
      <Label className={styles.label}>{label}</Label>
      {children}
    </div>
  );
};

export default MarketingLabelContainer;
