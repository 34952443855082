import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.module.scss';
import Toast from './components/common/Toast';
import Routes from './Routes';
import useIdleTimer from './hooks/useIdleTimer';
import IdleTimerWarning from './components/common/IdleTimerWarning';

const App = () => {
  const { isTimeForWarning, isSessionExpired, onContinue } = useIdleTimer();

  return (
    <Router>
      <Toast />
      <Routes isSessionExpired={isSessionExpired} />
      <IdleTimerWarning isOpen={isTimeForWarning} onContinue={onContinue} />
    </Router>
  );
};

export default App;
