import React from 'react';
import Button from '../Button';
import styles from './styles.module.scss';

interface FileUploaderProps {
  buttonText: string;
  id?: string;
  disabled?: boolean;
  onUpload: (file: File | null) => void;
}

const FileUploader = ({ id, buttonText, disabled, onUpload }: FileUploaderProps) => {
  const handleOnUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpload(e.currentTarget.files ? e.currentTarget.files[0] : null);
  };

  return (
    <div className={styles.fileUploader}>
      <Button variant="primary" disabled={disabled}>
        {buttonText}
      </Button>
      <input type="file" id={id} onChange={handleOnUpload} disabled={disabled} />
    </div>
  );
};

export default FileUploader;
