import React from 'react';
import { toast } from 'react-toastify';
import apolloClient from '../../../components/Auth/apolloClient';
import DataLoadingController from '../../../components/Auth/DataLoadingController';
import MainLayout from '../../../components/layout/MainLayout';
import Wrapper from '../../../components/layout/Wrapper';
import {
  FetchImagesDocument,
  GetPresignedUrlDocument,
  Image,
  useCreateImageMutation,
  useDeleteImageMutation,
  useFetchImagesQuery,
  useUpdateImageMutation,
} from '../../../gql/generated';
import withUser, { WithUserProps } from '../../../hoc/withUser';
import { CustomUser } from '../../../models/user/User';
import UserType from '../../../models/user/UserType';
import uploadWithPresignedUrl from '../../../utils/tools/uploadWithPresignedUrl';
import getCreateImageVars from '../../offer/images/utils/getCreateImageVars';
import getPresignedVars from '../../offer/images/utils/getPresignedVars';
import SettingsContainer from '../components/SettingsContainer';
import SettingsContent from '../components/SettingsContent';
import SettingsNav from '../components/SettingsNav';
import settingsMenu from '../settingsMenu';
import ImageLibraryComponent from './components/ImageLibraryComponent';

const ImageLibraryController = ({ user }: WithUserProps) => {
  const { data, loading, error } = useFetchImagesQuery({ variables: { type: UserType.NATIONAL_SETTINGS } });
  const [deleteImage] = useDeleteImageMutation({ refetchQueries: [{ query: FetchImagesDocument, variables: { type: UserType.NATIONAL_SETTINGS } }] });
  const [createImage] = useCreateImageMutation({ refetchQueries: [{ query: FetchImagesDocument, variables: { type: UserType.NATIONAL_SETTINGS } }] });
  const [updateImage] = useUpdateImageMutation();

  const handleOnAddSave = async (name: string, file: File) => {
    try {
      const response = await apolloClient.query({ query: GetPresignedUrlDocument, variables: getPresignedVars(user as CustomUser, UserType.NATIONAL_SETTINGS, file) });
      await uploadWithPresignedUrl(response.data.presignedUploadUrl, file);
      await createImage({ variables: getCreateImageVars(user as CustomUser, UserType.NATIONAL_SETTINGS, file.name, name) });
      toast.success('Image successfully added');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleOnUpdateSave = async (name: string, image: Image) => {
    try {
      await updateImage({
        variables: {
          ...image,
          name,
        },
      });
      toast.success('Image succesfully updated');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleOnDeleteImage = async (image: Image) => {
    if (image) {
      try {
        await deleteImage({ variables: { id: image.id, rev: image.rev } });
        toast.success('Image successfully deleted');
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  return (
    <MainLayout>
      <Wrapper>
        <DataLoadingController
          component={
            <SettingsContainer>
              <SettingsNav settingsMenu={settingsMenu} activeLink="imageLibrary" />
              <SettingsContent>
                <ImageLibraryComponent images={data?.images as Image[]} onDelete={handleOnDeleteImage} onAdd={handleOnAddSave} onUpdate={handleOnUpdateSave} />
              </SettingsContent>
            </SettingsContainer>
          }
          isLoaded={!loading}
          apolloErrors={[error]}
        />
      </Wrapper>
    </MainLayout>
  );
};

export default withUser(ImageLibraryController);
