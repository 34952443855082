import cx from 'clsx';
import React, { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

type JustifyContentType = 'flex-start' | 'flex-end' | 'center' | 'space-between';

interface FlexBoxProps extends HTMLAttributes<HTMLDivElement> {
  justifyContent?: JustifyContentType;
  alignItems?: 'center';
}

const FlexBox = ({ className, justifyContent, alignItems, ...rest }: FlexBoxProps) => {
  const flexStyles = {
    justifyContent,
    alignItems,
  };

  return <div style={flexStyles} className={cx(className, styles.flex)} {...rest} />;
};

export default FlexBox;
