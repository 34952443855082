import Axios from 'axios';
import React, { useEffect } from 'react';
import useUserManager from '../../hooks/useUserManager';
import useUserProfile from '../../hooks/useUserProfile';
import getRefreshToken from '../../utils/tools/getRefreshToken';
import Wrapper from '../layout/Wrapper';

const SignOutRedirect: React.FC = () => {
  const [user] = useUserProfile();
  const userMgr = useUserManager;

  localStorage.removeItem('_expiredTime');
  const revokeToken = async () => {
    const token: string = getRefreshToken();
    await Axios.post(`${process.env.REACT_APP_OIDC_AUTHORITY}token/revoke`, { token: `${token}`, client_id: process.env.REACT_APP_OIDC_CLIENT_ID });
  };

  useEffect(() => {
    if (user) {
      revokeToken();
      userMgr.signoutRedirect({
        id_token_hint: user.id_token,
      });
    }
  }, [user]);

  return (
    <Wrapper>
      <h1>Redirecting...</h1>
    </Wrapper>
  );
};

export default SignOutRedirect;
