import { GetPresignedUrlQueryVariables } from '../../../../gql/generated';
import { CustomUser } from '../../../../models/user/User';
import UserType from '../../../../models/user/UserType';

const getPresignedVars = (user: CustomUser, userType: UserType, file: File, offerId?: string) => {
  const presignedVars: GetPresignedUrlQueryVariables = {
    type: userType,
    fileName: file.name,
  };

  if (userType === UserType.NATIONAL) {
    presignedVars.offerId = offerId;
  }

  if (userType === UserType.DEALER) {
    presignedVars.dealerCode = user.profile.primary_dealer_number;
    presignedVars.offerId = offerId;
  }

  if (userType === UserType.REGIONAL) {
    presignedVars.regionCode = user.profile.tmscorporateregioncode;
    presignedVars.offerId = offerId;
  }

  return presignedVars;
};

export default getPresignedVars;
