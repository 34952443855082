import React from 'react';
import { toast } from 'react-toastify';
import apolloClient from '../../components/Auth/apolloClient';
import DataLoadingController from '../../components/Auth/DataLoadingController';
import MainLayout from '../../components/layout/MainLayout';
import { GetPresignedReportDownloadUrlDocument, useCreateReportMutation, useFetchReportsQuery } from '../../gql/generated';
import useUserProfile from '../../hooks/useUserProfile';
import UserType from '../../models/user/UserType';
import getStringValue from '../../utils/tools/getStringValue';
import ReportsComponent from './components/ReportsComponent';

const ReportsController = () => {
  const [user, userType] = useUserProfile();

  const { data, loading, error, refetch } = useFetchReportsQuery({
    variables: {
      userType: getStringValue(userType?.type),
      regionCode: getStringValue(user?.profile?.tmscorporateregioncode),
      dealerCode: getStringValue(user?.profile?.dealer_number),
    },
    fetchPolicy: 'network-only',
  });

  const [createReport] = useCreateReportMutation();

  /**
   * Gets passed down to child component to be used there. Returns type Promise<Image | undefined>
   */
  const handleOnGenerateReports = async (type: string, kind: string, startDate: string, endDate: string, regionCode?: string) => {
    try {
      const regionParam = type === UserType.NATIONAL ? undefined : regionCode;
      await createReport({ variables: { userType: userType?.type || '', type, kind, startDate, endDate, regionCode: regionParam } });
      refetch();
      toast.success('Report generated sucessfully');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleDownloadReports = async (reportId: string, name: string) => {
    try {
      const response = await apolloClient.query({ query: GetPresignedReportDownloadUrlDocument, variables: { userType: userType?.type, reportId } });
      const anchor = document.createElement('a');
      anchor.href = response.data.presignedReportDownloadUrl;
      anchor.download = name;
      anchor.click();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <MainLayout>
      <DataLoadingController
        isLoaded={!loading}
        component={
          <ReportsComponent
            reports={data?.reports || []}
            isNational={userType?.type === UserType.NATIONAL}
            onGenerateReport={handleOnGenerateReports}
            downloadReport={handleDownloadReports}
          />
        }
        apolloErrors={[error]}
      />
    </MainLayout>
  );
};

export default ReportsController;
