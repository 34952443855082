import cx from 'clsx';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

type ButtonVariant = 'default' | 'primary';

interface ButtonProps {
  id?: string;
  element?: 'button' | 'div' | 'navlink';
  className?: string;
  submitType?: boolean;
  variant?: ButtonVariant;
  children?: ReactNode;
  disabled?: boolean;
  to?: string;
  onClick?: () => void;
}

const Button = ({ id, variant = 'default', to, submitType, className, element, children, disabled, onClick = () => {} }: ButtonProps) => {
  const cssStyles = cx(styles.button, styles[variant], className);

  if (element === 'navlink' && to) {
    return (
      <NavLink id={id} to={to} className={cssStyles}>
        {children}
      </NavLink>
    );
  }

  if (element === 'div') {
    return (
      <div id={id} className={cssStyles}>
        {children}
      </div>
    );
  }

  return (
    <button id={id} className={cssStyles} type={submitType ? 'submit' : 'button'} onClick={() => onClick()} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
