import Axios from 'axios';
import DealerResponse from '../../models/dis/DealersResponse';

const getDealersByDealerCode = (dealerCode: string) => {
  const params = {
    brandId: '1',
    resultsFormat: 'json',
    resultsMode: 'starOnly',
    includePuertoRico: 'false',
    searchMode: 'filterOnly',
    dealerCode,
  };

  const headers = {
    'x-api-key': process.env.REACT_APP_DIS_API_KEY,
  };

  const url = `${process.env.REACT_APP_DIS_API_URL}/disrest/getDealers`;
  return Axios.get<DealerResponse>(url, { headers, params });
};

export default getDealersByDealerCode;
