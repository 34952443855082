import React, { ComponentType } from 'react';
import useUserProfile from '../hooks/useUserProfile';
import InferredType from '../models/user/InferredType';
import { CustomUser } from '../models/user/User';

export interface WithUserProps {
  user?: CustomUser | null;
  userType?: InferredType | null;
}

const withUser = <T extends WithUserProps = WithUserProps>(Component: ComponentType<T>) => {
  const ComponentWithProps = (props: Omit<T, keyof WithUserProps>) => {
    const [user, userType, pending] = useUserProfile();
    return pending ? <></> : <Component user={user} userType={userType} {...(props as T)} />;
  };

  ComponentWithProps.displayName = 'WithUserComponent';

  return ComponentWithProps;
};

export default withUser;
