import { CustomUser } from '../../../models/user/User';
import UserType from '../../../models/user/UserType';

const getPublishVariables = (type: string, user: CustomUser) => {
  if (type === UserType.REGIONAL) {
    return {
      type,
      regionCode: user.profile.tmscorporateregioncode,
    };
  }

  if (type === UserType.DEALER) {
    return {
      type,
      dealerCode: user.profile.primary_dealer_number,
    };
  }

  return {
    type,
  };
};

export default getPublishVariables;
