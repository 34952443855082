import cx from 'clsx';
import React from 'react';
import Select, { components, SingleValueProps } from 'react-select';
import SelectOption from './SelectOption';
import styles from './styles.module.scss';

interface CustomSelectProps {
  id?: string;
  options: SelectOption[];
  value?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  isSearchable?: boolean;
  displayValue?: string;
  onChange?: (option: SelectOption) => void;
  isClearable?: boolean;
}

const CustomSingleValue = (displayValue: string) => {
  return ({ children, ...props }: SingleValueProps<SelectOption>) => {
    const { getValue } = props;
    const values = (getValue ? getValue() : []) as SelectOption[];
    return <components.SingleValue {...props}>{displayValue || values[0].label}</components.SingleValue>;
  };
};

const CustomSelect = ({ id, options, value, error, className, disabled, isSearchable, displayValue, onChange = () => {}, isClearable = false }: CustomSelectProps) => {
  const option = options.find(item => item.value === value);
  const selectComponents = displayValue ? { SingleValue: CustomSingleValue(displayValue) } : undefined;

  return (
    <Select
      id={id}
      value={option}
      className={cx(styles.customSelect, className, error && styles.error)}
      options={options}
      onChange={opt => onChange(opt as SelectOption)}
      isDisabled={disabled}
      isSearchable={isSearchable}
      components={selectComponents}
      isClearable={isClearable}
    />
  );
};

export default CustomSelect;
