import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { toast } from 'react-toastify';
import DataLoadingController from '../../../components/Auth/DataLoadingController';
import BlockUI from '../../../components/common/BlockUI';
import MainLayout from '../../../components/layout/MainLayout';
import Wrapper from '../../../components/layout/Wrapper';
import { Consumer, Offer, UpdateOfferMutationVariables, useFetchConsumersQuery, useFetchOfferByIdQuery, useUpdateOfferMutation } from '../../../gql/generated';
import withUser, { WithUserProps } from '../../../hoc/withUser';
import OfferHeading from '../components/OfferHeading';
import tabs from '../tabs';
import MarketingComponent from './components/MarketingComponent';

const ViewMarketingController = ({ userType }: WithUserProps) => {
  const [goBack, setGoBack] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { data: offerData, loading: offerLoading, error: offerError } = useFetchOfferByIdQuery({ variables: { id } });
  const { data: consumersData, loading: consumersLoading, error: consumersError } = useFetchConsumersQuery();
  const [updateOffer, { loading: updateOfferLoading }] = useUpdateOfferMutation();

  const offer = offerData?.offer;
  const readOnly = userType?.type !== offer?.type;

  const handleOnSave = async (doSave = false, newDescription?: string, newDisclaimers?: string[], newConsumers?: string[]) => {
    if (doSave) {
      try {
        const { rev } = offer as Offer;
        const offerVars: UpdateOfferMutationVariables = {
          id,
          rev,
          description: newDescription,
          disclaimers: newDisclaimers,
          consumerIds: newConsumers,
        };

        await updateOffer({ variables: offerVars });
        toast.success('Offer updated');
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  if (goBack) {
    return <Redirect to={`/viewOffer/images/${offer?.id}`} />;
  }
  return (
    <MainLayout>
      <Wrapper>
        <DataLoadingController
          component={(() => (
            <BlockUI blocking={updateOfferLoading}>
              <OfferHeading title="View Offer" baseUrl="/viewOffer" tabs={tabs} selectedTab="marketing" offerId={offer?.id} />
              <MarketingComponent
                readOnly={readOnly}
                consumers={consumersData?.consumers ? (consumersData.consumers as Consumer[]) : []}
                defaultDescription={offer?.description || ''}
                defaultDisclaimers={offer?.disclaimers as string[]}
                defaultConsumers={offer?.consumerIds as string[]}
                offer={offer}
                onBack={() => setGoBack(true)}
                onSave={handleOnSave}
              />
            </BlockUI>
          ))()}
          isLoaded={!consumersLoading && !offerLoading}
          apolloErrors={[consumersError, offerError]}
        />
      </Wrapper>
    </MainLayout>
  );
};

export default withUser(ViewMarketingController);
