import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './styles.module.scss';

interface ReportButtonProps {
  onClick: () => void;
  id?: string;
  children?: React.ReactNode;
}

const ReportButton = ({ id, onClick, children }: ReportButtonProps) => {
  return (
    <button type="button" className={styles.reportButton} id={id} onClick={onClick}>
      <FontAwesomeIcon className={styles.icon} icon={faClipboardList} />
      {children}
    </button>
  );
};

export default ReportButton;
