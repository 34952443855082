import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface HeaderTitleProps {
  children?: ReactNode;
}

const HeaderTitle = ({ children }: HeaderTitleProps) => {
  return <h1 className={styles.title}>{children}</h1>;
};

export default HeaderTitle;
