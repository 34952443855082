import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import Label from '../Label';
import styles from './styles.module.scss';

interface ListTextareaProps {
  id?: string;
  containerCss?: string;
  showDelete?: boolean;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  onDelete?: () => void;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const ListTextarea = ({ id, containerCss, showDelete, value, defaultValue, disabled, onDelete, onChange = () => {}, onBlur = () => {} }: ListTextareaProps) => {
  return (
    <div className={cx(styles.listTextarea, containerCss)}>
      <Label className={styles.label}>Text</Label>
      <textarea
        className={styles.textarea}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={e => onChange(e.currentTarget.value)}
        onBlur={e => onBlur(e.currentTarget.value)}
        disabled={disabled}
      />
      <div className={styles.ctas}>{showDelete && !disabled && <FontAwesomeIcon className={styles.icon} icon={faTrashAlt} onClick={onDelete} />}</div>
    </div>
  );
};

export default ListTextarea;
