import React, { useEffect, useState } from 'react';
import ReactImageUploading, { ImageListType } from 'react-images-uploading';
import Button from '../../../../../components/common/Button';
import FileUploaderContainer from '../../../../../components/common/FileUploaderContainer';
import Input from '../../../../../components/common/Input';
import Label from '../../../../../components/common/Label';
import Modal from '../../../../../components/common/Modal';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import { Image } from '../../../../../gql/generated';
import getS3ImageUrl from '../../utils/getS3ImageUrl';
import ImageLibrary from '../ImageLibraryModal';
import styles from './styles.module.scss';

interface ImagesUploaderProps {
  acceptType?: string[];
  defaultImagePath?: string;
  disabled?: boolean;
  id?: string;
  instructions?: string[];
  label: string;
  onFileUpload?: (file: File) => void;
  onImageRemove?: () => void;
  onImageSelect?: (image: Image) => void;
  refImageId?: string;
  setIsImageEdited: (isEdited: boolean) => void;
  setUrl: (url: string | undefined) => void;
  url: string | undefined;
}

const ImagesUploader = ({
  acceptType,
  defaultImagePath,
  disabled,
  id,
  instructions,
  label,
  onFileUpload = () => {},
  onImageRemove = () => {},
  onImageSelect = () => {},
  refImageId,
  setIsImageEdited,
  setUrl,
  url,
}: ImagesUploaderProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [imagePath, setImagePath] = useState(defaultImagePath);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setImagePath(defaultImagePath);
  }, [defaultImagePath]);

  const handleOnLibrarySelect = (img: Image) => {
    setUrl(undefined);
    setImagePath(img.s3Key);
    onImageSelect(img);
  };

  const onChange = (imageList: ImageListType) => {
    setIsImageEdited(false);

    if (imageList.length && imageList[0].file) {
      onFileUpload(imageList[0].file);
    }
  };

  const removeImage = () => {
    setImagePath('');
    setUrl('');
    onImageRemove();
  };

  return (
    <ReactImageUploading
      value={[]}
      onChange={onChange}
      onError={e => {
        if (e?.acceptType) {
          setErrorMessage('File of this image is not supported. Please choose another Image');
        }
      }}
      acceptType={acceptType}
    >
      {({ onImageUpload }) => {
        return (
          <div className={styles.container}>
            <Label className={styles.label}>{label}</Label>
            <FileUploaderContainer>
              <Input id={`${id}-image-input`} value={imagePath} readOnly disabled />
              <Button id={`${id}-image-upload`} variant="primary" onClick={onImageUpload} disabled={disabled}>
                Upload Image
              </Button>
              <Button id={`${id}-library-upload`} variant="primary" onClick={() => setOpenModal(true)} disabled={disabled}>
                Choose from Image Library
              </Button>
              {(defaultImagePath || refImageId || url) && (
                <Button onClick={removeImage} variant="primary">
                  Remove image
                </Button>
              )}
            </FileUploaderContainer>
            {instructions && (
              <ul className={styles.instructions}>
                {instructions.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            )}
            {(url ?? imagePath) && (
              <div className={styles.previewContainer}>
                <img src={url ?? getS3ImageUrl(imagePath)} alt={imagePath} />
              </div>
            )}

            <Modal isOpen={!!errorMessage} onClose={() => setErrorMessage('')} size="md">
              <ModalHeader title="Image Validation" onClose={() => setErrorMessage('')} />
              <ModalBody>{errorMessage}</ModalBody>
              <ModalFooter>
                <Button id="select-btn" variant="primary" onClick={() => setErrorMessage('')}>
                  Select New Image
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="lg">
              <ImageLibrary onSelect={handleOnLibrarySelect} onClose={() => setOpenModal(false)} />
            </Modal>
          </div>
        );
      }}
    </ReactImageUploading>
  );
};

export default ImagesUploader;
