import React, { useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Button from '../../../../../components/common/Button';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import styles from './styles.module.scss';

interface ImageEditorProps {
  file: File | undefined;
  isEditing: boolean;
  maxHeight?: number;
  maxWidth?: number;
  onSave?: () => void;
  setErrorMessage: (message: string) => void;
  setImageFile?: (file: File) => void;
  setIsEditing: (isEditing: boolean) => void;
  setIsImageEdited: (isEdited: boolean) => void;
  setUrl: (url: string) => void;
  zoom: number;
}

const ImageEditor = ({ file, isEditing, maxHeight, maxWidth, onSave, setErrorMessage, setImageFile, setIsEditing, setIsImageEdited, setUrl, zoom }: ImageEditorProps) => {
  const [scale, setScale] = useState<number>(1);
  const [fileName, setFileName] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');
  const [editor, setEditor] = useState<any>();

  const handleScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScale(parseFloat(e.target.value));
  };

  useEffect(() => {
    if (file) {
      setFileName(file.name);
      setFileType(file.type);
    }
  }, [file]);

  const onClickSave = () => {
    setIsEditing(true);
    if (editor) {
      const url = editor.getImageScaledToCanvas().toDataURL(fileType);

      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const newFile = new File([blob], fileName, { type: fileType });

          if (newFile.size > 150000) {
            setErrorMessage('File is too large to upload for this image. Please adjust or choose another image');
            setIsEditing(false);
            setIsImageEdited(true);
          } else {
            if (setImageFile) {
              setImageFile(newFile);
            }
            setUrl(url);
            if (onSave) {
              setIsImageEdited(true);
              setTimeout(() => {
                onSave();
              }, 4000);
            }
          }
        });
    }
  };

  const setEditorRef = (newEditor: AvatarEditor | null) => {
    setEditor(newEditor);
  };

  const canvastyle = {
    zoom,
    border: '1px solid #333',
  };

  return (
    <>
      <div className={styles.container}>
        {file && (
          <>
            <AvatarEditor style={canvastyle} border={50} color={[229, 229, 229, 0.6]} height={maxHeight} image={file} ref={setEditorRef} scale={scale} width={maxWidth} />
            <div>
              <input defaultValue="1" max="2" step="0.01" className={styles.range} min="1" onChange={handleScale} type="range" />
            </div>
          </>
        )}
      </div>
      <ModalFooter>
        <Button className={styles.saveButton} onClick={onClickSave} variant="primary" disabled={!isEditing}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default ImageEditor;
