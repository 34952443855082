import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface FileUploaderContainerProps {
  children?: ReactNode;
}

const FileUploaderContainer = ({ children }: FileUploaderContainerProps) => {
  return <div className={styles.fileUploaderContainer}>{children}</div>;
};

export default FileUploaderContainer;
