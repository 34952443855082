import cx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface LabelProps {
  children?: string;
  className?: string;
}

const Label = ({ children, className }: LabelProps) => {
  return <span className={cx(styles.label, className)}>{children}</span>;
};

export default Label;
