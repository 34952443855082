import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { toast } from 'react-toastify';
import DataLoadingController from '../../../components/Auth/DataLoadingController';
import BlockUI from '../../../components/common/BlockUI';
import MainLayout from '../../../components/layout/MainLayout';
import Wrapper from '../../../components/layout/Wrapper';
import {
  Offer,
  OfferCategory,
  OfferServiceSpecialsCategory,
  PricingOption,
  useFetchOfferByIdQuery,
  useFetchOfferCategoriesQuery,
  useFetchOfferServiceSpecialsCategoriesQuery,
  useFetchPricingOptionsQuery,
  useUpdateOfferMutation,
} from '../../../gql/generated';
import withUser, { WithUserProps } from '../../../hoc/withUser';
import { IDealer } from '../../../models/dealers/IDealer';
import { IOfferUpdate } from '../../../models/offers/IOffer';
import UserType from '../../../models/user/UserType';
import getStringValue from '../../../utils/tools/getStringValue';
import transformDISResponse from '../../../utils/tools/transformDISResponse';
import transformOfferForUpdate from '../../../utils/tools/transformOfferForUpdate';
import getAllDealers from '../../../webapi/dis/getAllDealers';
import getDealersByDealerCode from '../../../webapi/dis/getDealersByDealerCode';
import getDealersByRegionCode from '../../../webapi/dis/getDealersByRegionCode';
import OfferHeading from '../components/OfferHeading';
import tabs from '../tabs';
import InformationComponent from './components/InformationComponent';

const ViewInformationController = ({ user, userType }: WithUserProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: offerData, loading: offerLoading, error: offerError } = useFetchOfferByIdQuery({ variables: { id } });
  const { data: offerCatData, loading: offerCatLoading, error: offerCatError } = useFetchOfferCategoriesQuery({ variables: { type: offerData?.offer?.type || UserType.NATIONAL } });
  const {
    data: offerSpecCatData,
    loading: offerSpecCatLoading,
    error: offerSpecCatError,
  } = useFetchOfferServiceSpecialsCategoriesQuery({ variables: { type: userType?.type as UserType } });
  const { data: pricingOptData, loading: pricingOptLoading, error: pricingOptError } = useFetchPricingOptionsQuery();

  const [updateOffer, { loading: updateOfferLoading }] = useUpdateOfferMutation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [dealers, setDealers] = useState<IDealer[]>([]);
  const [redirect, setRedirect] = useState(false);

  const readOnly = userType?.type !== offerData?.offer?.type;

  useEffect(() => {
    (async () => {
      try {
        if (user && offerData && !isLoaded) {
          if (offerData?.offer?.type === UserType.REGIONAL) {
            const regionCode = offerData?.offer?.regionCodes[0]?.substring(0, 2);
            const dealersResponse = await getDealersByRegionCode(getStringValue(regionCode));
            setDealers(transformDISResponse(dealersResponse.data));
            setIsLoaded(true);
          } else if (offerData?.offer?.type === UserType.DEALER) {
            const dealerCode = offerData?.offer?.dealerCodes[0];
            const dealersResponse = await getDealersByDealerCode(getStringValue(dealerCode));
            setDealers(transformDISResponse(dealersResponse.data));
            setIsLoaded(true);
          } else {
            const dealersResponse = await getAllDealers();
            setDealers(transformDISResponse(dealersResponse.data));
            setIsLoaded(true);
          }
        }
      } catch {
        setDataError(true);
        setIsLoaded(true);
      }
    })();
  }, [user, offerData]);

  const handleOnSave = async (offer: IOfferUpdate, redirectNext = false, doSave = false) => {
    setRedirect(redirectNext);
    if (doSave) {
      const offerForMutation = transformOfferForUpdate(offer as Offer);

      try {
        await updateOffer({
          variables: {
            ...offerForMutation,
            id: getStringValue(offer.id),
            rev: getStringValue(offer.rev),
          },
        });
        toast.success('Offer saved successfully!');
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  let offerInfo: any;
  if (offerData && offerData.offer) {
    const dealerCodes: string[] = [];
    offerData.offer.dealerCodes.forEach(item => {
      dealerCodes.push(getStringValue(item));
    });
    let disclaimers: string[] = [];
    if (offerData.offer.disclaimers) {
      disclaimers = offerData.offer.disclaimers.map(disclaimer => getStringValue(disclaimer));
    }

    offerInfo = {
      amount: getStringValue(offerData.offer.amount),
      categoryId: getStringValue(offerData.offer.categoryId),
      couponCode: getStringValue(offerData.offer.couponCode),
      dealerCodes,
      description: getStringValue(offerData.offer.description),
      detailsImage: getStringValue(offerData.offer.detailsImage),
      disclaimers,
      endDate: getStringValue(offerData.offer.endDate),
      id: getStringValue(offerData.offer.id),
      pricingOptionId: getStringValue(offerData.offer.pricingOptionId),
      regionCodes: [], // region codes will generated on save
      rev: getStringValue(offerData.offer.rev),
      serviceSpecialsCategoryId: getStringValue(offerData.offer.serviceSpecialsCategoryId),
      startDate: getStringValue(offerData.offer.startDate),
      status: getStringValue(offerData.offer.status),
      subTitle: getStringValue(offerData.offer.subTitle),
      thumbnailImage: getStringValue(offerData.offer.thumbnailImage),
      title: getStringValue(offerData.offer.title),
      type: getStringValue(offerData.offer.type),
    };
  }

  if (redirect) {
    return <Redirect to={`/viewOffer/images/${offerData?.offer?.id}`} />;
  }

  return (
    <MainLayout>
      <Wrapper>
        <DataLoadingController
          component={(() => (
            <BlockUI blocking={updateOfferLoading}>
              <OfferHeading title="View Offer" baseUrl="/viewOffer" tabs={tabs} selectedTab="info" offerId={offerData?.offer?.id} />
              <InformationComponent
                offer={offerInfo}
                dealers={dealers}
                dealersLoading={!isLoaded}
                onSave={handleOnSave}
                pricingOptions={pricingOptData?.pricingOptions as PricingOption[]}
                categories={offerCatData?.offerCategories as OfferCategory[]}
                serviceSpecialsCategories={offerSpecCatData?.offerServiceSpecialsCategories as OfferServiceSpecialsCategory[]}
                readOnly={readOnly}
                readOnlyDealers={readOnly}
                userType={userType}
              />
            </BlockUI>
          ))()}
          isLoaded={!pricingOptLoading && !offerCatLoading && !offerLoading && !offerSpecCatLoading}
          apolloErrors={[offerCatError, offerError, pricingOptError, offerSpecCatError]}
          error={dataError}
        />
      </Wrapper>
    </MainLayout>
  );
};

export default withUser(ViewInformationController);
