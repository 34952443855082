import cx from 'clsx';
import React, { ReactNode } from 'react';
import Label from '../Label';
import styles from './styles.module.scss';

interface LabelContainerProps {
  label: string;
  children?: ReactNode;
  vertical?: boolean; // sets layout in vertical orientation
  serviceSpecialsCategory?: boolean;
}

const LabelContainer = ({ label, children, vertical, serviceSpecialsCategory }: LabelContainerProps) => {
  return (
    <div className={cx(vertical && styles.labelContainerVertical, !vertical && styles.labelContainer, serviceSpecialsCategory && styles.serviceSpecialsCategory)}>
      <Label className={styles.label}>{label}</Label>
      {children}
    </div>
  );
};

export default LabelContainer;
