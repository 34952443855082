import React from 'react';
import styles from './styles.module.scss';

interface PageTitleProps {
  children?: React.ReactNode;
}

const PageTitle = ({ children }: PageTitleProps) => {
  return <h2 className={styles.pageTitle}>{children}</h2>;
};

export default PageTitle;
