import { useEffect, useRef, useState } from 'react';

const useIdleTimer = () => {
  // expire user total time in seconds
  const idleTime = 840;
  // idleTime - warningTime = popup, ms
  const warningTime = 60000;

  const [isTimeForWarning, setIsTimeForWarning] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const disableTrackerRef = useRef(false);
  const [user, setUser] = useState('');

  let timeoutTracker: NodeJS.Timeout;

  const updateTimeValue = () => {
    const time = `${Date.now() + idleTime * 1000}`;
    localStorage.setItem('_expiredTime', time);
    return time;
  };

  const handleWindowEventListener = () => {
    if (timeoutTracker) {
      clearTimeout(timeoutTracker);
    }

    if (disableTrackerRef.current) {
      return;
    }

    timeoutTracker = setTimeout(updateTimeValue, 300);
  };

  const tracker = () => {
    window.addEventListener('mousemove', handleWindowEventListener);
    window.addEventListener('scroll', handleWindowEventListener);
    window.addEventListener('keydown', handleWindowEventListener);
  };

  const cleanUp = (interval?: NodeJS.Timeout) => {
    if (interval) {
      clearInterval(interval);
    }
    window.removeEventListener('mousemove', handleWindowEventListener);
    window.removeEventListener('scroll', handleWindowEventListener);
    window.removeEventListener('keydown', handleWindowEventListener);
    localStorage.removeItem('_expiredTime');
  };

  const setWarningState = (value: boolean) => {
    setIsTimeForWarning(value);
    disableTrackerRef.current = value;
  };

  const onContinue = () => {
    setWarningState(false);
    updateTimeValue();
  };

  const onExpired = () => {
    setWarningState(false);
    setIsSessionExpired(true);
  };

  const showWarning = () => {
    setWarningState(true);
  };

  const checkExpiredTime = () => {
    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || '', 10);
    return !expiredTime || expiredTime < Date.now();
  };

  const expireUser = (interval?: NodeJS.Timeout) => {
    onExpired();
    cleanUp(interval);
  };

  const checkExpiredWarningTime = () => {
    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || '', 10);
    const isExpired = !expiredTime || expiredTime - warningTime < Date.now();
    if (isExpired) {
      showWarning();
    }

    return isExpired;
  };

  const startInterval = () => {
    let expiredUser = false;
    if (localStorage.getItem('_expiredTime')) {
      if (checkExpiredTime()) {
        expireUser();
        expiredUser = true;
      } else {
        checkExpiredWarningTime();
      }
    }

    if (expiredUser) {
      return;
    }

    if (!localStorage.getItem('_expiredTime')) {
      updateTimeValue();
    }

    const interval = setInterval(() => {
      if (checkExpiredTime()) {
        expireUser(interval);
      } else if (!checkExpiredWarningTime()) {
        if (disableTrackerRef.current) {
          onContinue();
        }
      }
    }, 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const storageItem = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`);
      if (storageItem) {
        setUser(storageItem);
        clearInterval(interval);
      }
    }, 1000);
  }, [user]);

  useEffect(() => {
    if (user) {
      startInterval();
      tracker();
    }
  }, [user]);

  return { isTimeForWarning, isSessionExpired, onContinue };
};

export default useIdleTimer;
