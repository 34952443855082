import React from 'react';
import useUserProfile from '../../../hooks/useUserProfile';
import UserType from '../../../models/user/UserType';
import Wrapper from '../Wrapper';
import NavBarLink from './NavBarLink';
import styles from './styles.module.scss';

const NavBar = () => {
  const [, userType] = useUserProfile();

  return (
    <nav className={styles.navbar}>
      <Wrapper>
        <ul className={styles.navList}>
          <li>
            <span>
              <NavBarLink id="navbar-welcome" to="/welcome">
                Welcome
              </NavBarLink>
            </span>
          </li>
          <li>
            <span>
              <NavBarLink id="navbar-dashboard" to="/dashboard">
                Dashboard
              </NavBarLink>
            </span>
          </li>
          <li>
            <span>
              <NavBarLink id="navbar-createoffer" to="/createOffer/info">
                Create Offer
              </NavBarLink>
            </span>
          </li>
          {userType?.type !== UserType.DEALER && (
            <li>
              <span>
                <NavBarLink id="navbar-dealergroup" to="/dealerGroup">
                  Dealer Group
                </NavBarLink>
              </span>
            </li>
          )}
          {userType?.type !== UserType.DEALER && (
            <li>
              <span>
                <NavBarLink id="navbar-reports" to="/reports">
                  Reports
                </NavBarLink>
              </span>
            </li>
          )}
          <li>
            <span>
              <NavBarLink id="navbar-help" to="/settings/help">
                Help
              </NavBarLink>
            </span>
          </li>
          <li>
            <span>
              <NavBarLink id="navbar-settings" to="/settings/imageLibrary">
                Settings
              </NavBarLink>
            </span>
          </li>
        </ul>
      </Wrapper>
    </nav>
  );
};

export default NavBar;
