import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import DataLoadingController from '../../../components/Auth/DataLoadingController';
import BlockUI from '../../../components/common/BlockUI';
import MainLayout from '../../../components/layout/MainLayout';
import Wrapper from '../../../components/layout/Wrapper';
import {
  FetchOffersDocument,
  FetchOffersQuery,
  Offer,
  OfferCategory,
  OfferServiceSpecialsCategory,
  PricingOption,
  useCreateOfferMutation,
  useFetchOfferCategoriesQuery,
  useFetchOfferServiceSpecialsCategoriesQuery,
  useFetchPricingOptionsQuery,
} from '../../../gql/generated';
import withUser, { WithUserProps } from '../../../hoc/withUser';
import { IDealer } from '../../../models/dealers/IDealer';
import { IOfferCreate } from '../../../models/offers/IOffer';
import InferredType from '../../../models/user/InferredType';
import { CustomUser } from '../../../models/user/User';
import UserType from '../../../models/user/UserType';
import transformDISResponse from '../../../utils/tools/transformDISResponse';
import transformOfferForCreate from '../../../utils/tools/transformOfferForCreate';
import getInferredType from '../../../utils/user/getInferredType';
import getAllDealers from '../../../webapi/dis/getAllDealers';
import getDealersByDealerCode from '../../../webapi/dis/getDealersByDealerCode';
import getDealersByRegionCode from '../../../webapi/dis/getDealersByRegionCode';
import OfferHeading from '../components/OfferHeading';
import tabs from '../tabs';
import InformationComponent from './components/InformationComponent';

const CreateInformationController = ({ user, userType }: WithUserProps) => {
  const [createOffer, { loading: createOfferLoading }] = useCreateOfferMutation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [dealers, setDealers] = useState<IDealer[]>([]);
  const [newOfferId, setNewOfferId] = useState<string | undefined>();
  const [redirect, setRedirect] = useState(false);
  const { data: offerCatData, loading: offerCatLoading, error: offerCatError } = useFetchOfferCategoriesQuery({ variables: { type: userType?.type as UserType } });
  const {
    data: offerSpecCatData,
    loading: offerSpecCatLoading,
    error: offerSpecCatError,
  } = useFetchOfferServiceSpecialsCategoriesQuery({ variables: { type: userType?.type as UserType } });
  const { data: pricingOptData, loading: pricingOptLoading, error: pricingOptError } = useFetchPricingOptionsQuery();

  useEffect(() => {
    (async () => {
      try {
        if (userType?.type === UserType.REGIONAL) {
          const regionCode = (user?.profile.tmscorporateregioncode as string).substring(0, 2);
          const dealersResponse = await getDealersByRegionCode(regionCode);
          setDealers(transformDISResponse(dealersResponse.data));
          setIsLoaded(true);
        } else if (userType?.type === UserType.DEALER) {
          const dealersResponse = await getDealersByDealerCode(user?.profile.primary_dealer_number || '');
          setDealers(transformDISResponse(dealersResponse.data));
          setIsLoaded(true);
        } else {
          const dealersResponse = await getAllDealers();
          setDealers(transformDISResponse(dealersResponse.data));
          setIsLoaded(true);
        }
      } catch {
        setDataError(true);
      }
    })();
  }, []);

  const handleOnSave = async (offer: IOfferCreate, redirectNext = false) => {
    try {
      setRedirect(redirectNext);
      const inferredType = getInferredType(user as CustomUser) as InferredType;
      const offerForMutation = transformOfferForCreate(offer as Offer);
      const newOffer = await createOffer({
        variables: {
          ...offerForMutation,
          type: inferredType.param,
        },
        update: (cache, { data }) => {
          try {
            const query = FetchOffersDocument;
            const variables = { active: true };
            const offersQuery = cache.readQuery<FetchOffersQuery>({ query, variables });
            const existingOffers = offersQuery && offersQuery.offers ? (offersQuery.offers as Offer[]) : [];
            const newData = data?.createOffer.offer as Offer;
            cache.writeQuery({ query, variables, data: { offers: [newData, ...existingOffers] } });
          } catch (e) {
            // eslint no-empty
          }
        },
      });
      toast.success('Offer created successfully!');
      setNewOfferId(newOffer.data?.createOffer.offer.id);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  if (newOfferId && redirect) {
    return <Redirect to={`/viewOffer/images/${newOfferId}`} />;
  }

  if (newOfferId) {
    return <Redirect to={`/viewOffer/info/${newOfferId}`} />;
  }

  return (
    <MainLayout>
      <Wrapper>
        <DataLoadingController
          component={(() => (
            <BlockUI blocking={createOfferLoading}>
              <OfferHeading title="Create Offer" baseUrl="/createOffer" tabs={tabs} selectedTab="info" disableTabs />
              <InformationComponent
                categories={offerCatData?.offerCategories as OfferCategory[]}
                dealers={dealers}
                dealersLoading={!isLoaded}
                onSave={handleOnSave}
                pricingOptions={pricingOptData?.pricingOptions as PricingOption[]}
                serviceSpecialsCategories={offerSpecCatData?.offerServiceSpecialsCategories as OfferServiceSpecialsCategory[]}
                userType={userType}
              />
            </BlockUI>
          ))()}
          isLoaded={!pricingOptLoading && !offerCatLoading && !offerSpecCatLoading}
          apolloErrors={[offerCatError, pricingOptError, offerSpecCatError]}
          error={dataError}
        />
      </Wrapper>
    </MainLayout>
  );
};

export default withUser(CreateInformationController);
