import React from 'react';
import { toast } from 'react-toastify';
import MainLayout from '../../../components/layout/MainLayout';
import Wrapper from '../../../components/layout/Wrapper';
import SettingsContainer from '../components/SettingsContainer';
import SettingsContent from '../components/SettingsContent';
import SettingsNav from '../components/SettingsNav';
import settingsMenu from '../settingsMenu';
import { DownloadUserGuideDocument } from '../../../gql/generated';
import apolloClient from '../../../components/Auth/apolloClient';
import styles from './styles.module.scss';

const HelpController = () => {
  const handleDownloadUserGuide = async () => {
    try {
      const response = await apolloClient.query({ query: DownloadUserGuideDocument });
      const anchor = document.createElement('a');
      anchor.href = response.data.downloadUserGuide.url;
      anchor.click();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <MainLayout>
      <Wrapper>
        <SettingsContainer>
          <SettingsNav settingsMenu={settingsMenu} activeLink="help" />
          <SettingsContent>
            <h1>Help</h1>
            <button type="button" className={styles.userGuideButton} onClick={handleDownloadUserGuide}>
              OAT TOOMS User Guide Documentation (PDF)
            </button>
          </SettingsContent>
        </SettingsContainer>
      </Wrapper>
    </MainLayout>
  );
};

export default HelpController;
