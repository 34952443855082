import { DashboardOfferFieldsFragment, Maybe, OfferCategory } from '../../../gql/generated';
import { DashboardOffer } from '../../../models/dashboard/DashboardOffer';
import UserType from '../../../models/user/UserType';
import getStringValue from '../../../utils/tools/getStringValue';

const transformOffersResponse = (data: Maybe<Array<Maybe<DashboardOfferFieldsFragment>>> | undefined, offerCategories: Maybe<Array<Maybe<OfferCategory>>> | undefined) => {
  const offers: DashboardOffer[] | undefined = data?.map(offer => {
    const isDeleted = Boolean(offer?.isDeleted);
    let code;
    if (offer?.type === UserType.DEALER) {
      const dealerCodes = offer?.dealerCodes?.filter(item => typeof item === 'string');
      code = dealerCodes.length ? dealerCodes[0] || undefined : undefined;
    }

    if (offer?.type === UserType.REGIONAL) {
      const regionCodes = offer?.regionCodes?.filter(item => typeof item === 'string');
      code = regionCodes.length ? regionCodes[0] || undefined : undefined;
    }

    return {
      offerType: getStringValue(offer?.type),
      code,
      offerCategory: offerCategories?.find(cat => cat?.id === offer?.categoryId)?.name || '',
      offerName: getStringValue(offer?.title),
      offerId: getStringValue(offer?.id),
      rev: getStringValue(offer?.rev),
      startDate: getStringValue(offer?.startDate),
      endDate: getStringValue(offer?.endDate),
      status: getStringValue(offer?.status),
      isDeleted,
    };
  });

  return offers || [];
};

export default transformOffersResponse;
