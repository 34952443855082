import { useEffect, useState } from 'react';
import InferredType from '../models/user/InferredType';
import { CustomUser } from '../models/user/User';
import getInferredType from '../utils/user/getInferredType';
import useUserManager from './useUserManager';

const useUserProfile = (): [CustomUser | null, InferredType | null, boolean] => {
  const userManager = useUserManager;
  const [pending, setPending] = useState(true);
  const [user, setUser] = useState<CustomUser | null>(null);

  useEffect(() => {
    (async () => {
      setUser(await userManager.getUser());
      setPending(false);
    })();
  }, [userManager]);

  const userType = getInferredType(user);
  return [user, userType, pending];
};

export default useUserProfile;
