import React from 'react';
import DataLoadingController from '../../components/Auth/DataLoadingController';
import MainLayout from '../../components/layout/MainLayout';
import { useFetchOfferTotalsQuery } from '../../gql/generated';
import useUserProfile from '../../hooks/useUserProfile';
import WelcomeComponent from './components/WelcomeComponent';

const WelcomeController = () => {
  const [user, userType] = useUserProfile();
  const { data, loading, error } = useFetchOfferTotalsQuery({ variables: { userType: userType?.param || '' }, fetchPolicy: 'network-only' });

  return (
    <MainLayout>
      <DataLoadingController component={<WelcomeComponent offerTotals={data?.offerTotals} name={user?.profile?.first_name} />} isLoaded={!loading} apolloErrors={[error]} />
    </MainLayout>
  );
};

export default WelcomeController;
