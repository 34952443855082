import React from 'react';
import { toast } from 'react-toastify';
import DataLoadingController from '../../components/Auth/DataLoadingController';
import MainLayout from '../../components/layout/MainLayout';
import Wrapper from '../../components/layout/Wrapper';
import { OfferCategory, useFetchDashboardDataQuery, usePublishOffersMutation, useUpdateOfferMutation, useUpdateOfferStatusMutation } from '../../gql/generated';
import useUserProfile from '../../hooks/useUserProfile';
import { DashboardOffer } from '../../models/dashboard/DashboardOffer';
import OfferStatus from '../../models/offers/OfferStatus';
import { CustomUser } from '../../models/user/User';
import UserType from '../../models/user/UserType';
import getStringValue from '../../utils/tools/getStringValue';
import DashboardComponent from './components/DashboardComponent';
import getPublishVariables from './utils/getPublishVariables';
import transformOfferCategories from './utils/transformOfferCategories';
import transformOffersResponse from './utils/transformOffersResponse';

const DashboardController = () => {
  const { data, loading, error, refetch } = useFetchDashboardDataQuery({ variables: { categoryType: UserType.NATIONAL }, fetchPolicy: 'network-only' });
  const [updateOfferStatus] = useUpdateOfferStatusMutation();
  const [publishOffers] = usePublishOffersMutation();
  const [user, userType] = useUserProfile();

  const activeOffers: DashboardOffer[] = transformOffersResponse(data?.active, data?.offerCategories);
  const expiredOffers: DashboardOffer[] = transformOffersResponse(data?.expired, data?.offerCategories);
  const offerCategories: OfferCategory[] = transformOfferCategories(data?.offerCategories);
  const [updateOffer] = useUpdateOfferMutation();

  const handleOfferStatus = async (id: string, rev: string, status: string) => {
    try {
      await updateOfferStatus({
        variables: {
          id,
          rev,
          status,
        },
      });

      toast.success('Successfully updated offer status');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleOnPublish = async () => {
    try {
      await publishOffers({ variables: getPublishVariables(getStringValue(userType?.type), user as CustomUser) });
      toast.success('Succesfully published offers');
      refetch();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleDeleteOffer = async (id: string, rev: string) => {
    try {
      const offerVars = {
        id,
        rev,
        isDeleted: true,
      };

      const updatedOffer = await updateOffer({ variables: offerVars });
      const offerRev = updatedOffer.data?.updateOffer.offer.rev;
      const offerId = updatedOffer.data?.updateOffer.offer.id;
      if (offerId && offerRev) {
        await handleOfferStatus(offerId, offerRev, OfferStatus.READY_TO_PUBLISH);
      }
      toast.success('Offer deleted successfully');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <MainLayout bgColorAlt={!!data}>
      <DataLoadingController
        component={
          <DashboardComponent
            activeOffers={activeOffers}
            expiredOffers={expiredOffers}
            offerCategories={offerCategories}
            onUpdateStatus={handleOfferStatus}
            onDeleteOffer={handleDeleteOffer}
            onPublish={handleOnPublish}
          />
        }
        loadingComponent={(() => (
          <Wrapper>
            <h2>Loading Dashboard...</h2>
          </Wrapper>
        ))()}
        isLoaded={!loading}
        apolloErrors={[error]}
      />
    </MainLayout>
  );
};

export default DashboardController;
