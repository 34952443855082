import cx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const OfferLabel = ({ children }: { children: React.ReactNode }) => {
  return <span className={styles.offerLabel}>{children}</span>;
};

const OfferCount = ({ id, children }: { id: string; children: React.ReactNode }) => {
  return (
    <span id={id} className={styles.offerCount}>
      {children}
    </span>
  );
};

interface OfferCountPanelsProps {
  totalOffers: number;
  dealerOffers: number;
  regionalOffers: number;
  nationalOffers: number;
  expiredOffers: number;
}

const OfferCountPanels = ({ totalOffers = 0, dealerOffers = 0, regionalOffers = 0, nationalOffers = 0, expiredOffers = 0 }: OfferCountPanelsProps) => {
  return (
    <section className={styles.offerCountPanels}>
      <Link className={cx(styles.total, styles.gridCell)} to="/dashboard">
        <OfferLabel>TOTAL OFFERS</OfferLabel>
        <OfferCount id="totalOffers">{totalOffers}</OfferCount>
      </Link>

      <Link className={styles.gridCell} to="/dashboard?filter=Dealer">
        <OfferLabel>DEALER OFFERS</OfferLabel>
        <OfferCount id="dealerOffers">{dealerOffers}</OfferCount>
      </Link>

      <Link className={styles.gridCell} to="/dashboard?filter=Regional">
        <OfferLabel>REGIONAL OFFERS</OfferLabel>
        <OfferCount id="regionalOffers">{regionalOffers}</OfferCount>
      </Link>

      <Link className={styles.gridCell} to="/dashboard?filter=National">
        <OfferLabel>NATIONAL OFFERS</OfferLabel>
        <OfferCount id="nationalOffers">{nationalOffers}</OfferCount>
      </Link>

      <Link className={styles.gridCell} to="/dashboard?filter=Expired">
        <OfferLabel>EXPIRED OFFERS</OfferLabel>
        <OfferCount id="expiredOffers">{expiredOffers}</OfferCount>
      </Link>
    </section>
  );
};

export default OfferCountPanels;
