enum OfferTypes {
  REGIONAL = 'Regional',
  NATIONAL = 'National',
  DEALER = 'Dealer',
}

enum PricingOptions {
  PERCENTAGE_AMOUNT = 'Percentage Amount',
  COMPLIMENTARY = 'Complimentary',
}

enum ReportTypes {
  NATIONAL = 'National',
  REGIONAL = 'Regional',
}

enum ReportKinds {
  ALL_ACTIVE_OFFERS = 'All Active Offers',
}

const helpUrl = '/OAT-TOOMS-UserGuideDocumentation.pdf';

export default {
  OfferTypes,
  PricingOptions,
  ReportKinds,
  ReportTypes,
  helpUrl,
};
