import cx from 'clsx';
import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import { Image, useFetchImagesQuery } from '../../../../../gql/generated';
import UserType from '../../../../../models/user/UserType';
import getS3ImageUrl from '../../utils/getS3ImageUrl';
import styles from './styles.module.scss';

interface ImageLibraryProps {
  onSelect: (image: Image) => void;
  onClose: () => void;
}

const ImageLibraryModal = ({ onSelect, onClose }: ImageLibraryProps) => {
  const { data, loading } = useFetchImagesQuery({ variables: { type: UserType.NATIONAL_SETTINGS } });
  const [currentImage, setCurrentImage] = useState<Image | null>(null);

  const handleSelectImage = (image: Image | null) => {
    if (image?.id === currentImage?.id) {
      setCurrentImage(null);
    } else {
      setCurrentImage(image);
    }
  };

  const handleSaveImage = (image: Image | null) => {
    if (image) {
      onSelect(image);
      onClose();
    }
  };

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <ModalHeader title="Image Library" onClose={onClose} />
      <ModalBody>
        <div className={styles.imageContainer}>
          {data?.images?.map(image => {
            const selected = currentImage?.id === image?.id;
            return (
              <div className={cx(styles.imageDiv, selected && styles.selectedImage)} key={image?.id}>
                <div className={styles.cardWrapper} onClick={() => handleSelectImage(image)} onKeyPress={() => handleSelectImage(image)} role="button" tabIndex={0}>
                  <div className={styles.imageWrapper}>{image && <img src={getS3ImageUrl(`${image.s3Key}`)} alt={image.fileName} />}</div>
                  <div className={styles.nameWrapper}>{image?.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button id="select-btn" variant="primary" onClick={() => handleSaveImage(currentImage)} disabled={currentImage === null}>
          Select
        </Button>
      </ModalFooter>
    </>
  );
};

export default ImageLibraryModal;
