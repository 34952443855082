import React from 'react';
import Wrapper from '../../layout/Wrapper';

interface PageMessageProps {
  message: string;
}

const PageMessage = ({ message }: PageMessageProps) => {
  return (
    <Wrapper>
      <h1>{message}</h1>
    </Wrapper>
  );
};

export default PageMessage;
