import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../../../components/common/Button';
import ButtonGroup from '../../../../../components/common/ButtonGroup';
import Checkbox from '../../../../../components/common/Checkbox';
import Checklist from '../../../../../components/common/Checklist';
import ChecklistItem from '../../../../../components/common/ChecklistItem';
import FlexBox from '../../../../../components/common/FlexBox';
import ListTextarea from '../../../../../components/common/ListTextarea';
import ListTextareaContainer from '../../../../../components/common/ListTextareaContainer';
import Modal from '../../../../../components/common/Modal';
import Textarea from '../../../../../components/common/Textarea';
import ValidationText from '../../../../../components/common/ValidationText';
import { Consumer, Disclaimer, Offer } from '../../../../../gql/generated';
import stringArrayToMap from '../../../../../utils/tools/stringArrayToMap';
import AddButton from '../AddButton';
import MarketingLabelContainer from '../MarketingLabelContainer';
import MarketingPublishContainer from '../MarketingPublishContainer';
import PreviewOfferModal from '../PreviewOfferModal';
import styles from './styles.module.scss';
import DisclaimersListModal from '../DisclaimersListModal';
import getStringValue from '../../../../../utils/tools/getStringValue';

interface MarketingProps {
  consumers: Consumer[];
  defaultDescription?: string;
  defaultDisclaimers?: string[];
  defaultConsumers?: string[];
  offer: Offer | undefined | null;
  readOnly?: boolean;
  onBack?: () => void;
  onSave?: (doSave: boolean, description: string, disclaimers: string[], consumers: string[]) => void;
}

const MarketingComponent = ({ consumers, defaultConsumers, defaultDescription, defaultDisclaimers, offer, readOnly, onBack = () => {}, onSave = () => {} }: MarketingProps) => {
  const [description, setDescription] = useState(defaultDescription || '');
  const [disclaimers, setDisclaimers] = useState(defaultDisclaimers && defaultDisclaimers.length > 0 ? defaultDisclaimers : ['']);
  const [consumerSelectMap, setConsumerSelectMap] = useState<{ [consumerId: string]: boolean }>(
    defaultConsumers ? stringArrayToMap(defaultConsumers) : stringArrayToMap(consumers.map(item => item.id))
  );
  const [doSave, setDoSave] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDisclaimerModal, setOpenDisclaimerModal] = useState(false);

  const isDescriptionExceeded = description.length > 125;

  const handleAddDisclaimers = () => {
    setDisclaimers([...disclaimers, '']);
  };

  const handleDeleteDisclaimer = () => {
    const list = [...disclaimers];
    list.splice(list.length - 1, 1);
    setDisclaimers(list);
    setDoSave(true);
  };

  const handleConsumerSelectAll = (checked: boolean) => {
    const list: { [code: string]: boolean } = {};
    consumers.forEach(consumer => {
      list[consumer.id] = checked;
    });

    setDoSave(true);
    setConsumerSelectMap(list);
  };

  const hasSelectedConsumers = () => {
    return Object.values(consumerSelectMap).some(val => val);
  };

  const handleOnSave = () => {
    setSubmitted(true);

    if (hasSelectedConsumers() && !isDescriptionExceeded) {
      const disclaimersTrimmed = disclaimers.filter(item => !!item);
      const consumerArray = Object.keys(consumerSelectMap).filter(key => consumerSelectMap[key]);
      onSave(doSave, description, disclaimersTrimmed, consumerArray);
    }
  };

  const handleOnConsumerChecked = (consumerId: string, checked: boolean) => {
    setDoSave(true);
    setConsumerSelectMap({ ...consumerSelectMap, [consumerId]: checked });
  };

  const handleOnDisclaimerSelect = (selectedDisclaimer: Disclaimer | undefined) => {
    setDoSave(true);
    setDisclaimers([...disclaimers, getStringValue(selectedDisclaimer?.disclaimer)]);
  };

  useEffect(() => {
    if(doSave){
      handleOnSave()
    }
  }, [consumerSelectMap, description, disclaimers, doSave])

  let timeout : any;

  const setTimeoutOnInput = (e: any, setter: any) => {
    clearTimeout(timeout);
    const changedValue = e.currentTarget.value
    timeout = setTimeout(async () => {
      setter(changedValue);
      setDoSave(true);
    }, 1500);
  }
  

  return (
    <>
      <MarketingLabelContainer label="Description">
        <div>
          <Textarea
            id="description-txt-area"
            className={styles.inputContainer}
            disabled={readOnly}
            defaultValue={description}
            onChange={e => {
              setTimeoutOnInput(e, setDescription)
            }}
            error={isSubmitted && isDescriptionExceeded}
          />
          {isSubmitted && isDescriptionExceeded && <ValidationText>Description exceeds 125 characters</ValidationText>}
        </div>
      </MarketingLabelContainer>

      <MarketingLabelContainer label="Select General Disclaimers">
        <ListTextareaContainer>
          {disclaimers.map((item, index) => {
            const showDelete = index > 0 && index === disclaimers.length - 1;
            const uid = uuidv4();
            return (
              <ListTextarea
                key={uid}
                id={`disclaimer-${index}-${uid}`}
                defaultValue={item}
                onChange={value => {
                  const tempList = [...disclaimers];
                  tempList[index] = value;

                  clearTimeout(timeout);
                  timeout = setTimeout(async () => {
                    setDisclaimers(tempList);
                    setDoSave(true);
                  }, 1500);
                }}
                containerCss={styles.listInputContainer}
                showDelete={showDelete}
                onDelete={handleDeleteDisclaimer}
                disabled={readOnly}
              />
            );
          })}
          {!readOnly && <AddButton id="add-disclaimer-btn" onClick={handleAddDisclaimers} text="Add Disclaimer" />}
          {!readOnly && <AddButton id="add-saved-disclaimer-btn" onClick={() => setOpenDisclaimerModal(true)} text="Add Saved Disclaimer" />}
        </ListTextareaContainer>
      </MarketingLabelContainer>

      <MarketingPublishContainer label="Publish to">
        <Checklist>
          <ChecklistItem>
            <Checkbox id="check-all-chbox" label="Check/Uncheck All" onChange={handleConsumerSelectAll} disabled={readOnly} />
          </ChecklistItem>
          {consumers.map(item => (
            <ChecklistItem key={item.id}>
              <Checkbox
                id={`consumer-chbox-${item.id}`}
                checked={consumerSelectMap[item.id] || false}
                label={item.name}
                onChange={checked => handleOnConsumerChecked(item.id, checked)}
                disabled={readOnly}
              />
            </ChecklistItem>
          ))}
        </Checklist>
      </MarketingPublishContainer>

      {isSubmitted && !hasSelectedConsumers() && (
        <div>
          <ValidationText>Please select an application to publish to.</ValidationText>
        </div>
      )}

      {!readOnly && (
        <FlexBox justifyContent="flex-end">
          <ButtonGroup>
            <Button id="back-btn" variant="primary" onClick={() => onBack()}>
              Back
            </Button>
            <Button id="save-btn" variant="primary" onClick={() => handleOnSave()}>
              Save
            </Button>
            <Button id="preview-btn" variant="primary" onClick={() => setOpenModal(true)}>
              Preview
            </Button>
          </ButtonGroup>
        </FlexBox>
      )}
      {offer?.id && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="lg">
          <PreviewOfferModal onClose={() => setOpenModal(false)} offer={offer} />
        </Modal>
      )}
      <Modal isOpen={openDisclaimerModal} onClose={() => setOpenDisclaimerModal(false)} size="lg">
        <DisclaimersListModal onClose={() => setOpenDisclaimerModal(false)} onSelect={handleOnDisclaimerSelect} />
      </Modal>
    </>
  );
};

export default MarketingComponent;
