import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ChecklistProps {
  children?: ReactNode;
}

const Checklist = ({ children }: ChecklistProps) => {
  return <div className={styles.checklist}>{children}</div>;
};

export default Checklist;
