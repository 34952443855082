import React, { ButtonHTMLAttributes } from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const AddButton = ({ text, ...props }: ButtonProps) => {
  return (
    <button className={styles.addButton} type="button" {...props}>
      <FontAwesomeIcon icon={faPlusCircle} />
      <span className={styles.label}>{text}</span>
    </button>
  );
};

export default AddButton;
