import cx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface SectionHeaderProps {
  className?: string;
  children?: React.ReactNode;
}

const SectionHeader = ({ children, className }: SectionHeaderProps) => {
  return <h3 className={cx(styles.sectionHeader, className)}>{children}</h3>;
};

export default SectionHeader;
