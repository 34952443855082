import InferredType from '../../models/user/InferredType';
import { CustomUser } from '../../models/user/User';
import UserType from '../../models/user/UserType';

const userTypeMap = {
  [UserType.DEALER]: {
    type: UserType.DEALER,
    param: 'Dealer',
  },
  [UserType.NATIONAL]: {
    type: UserType.NATIONAL,
    param: 'National',
  },
  [UserType.REGIONAL]: {
    type: UserType.REGIONAL,
    param: 'Regional',
  },
  [UserType.ERROR]: {
    type: UserType.ERROR,
    param: '',
    message: '',
  },
};

const getInferredType = (user: CustomUser | null) => {
  let inferredType: InferredType | null = null;

  if (user?.profile.roles) {
    /*
    old roles data: comma delimited string
    new roles data: array of strings, and strings could be comma delimited
      ex ['dealer,enrolled', 'something,enrolled']. Join them -> 'dealer,enrolled,something,enrolled', then split!
    */
    const roles: string[] = typeof user?.profile.roles === 'string' ? user?.profile.roles.split(',') : user?.profile.roles.join(',').split(',');

    if (roles.includes('TOOMS_National_user')) {
      inferredType = userTypeMap[UserType.NATIONAL];
    }

    if (roles.includes('TOOMS_Regional_user')) {
      if (inferredType) {
        inferredType = { ...userTypeMap[UserType.ERROR], message: 'ERROR: Conflicting roles' };
      } else {
        inferredType = userTypeMap[UserType.REGIONAL];
      }
    }

    if (!inferredType && roles.includes('TOOMS_enrolled')) {
      inferredType = userTypeMap[UserType.DEALER];
    }

    if (!inferredType) {
      inferredType = { ...userTypeMap[UserType.ERROR], message: 'ERROR: Could not determine user type' };
    }
  }

  return inferredType;
};

export default getInferredType;
