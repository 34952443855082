import * as uuid from 'uuid';
import React, { useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import { toast } from 'react-toastify';
import Button from '../../../../../components/common/Button';
import ButtonGroup from '../../../../../components/common/ButtonGroup';
import FlexBox from '../../../../../components/common/FlexBox';
import { GetPresignedUrlDocument, Image, Offer, useCreateImageMutation } from '../../../../../gql/generated';
import ImagesUploader from '../ImagesUploader';
import getCreateImageVars from '../../utils/getCreateImageVars';
import getPresignedVars from '../../utils/getPresignedVars';
import uploadWithPresignedUrl from '../../../../../utils/tools/uploadWithPresignedUrl';
import apolloClient from '../../../../../components/Auth/apolloClient';
import withUser, { WithUserProps } from '../../../../../hoc/withUser';
import InferredType from '../../../../../models/user/InferredType';
import Modal from '../../../../../components/common/Modal';
import PreviewOfferModal from '../../../marketing/components/PreviewOfferModal';
import ImageEditor from '../ImageEditor';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import { CustomUser } from '../../../../../models/user/User';

interface ImagesProps extends WithUserProps {
  defaultDetailsImageId?: string;
  defaultDetailsImagePath?: string;
  defaultThumbnailImageId?: string;
  defaultThumbnailImagePath?: string;
  offer?: Offer | undefined | null;
  readOnly?: boolean;
  onBack?: () => void;
  onSave?: (redirect: boolean, doSave: boolean, detailsId?: string, thumbnailId?: string) => void;
  setIsEditing: (isEditing: boolean) => void;
}

const ImagesComponent = ({
  defaultDetailsImageId,
  defaultDetailsImagePath,
  defaultThumbnailImageId,
  defaultThumbnailImagePath,
  offer,
  readOnly,
  user: initUser,
  userType: initUserType,
  onBack,
  onSave,
  setIsEditing,
}: ImagesProps) => {
  const [detailsImageId, setDetailsImageId, refDetailsImageId] = useStateRef<any>(defaultDetailsImageId);
  const [detailsImagePath, setDetailsImagePath] = useState<string | undefined>(defaultDetailsImagePath);
  const [detailsFile, setDetailsFile] = useState<File | undefined>();
  const [editDetailsFile, setEditDetailsFile] = useState<File | undefined>();
  const [thumbnailImageId, setThumbnailImageId, refThumbnailImageId] = useStateRef<string | undefined>(defaultThumbnailImageId);
  const [thumbnailImagePath, setThumbnailImagePath] = useState<string | undefined>(defaultThumbnailImagePath);
  const [thumbnailFile, setThumbnailFile] = useState<File | undefined>();
  const [editThumbnailFile, setEditThumbnailFile] = useState<File | undefined>();
  const [doSave, setDoSave] = useState(false);
  const [createImage] = useCreateImageMutation();
  const [openModal, setOpenModal] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();
  const [detailsUrl, setDetilsUrl] = useState<string | undefined>();
  const [removeImage, setRemoveImage] = useState<boolean>(false);
  const user = initUser as CustomUser;
  const userType = initUserType as InferredType;
  const [saveFromLibary, setSaveFromLibary] = useState(false);
  const [isDetailsImageEdited, setIsDetailsImageEdited] = useState(true);
  const [isThumbnailImageEdited, setIsThumbnailImageEdited] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setDetailsImageId(defaultDetailsImageId);
    setDetailsImagePath(defaultDetailsImagePath);
    setThumbnailImageId(defaultThumbnailImageId);
    setThumbnailImagePath(defaultThumbnailImagePath);
  }, []);

  const handleOnSave = async (redirect = false) => {
    if (onSave) {
      const detailsId = refDetailsImageId.current ? refDetailsImageId.current : detailsImageId;
      const thumbnailId = refThumbnailImageId.current ? refThumbnailImageId.current : thumbnailImageId;
      onSave(redirect, doSave, detailsId, thumbnailId);
      setDetailsFile(undefined);
      setThumbnailFile(undefined);
    }
    setDoSave(false);
  };

  useEffect(() => {
    if (removeImage) {
      handleOnSave();
      setRemoveImage(false);
    }
  }, [removeImage]);

  useEffect(() => {
    if (saveFromLibary) {
      handleOnSave();
      setSaveFromLibary(false);
    }
  }, [saveFromLibary]);

  /**
   * Gets passed down to child component to be used there. Returns type Promise<Image | undefined>
   * @param file
   */

  const onFileUpload = async (file: File) => {
    const imageFile = file;
    const splitImageName = imageFile.name.split('.');
    const extension = splitImageName[1];
    const blob = file.slice(0, file.size, `image/${extension}`);
    const newImageFile = new File([blob], `${uuid.v4()}.${extension}`, { type: `image/${extension}` });
    try {
      const response = await apolloClient.query({ query: GetPresignedUrlDocument, variables: getPresignedVars(user, userType.type, newImageFile, offer?.id) });
      await uploadWithPresignedUrl(response.data.presignedUploadUrl, newImageFile);
      const imageResponse = await createImage({ variables: getCreateImageVars(user, userType.type, newImageFile.name, newImageFile.name, offer?.id) });
      toast.success('Image uploaded sucessfully');
      return imageResponse.data?.createImage.image as Image;
    } catch (e: any) {
      toast.error(e.message);
      return undefined;
    }
  };

  const fileUpload = async () => {
    if (thumbnailFile) {
      const thumbnailImage = await onFileUpload(thumbnailFile);
      if (thumbnailImage) {
        setThumbnailImageId(thumbnailImage?.id);
      }
    }
    if (detailsFile) {
      const detailsImage = await onFileUpload(detailsFile);
      if (detailsImage) {
        setDetailsImageId(detailsImage?.id);
      }
    }
  };

  useEffect(() => {
    fileUpload();
  }, [thumbnailFile, detailsFile]);
  return (
    <>
      <ImagesUploader
        refImageId={refThumbnailImageId.current}
        id="thumbnail-upload"
        label="Upload Offer Thumbnail Image"
        defaultImagePath={thumbnailImagePath}
        disabled={readOnly}
        instructions={[
          'Recommended:',
          'Image dimension : 210 pix (width) X 146 pix (height)',
          'File size limit 150KB',
          'JPG/JPEG file type accepted',
          'Thumbnail image should not have a border',
        ]}
        acceptType={['jpg', 'jpeg']}
        onImageRemove={() => {
          setDoSave(true);
          setRemoveImage(true);
          setThumbnailFile(undefined);
          setThumbnailImageId('');
          setThumbnailImagePath('');
        }}
        onImageSelect={image => {
          setDoSave(true);
          setThumbnailImageId(image.id);
          setThumbnailImagePath(image.s3Key);
          setSaveFromLibary(true);
        }}
        onFileUpload={file => {
          setDoSave(true);
          setEditThumbnailFile(file);
        }}
        setIsImageEdited={setIsThumbnailImageEdited}
        setUrl={setThumbnailUrl}
        url={thumbnailUrl}
      />
      {!isThumbnailImageEdited && (
        <Modal isOpen={!isThumbnailImageEdited} size="lg">
          <ModalHeader title="Resize image" onClose={() => setIsThumbnailImageEdited(true)} />
          <ModalBody>
            <ImageEditor
              file={editThumbnailFile}
              isEditing
              maxHeight={146}
              maxWidth={210}
              onSave={handleOnSave}
              setErrorMessage={setErrorMessage}
              setImageFile={file => {
                setDoSave(true);
                setThumbnailFile(file);
              }}
              setIsEditing={setIsEditing}
              setIsImageEdited={setIsThumbnailImageEdited}
              setUrl={setThumbnailUrl}
              zoom={1}
            />
          </ModalBody>
        </Modal>
      )}

      <ImagesUploader
        refImageId={refDetailsImageId.current}
        id="details-upload"
        label="Upload Offer Details Image"
        defaultImagePath={detailsImagePath}
        disabled={readOnly}
        instructions={['Recommended:', 'Image dimension : 505 pix (width) X 800 pix (height)', 'File size limit 150KB', 'JPG/JPEG and PDF file type accepted']}
        acceptType={['jpg', 'jpeg', 'pdf']}
        onImageRemove={() => {
          setDetailsFile(undefined);
          setDetailsImageId('');
          setDetailsImagePath('');
          setDoSave(true);
          setRemoveImage(true);
        }}
        onImageSelect={image => {
          setDetailsImageId(image.id);
          setDetailsImagePath(image.s3Key);
          setDoSave(true);
          setSaveFromLibary(true);
        }}
        onFileUpload={file => {
          setDoSave(true);
          setEditDetailsFile(file);
        }}
        setIsImageEdited={setIsDetailsImageEdited}
        setUrl={setDetilsUrl}
        url={detailsUrl}
      />
      {!isDetailsImageEdited && (
        <Modal isOpen={!isDetailsImageEdited} size="lg">
          <ModalHeader title="Resize image" onClose={() => setIsDetailsImageEdited(true)} />
          <ModalBody>
            <ImageEditor
              file={editDetailsFile}
              isEditing
              maxHeight={800}
              maxWidth={505}
              onSave={handleOnSave}
              setErrorMessage={setErrorMessage}
              setImageFile={file => {
                setDetailsFile(file);
                setDoSave(true);
              }}
              setIsEditing={setIsEditing}
              setIsImageEdited={setIsDetailsImageEdited}
              setUrl={setDetilsUrl}
              zoom={0.5}
            />
          </ModalBody>
        </Modal>
      )}

      {!readOnly && (
        <FlexBox justifyContent="flex-end">
          <ButtonGroup>
            {offer?.id && (
              <Button id="preview-btn" variant="primary" onClick={() => setOpenModal(true)}>
                Preview
              </Button>
            )}
            <Button id="back-btn" variant="primary" onClick={onBack}>
              Back
            </Button>

            <Button id="next-btn" variant="primary" onClick={() => handleOnSave(true)}>
              Next
            </Button>
          </ButtonGroup>
        </FlexBox>
      )}
      {offer?.id && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="lg">
          <PreviewOfferModal onClose={() => setOpenModal(false)} offer={offer} />
        </Modal>
      )}
      <Modal isOpen={!!errorMessage} onClose={() => setErrorMessage('')} size="md">
        <ModalHeader title="Image Validation" onClose={() => setErrorMessage('')} />
        <ModalBody>{errorMessage}</ModalBody>
        <ModalFooter>
          <Button id="select-btn" variant="primary" onClick={() => setErrorMessage('')}>
            Select New Image
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withUser(ImagesComponent);
