import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import ButtonGroup from '../../../../../components/common/ButtonGroup';
import Checkbox from '../../../../../components/common/Checkbox';
import FileUploader from '../../../../../components/common/FileUploader';
import FileUploaderContainer from '../../../../../components/common/FileUploaderContainer';
import Input from '../../../../../components/common/Input';
import LabelContainer from '../../../../../components/common/LabelContainer';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import ValidationText from '../../../../../components/common/ValidationText';
import { Image } from '../../../../../gql/generated';
import getStringValue from '../../../../../utils/tools/getStringValue';
import getS3ImageUrl from '../../../../offer/images/utils/getS3ImageUrl';
import styles from './styles.module.scss';

interface ImageModalProps {
  image?: Image | null;
  onClose: () => void;
  onUpdate?: (name: string, image: Image) => void;
  onAdd?: (name: string, file: File) => void;
}

const ImageModal = ({ image, onClose, onUpdate, onAdd }: ImageModalProps) => {
  const [name, setName] = useState(getStringValue(image?.name));
  const [fileName, setFileName] = useState(getStringValue(image?.fileName));
  const [file, setFile] = useState<File | null>(null);
  const [submitted, setSubmitted] = useState(false);

  const isAdding = !image;

  const handleOnAddImage = async () => {
    setSubmitted(true);
    if (isAdding && onAdd && file && name) {
      onAdd(name, file);
      onClose();
    }

    if (!isAdding && onUpdate && name && image) {
      onUpdate(name, image);
    }
  };

  return (
    <div>
      <ModalHeader title={`${!image ? 'Add' : 'Edit'} Image`} onClose={onClose} />

      <ModalBody>
        <div className={styles.nameEnabledContainer}>
          <LabelContainer vertical label="Image Name">
            <Input id="input-name" defaultValue={name} onBlur={e => setName(e.currentTarget.value)} error={submitted && !name} />
            {submitted && !name && <ValidationText>Name is required</ValidationText>}
          </LabelContainer>

          {image && (
            <LabelContainer vertical label="Status">
              <Checkbox id="enabled-chbox" checked={image && image.offerIds ? image?.offerIds.length > 0 : false} label="Enabled" />
            </LabelContainer>
          )}
        </div>

        <div className={styles.imageContainer}>
          <LabelContainer vertical label="Image">
            <FileUploaderContainer>
              <Input id="input-file-name" value={fileName} disabled error={submitted && !fileName} />
              {!image && (
                <FileUploader
                  id="file-uploader"
                  buttonText="Select Image"
                  onUpload={fileParam => {
                    setFile(fileParam);
                    setFileName(getStringValue(fileParam?.name));
                  }}
                />
              )}
            </FileUploaderContainer>
          </LabelContainer>

          {!image && submitted && !file && <ValidationText>File is required</ValidationText>}
          {image && (
            <div className={styles.imagePreviewContainer}>
              <img alt={image.name} src={getS3ImageUrl(image.s3Key)} />
            </div>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-close-btn" variant="primary" onClick={() => onClose()}>
            Close
          </Button>
          <Button id="modal-save-btn" variant="primary" onClick={() => handleOnAddImage()}>
            {!image ? 'Add' : 'Save'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div>
  );
};

export default ImageModal;
