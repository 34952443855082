import { IDealer } from '../../models/dealers/IDealer';
import DealersResponse from '../../models/dis/DealersResponse';

const transformDISResponse = (dealersResponse: DealersResponse): IDealer[] => {
  const dealers: IDealer[] = [];

  dealersResponse.showDealerLocatorDataArea.dealerLocator.forEach(dlItem => {
    dlItem.dealerLocatorDetail.forEach(dldItem => {
      dealers.push({
        dealerCode: dldItem.dealerParty.partyID.value,
        dealerName: dldItem.dealerParty.specifiedOrganization.companyName.value,
        region: dldItem.dealerParty.specifiedOrganization.divisionCode.name,
        regionCode: `${dldItem.dealerParty.specifiedOrganization.divisionCode.value}0`,
        state: dldItem.dealerParty.specifiedOrganization.primaryContact[0]?.postalAddress.stateOrProvinceCountrySubDivisionID?.value || '',
        street: dldItem.dealerParty.specifiedOrganization.primaryContact[0]?.postalAddress.lineOne?.value || '',
        city: dldItem.dealerParty.specifiedOrganization.primaryContact[0]?.postalAddress.cityName?.value || '',
        zipCode: dldItem.dealerParty.specifiedOrganization.primaryContact[0]?.postalAddress.postcode?.value || '',
      });
    });
  });

  return dealers;
};

export default transformDISResponse;
