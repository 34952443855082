import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

interface NavBarLinkProps {
  children: React.ReactNode;
  to: string;
  id?: string;
}

const NavBarLink = ({ id, to, children }: NavBarLinkProps) => {
  const location = useLocation();
  const isActive = to === location.pathname;

  return isActive ? (
    <span className={styles.active}>{children}</span>
  ) : (
    <NavLink id={id} to={to} className={styles.navlink}>
      {children}
    </NavLink>
  );
};

export default NavBarLink;
