import React from 'react';
import { toast } from 'react-toastify';
import DataLoadingController from '../../components/Auth/DataLoadingController';
import DealerGroupComponent from './components/DealerGroupComponent';
import MainLayout from '../../components/layout/MainLayout';
import Wrapper from '../../components/layout/Wrapper';
import { DealerGroupsType, DealerGroupType } from '../../models/dealerGroup/DealerGroup';
import {
  FetchDealerGroupsDocument,
  useCreateDealerGroupMutation,
  useDeleteDealerGroupMutation,
  useFetchDealerGroupsQuery,
  useUpdateDealerGroupMutation,
} from '../../gql/generated';
import withUser, { WithUserProps } from '../../hoc/withUser';

const DealerGroupController = ({ user, userType }: WithUserProps) => {
  const { data, loading, error } = useFetchDealerGroupsQuery({ variables: { type: userType?.type || '', regionCode: user?.profile.tmscorporateregioncode } });
  const [createDealerGroup] = useCreateDealerGroupMutation({
    refetchQueries: [{ query: FetchDealerGroupsDocument, variables: { type: userType?.type || '', regionCode: user?.profile.tmscorporateregioncode } }],
  });
  const [updateDealerGroup] = useUpdateDealerGroupMutation({
    refetchQueries: [{ query: FetchDealerGroupsDocument, variables: { type: userType?.type || '', regionCode: user?.profile.tmscorporateregioncode } }],
  });
  const [deleteDealerGroup] = useDeleteDealerGroupMutation({
    refetchQueries: [{ query: FetchDealerGroupsDocument, variables: { type: userType?.type || '', regionCode: user?.profile.tmscorporateregioncode } }],
  });

  const createDealerGroupHandler = async (name: string, dealers: string[]) => {
    try {
      await createDealerGroup({
        variables: {
          type: userType?.type || '',
          regionCode: user?.profile.tmscorporateregioncode,
          name,
          dealers,
        },
      });
      toast.success('Dealer Group succesfully created');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const deleteDealerGroupHandler = async (dealerGroup: DealerGroupType) => {
    if (dealerGroup) {
      try {
        await deleteDealerGroup({
          variables: {
            id: dealerGroup.id,
            rev: dealerGroup.rev,
          },
        });
        toast.success('Dealer Group succesfully deleted');
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  const updateDealerGroupHandler = async (id: string, rev: string, name: string, dealers: string[]) => {
    try {
      await updateDealerGroup({
        variables: {
          id,
          rev,
          name,
          dealers,
        },
      });
      toast.success('Dealer Group succesfully updated');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const dealerGroups: DealerGroupsType = data?.dealerGroups;

  return (
    <MainLayout>
      <Wrapper>
        <DataLoadingController
          component={
            <DealerGroupComponent dealerGroups={dealerGroups} onCreate={createDealerGroupHandler} onDelete={deleteDealerGroupHandler} onUpdate={updateDealerGroupHandler} />
          }
          loadingComponent={(() => (
            <Wrapper>
              <h2>Loading Dealer Groups ...</h2>
            </Wrapper>
          ))()}
          isLoaded={!loading}
          apolloErrors={[error]}
        />
      </Wrapper>
    </MainLayout>
  );
};

export default withUser(DealerGroupController);
