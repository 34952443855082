import cx from 'clsx';
import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import ButtonGroup from '../../../../../components/common/ButtonGroup';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import { Offer, useFetchImagesQuery } from '../../../../../gql/generated';
import UserType from '../../../../../models/user/UserType';
import OfferCard from './Components/OfferCard';
import OfferDetail from './Components/OfferDetail';
import styles from './styles.module.scss';

interface PreviewOfferProps {
  offer: Offer;
  onClose: () => void;
}

const PreviewOfferModal = ({ offer, onClose }: PreviewOfferProps) => {
  const { loading } = useFetchImagesQuery({ variables: { type: UserType.NATIONAL_SETTINGS } });
  const [selectedTab, setSelectedTab] = useState('card');

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <ModalHeader title="Preview Offer" onClose={onClose} />
      <ModalBody>
        <section className={styles.contentContainer}>
          <ul className={styles.tabs}>
            <li className={cx(styles.tab, selectedTab === 'card' && styles.selectedTab)}>
              <button type="button" onClick={() => setSelectedTab('card')}>
                Offer Card
              </button>
            </li>
            <li className={cx(styles.tab, selectedTab === 'detail' && styles.selectedTab)}>
              <button type="button" onClick={() => setSelectedTab('detail')}>
                Offer Detail
              </button>
            </li>
          </ul>
          <section className={styles.contentWrapper}>{selectedTab === 'card' ? <OfferCard offer={offer} /> : <OfferDetail offer={offer} />}</section>
        </section>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-close-btn" variant="primary" onClick={onClose}>
            Close
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default PreviewOfferModal;
