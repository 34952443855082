import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ModalHeaderProps {
  title?: string;
  children?: ReactNode;
  onClose?: () => void;
}

const ModalHeader = ({ title, children, onClose }: ModalHeaderProps) => {
  return (
    <div className={styles.modalHeader}>
      <div>
        {title && <h2 className={styles.title}>{title}</h2>}
        {children}
      </div>
      <div>
        <button id="modal-close-btn" type="button" className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      </div>
    </div>
  );
};

export default ModalHeader;
