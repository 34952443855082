import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface DropdownProps {
  children?: ReactNode;
}

const Dropdown = ({ children }: DropdownProps) => {
  return <div className={styles.dropdown}>{children}</div>;
};

export default Dropdown;
