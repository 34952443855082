import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

interface SettingsNavProps {
  settingsMenu: { id: string; text: string }[];
  activeLink: string;
}

const SettingsNav = ({ settingsMenu, activeLink }: SettingsNavProps) => {
  return (
    <ul className={styles.sideNav}>
      {settingsMenu.map(item => (
        <li key={item.id}>{activeLink === item.id ? <div className={styles.active}>{item.text}</div> : <NavLink to={`/settings/${item.id}`}>{item.text}</NavLink>}</li>
      ))}
    </ul>
  );
};

export default SettingsNav;
