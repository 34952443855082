import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import styles from './styles.module.scss';

const Toast = () => (
  <section>
    <ToastContainer draggable={false} hideProgressBar transition={Slide} className={styles.toast} />
  </section>
);

export default Toast;
