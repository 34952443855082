import React, { ReactNode } from 'react';
import Loader from './Loader';
import styles from './styles.module.scss';

interface BlockUIProps {
  children?: ReactNode;
  blocking?: boolean;
  showLoader?: boolean;
}

const BlockUI = ({ children, blocking, showLoader }: BlockUIProps) => {
  return (
    <div className={styles.blockUIContainer}>
      {children}
      {blocking && (
        <div className={styles.blockUI}>
          <div className={styles.overlay} />
          <div className={styles.messageContainer}>{showLoader && <Loader />}</div>
        </div>
      )}
    </div>
  );
};

export default BlockUI;
