import { CreateImageMutationVariables } from '../../../../gql/generated';
import { CustomUser } from '../../../../models/user/User';
import UserType from '../../../../models/user/UserType';

const getCreateImageVars = (user: CustomUser, userType: UserType, fileName: string, name: string, offerId?: string) => {
  const createImageVars: CreateImageMutationVariables = {
    type: userType,
    fileName,
    name,
  };

  if (userType === UserType.NATIONAL) {
    createImageVars.offerId = offerId;
  }

  if (userType === UserType.DEALER) {
    createImageVars.dealerCode = user.profile.primary_dealer_number;
    createImageVars.offerId = offerId;
  }

  if (userType === UserType.REGIONAL) {
    createImageVars.regionCode = user.profile.tmscorporateregioncode;
    createImageVars.offerId = offerId;
  }

  return createImageVars;
};

export default getCreateImageVars;
