import cx from 'clsx';
import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

interface ModalBodyProps {
  children?: ReactNode;
  fixHeightLarge?: boolean;
  fixHeighMiddle?: boolean;
}

const ModalBody = ({ children, fixHeightLarge, fixHeighMiddle }: ModalBodyProps) => {
  return <div className={cx(styles.modalBody, fixHeighMiddle && styles.fixHeighMiddle, fixHeightLarge && styles.fixHeightLarge)}>{children}</div>;
};

export default ModalBody;
