import React from 'react';
import { Link } from 'react-router-dom';
import useUserProfile from '../../hooks/useUserProfile';
import getInferredType from '../../utils/user/getInferredType';

const UserProfile: React.FC = () => {
  const [user] = useUserProfile();

  const userType = getInferredType(user);

  return (
    <div>
      <h1>
        Welcome {user?.profile.first_name} {user?.profile.last_name}
      </h1>
      <dl>
        <dt>Inferred Type</dt>
        <dd>{userType?.message || userType?.type}</dd>
        <dt>Type</dt>
        <dd>{user?.profile.user_type}</dd>
        <dt>Roles</dt>
        <dd>{user?.profile.roles}</dd>
        <dt>Region</dt>
        <dd>{user?.profile.region_code ?? 'N/A'}</dd>
        <dt>Corporate Region</dt>
        <dd>{user?.profile.tmscorporateregioncode ?? 'N/A'}</dd>
        <dt>Primary Dealer Number</dt>
        <dd>{user?.profile.primary_dealer_number ?? 'N/A'}</dd>
        <dt>Dealer Number</dt>
        <dd>{user?.profile.dealer_number ?? 'N/A'}</dd>
      </dl>
      <Link to="/signout">Sign out</Link>
      <br />
      <span>{JSON.stringify(user)}</span>
    </div>
  );
};

export default UserProfile;
