import cx from 'clsx';
import React, { TextareaHTMLAttributes, Ref } from 'react';
import styles from './styles.module.scss';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const Textarea = React.forwardRef(({ className, error, ...rest }: TextareaProps, ref: Ref<HTMLTextAreaElement>) => {
  return <textarea ref={ref} className={cx(styles.textarea, className, error && styles.error)} {...rest} />;
});

export default Textarea;
