import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface SettingsContainerProps {
  children?: ReactNode;
}

const SettingsContainer = ({ children }: SettingsContainerProps) => {
  return <div className={styles.settingsContainer}>{children}</div>;
};

export default SettingsContainer;
