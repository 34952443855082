import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import getAuthToken from '../../utils/tools/getAuthToken';

const cache = new InMemoryCache({});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : ''}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token: string = getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Initialize ApolloClient
const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

export default apolloClient;
