import cx from 'clsx';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import LogoutComponent from '../../Auth/LogoutComponent';
import Header from '../Header';
import HeaderLogo from '../Header/HeaderLogo';
import HeaderTitle from '../Header/HeaderTitle';
import NavBar from '../NavBar';
import Wrapper from '../Wrapper';
import styles from './styles.module.scss';

interface MainLayoutProps {
  children?: ReactNode;
  mainLayoutCss?: string;
  bgColorAlt?: boolean;
}

const MainLayout = ({ children, mainLayoutCss, bgColorAlt }: MainLayoutProps) => {
  return (
    <div className={cx(styles.mainLayout, mainLayoutCss, bgColorAlt && styles.bgColorAlt)}>
      <Header>
        <Wrapper>
          <div className={styles.titleContainer}>
            <HeaderLogo />
            <HeaderTitle>
              <NavLink className={styles.titleLink} to="/dashboard">
                Offer Admin Tool (Toyota Owners Offer Management System)
              </NavLink>
            </HeaderTitle>
          </div>
        </Wrapper>
      </Header>
      <NavBar />
      <div className={styles.contentContainer}>{children}</div>
      <LogoutComponent />
    </div>
  );
};

export default MainLayout;
