import cx from 'clsx';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

interface ModalProps {
  children?: ReactNode;
  isOpen: boolean;
  className?: string;
  size?: 'md' | 'lg' | 'auto';
  onClose?: () => void;
}

const Modal = ({ isOpen, children, className, size = 'auto', onClose = () => {} }: ModalProps) => {
  const ModalContent = (
    <section className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalDialog}>
          <div role="presentation" className={styles.backdrop} onClick={() => onClose()} />
          <div className={cx(className, styles.dialog, size === 'md' && styles.medium, size === 'lg' && styles.large, size === 'auto' && styles.auto)}>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    </section>
  );

  return isOpen ? ReactDOM.createPortal(ModalContent, document.body) : null;
};

export default Modal;
