import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import Textarea from '../../../../../components/common/Textarea';
import { Disclaimer, useFetchDisclaimersQuery } from '../../../../../gql/generated';
import styles from './styles.module.scss';

interface DisclaimerListProps {
  onClose: () => void;
  onSelect: (disclaimer: Disclaimer | undefined) => void;
}

const DisclaimerListModal = ({ onClose, onSelect }: DisclaimerListProps) => {
  const { data: disclaimersData, loading: disclaimersLoading } = useFetchDisclaimersQuery({});
  const [currentDisclaimer, setCurrentDisclaimer] = useState<Disclaimer | null>(null);

  const handleSelectDisclaimer = (disclaimer: Disclaimer | null) => {
    if (disclaimer?.id === currentDisclaimer?.id) {
      setCurrentDisclaimer(null);
    } else {
      setCurrentDisclaimer(disclaimer);
    }
  };

  const handleSaveDisclaimer = () => {
    if (currentDisclaimer) {
      onSelect(currentDisclaimer);
      onClose();
    }
  };

  if (disclaimersLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <ModalHeader title="Disclaimer Library" onClose={onClose} />
      <ModalBody>
        <div className={styles.container}>
          <ul className={styles.list}>
            {disclaimersData?.disclaimers?.map(disclaimer => (
              <li className={(disclaimer?.id === currentDisclaimer?.id && styles.active) || undefined} key={disclaimer?.id}>
                <button id={`list-btn-${disclaimer?.id}`} key={disclaimer?.id} type="button" onClick={() => handleSelectDisclaimer(disclaimer)}>
                  {disclaimer?.title}
                </button>
              </li>
            ))}
          </ul>
          <div className={styles.discalimerContainer}>{currentDisclaimer?.disclaimer && <Textarea value={currentDisclaimer.disclaimer} disabled />}</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!currentDisclaimer} id="select-btn" onClick={handleSaveDisclaimer} variant="primary">
          Select
        </Button>
      </ModalFooter>
    </>
  );
};

export default DisclaimerListModal;
