import React from 'react';
import { Offer } from '../../../../../../../gql/generated';
import getS3ImageUrl from '../../../../../images/utils/getS3ImageUrl';
import getOfferText from './getOfferText';
import styles from './styles.module.scss';

interface OfferCardProps {
  offer: Offer;
}

const OfferCard = ({ offer }: OfferCardProps) => {
  return (
    <div className={styles.offerCard}>
      <img src={getS3ImageUrl(offer.thumbnailImage ? offer.thumbnailImage : '')} alt="Offer card thumbnail" />
      <div className={styles.offerCardContent}>
        <h2>{offer.title}</h2>
        <p className={styles.subtitle}>{offer.subTitle}</p>
        {offer.description && <p className={styles.description}>{offer.description}</p>}
        {offer.pricingOptionId && offer.amount && <span className={styles.price}>{getOfferText(offer.pricingOptionId, offer.amount)}</span>}
        <span className={styles.label}>Offer Details</span>
      </div>
    </div>
  );
};

export default OfferCard;
