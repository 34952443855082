import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface OfferTabsProps {
  id?: string;
  children?: ReactNode;
}

const OfferTabs = ({ children, id }: OfferTabsProps) => {
  return (
    <ul id={id} className={styles.offerTabs}>
      {children}
    </ul>
  );
};

export default OfferTabs;
