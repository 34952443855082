import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ChecklistItemProps {
  children?: ReactNode;
}

const ChecklistItem = ({ children }: ChecklistItemProps) => {
  return <div className={styles.checklistItem}>{children}</div>;
};

export default ChecklistItem;
