const REGIONS = {
  '110': {
    regionCode: '110',
    regionName: 'LA',
  },
  '120': {
    regionCode: '120',
    regionName: 'SF',
  },
  '130': {
    regionCode: '130',
    regionName: 'Portland',
  },
  '150': {
    regionCode: '150',
    regionName: 'Denver',
  },
  '160': {
    regionCode: '160',
    regionName: 'NY',
  },
  '170': {
    regionCode: '170',
    regionName: 'BOS',
  },
  '210': {
    regionCode: '210',
    regionName: 'CHI',
  },
  '220': {
    regionCode: '220',
    regionName: 'CIN',
  },
  '230': {
    regionCode: '230',
    regionName: 'Kansas City',
  },
  '500': {
    regionCode: '500',
    regionName: 'SET',
  },
  '600': {
    regionCode: '600',
    regionName: 'GST',
  },
  '800': {
    regionCode: '800',
    regionName: 'CAT',
  },
};

export default REGIONS;
