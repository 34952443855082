import { faCheckCircle, faPauseCircle, faSync, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import ButtonGroup from '../../../../../components/common/ButtonGroup';
import Dropdown from '../../../../../components/common/Dropdown';
import DropdownContent from '../../../../../components/common/DropdownContent';
import Modal from '../../../../../components/common/Modal';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import withUser, { WithUserProps } from '../../../../../hoc/withUser';
import useComponentVisible from '../../../../../hooks/useComponentVisible';
import OfferStatus from '../../../../../models/offers/OfferStatus';
import styles from './styles.module.scss';

interface StatusDropdownProps extends WithUserProps {
  value: string;
  offerType: string;
  onUpdate?: (status: string) => void;
  onDelete?: () => void;

  disabled?: boolean;
  id?: string;
}

const StatusDropdown = ({ userType, value, onDelete = () => {}, id, onUpdate = () => {}, disabled, offerType }: StatusDropdownProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [openModal, setOpenModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleButtonOnClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const getIconStyle = (status: string) => {
    switch (status) {
      case OfferStatus.IN_PROGRESS:
      case OfferStatus.PAUSED:
      case OfferStatus.READY_TO_PUBLISH: {
        return styles.orangeIcon;
      }
      case OfferStatus.PUBLISHED: {
        return styles.greenIcon;
      }
      default: {
        return undefined;
      }
    }
  };

  const getIcon = (status: string) => {
    switch (status) {
      case OfferStatus.PAUSED: {
        return faPauseCircle;
      }
      case OfferStatus.READY_TO_PUBLISH: {
        return faCheckCircle;
      }
      case OfferStatus.PUBLISHED: {
        return faCheckCircle;
      }
      default: {
        // in progress
        return faSync;
      }
    }
  };

  const handleOnClick = (status: string) => {
    setIsComponentVisible(false);
    onUpdate(status);
  };

  const handleOnDelete = () => {
    setIsDisabled(true);
    setIsComponentVisible(false);
    setOpenModal(false);
    onDelete();
  };

  return (
    <Dropdown>
      <button id={id} type="button" className={cx(styles.button, getIconStyle(value))} disabled={disabled} onClick={handleButtonOnClick}>
        <FontAwesomeIcon icon={getIcon(value)} />
      </button>
      <DropdownContent ref={ref} open={isComponentVisible} alignRight>
        <ul className={styles.list}>
          <li>
            <button type="button" className={styles.buttonItem} disabled={disabled} onClick={() => handleOnClick(OfferStatus.PAUSED)}>
              <FontAwesomeIcon icon={faPauseCircle} className={styles.orangeIcon} /> Paused
            </button>
          </li>
          <li>
            <button type="button" className={styles.buttonItem} disabled={disabled} onClick={() => handleOnClick(OfferStatus.READY_TO_PUBLISH)}>
              <FontAwesomeIcon icon={faCheckCircle} className={styles.orangeIcon} /> Ready to Publish
            </button>
          </li>
          {offerType === userType?.type && (
            <li>
              <button type="button" className={styles.buttonItem} disabled={disabled} onClick={() => setOpenModal(true)}>
                <FontAwesomeIcon icon={faTimesCircle} className={styles.orangeIcon} /> Delete Offer
              </button>
            </li>
          )}
        </ul>
      </DropdownContent>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <ModalHeader title="Delete Offer" onClose={() => setOpenModal(false)} />
        <ModalBody>
          <>Please confirm that you would like to delete this offer.</>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button id="modal-close-btn" variant="primary" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button id="del-modal-btn" variant="primary" disabled={isDisabled} onClick={() => handleOnDelete()}>
              Confirm
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </Dropdown>
  );
};

export default withUser(StatusDropdown);
