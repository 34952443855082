import { faPlusCircle, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import Modal from '../../../../../components/common/Modal';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import { Disclaimer } from '../../../../../gql/generated';
import DisclaimerModal from '../DisclaimerModal';
import DateFormat from '../../../../../components/common/DateFormat';
import styles from './styles.module.scss';
import SettingsTable from '../../../components/SettingsTable';

interface DisclaimersComponentProps {
  disclaimers: Disclaimer[];
  onDelete: (disclaimer: Disclaimer) => void;
  onCreate: (title: string, declaimer: string) => void;
  onUpdate: (disclaimer: string, title: string, declaimerText: string, rev: string) => void;
  isNationalUser: boolean;
}

const DisclaimersComponent = ({ disclaimers, onDelete, onCreate, onUpdate, isNationalUser }: DisclaimersComponentProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [disclaimerForDelete, setDisclaimerForDelete] = useState<Disclaimer | undefined>(undefined);
  const [disclaimerForEdit, setDisclaimerForEdit] = useState<Disclaimer | undefined>(undefined);

  const handleOnDelete = (disclaimer: Disclaimer) => {
    onDelete(disclaimer);
    setDisclaimerForDelete(undefined);
  };

  return (
    <div className={styles.discalimer}>
      <SettingsTable>
        <thead>
          <tr>
            <td>Disclaimer Name</td>
            <td>Disclaimer </td>
            <td>Modified by </td>
            <td>Modified date </td>
            <td aria-label="empty-td" />
          </tr>
        </thead>

        <tbody>
          {disclaimers?.map(disclaimer => (
            <tr key={disclaimer.id}>
              <td className={styles.name}>{disclaimer.title}</td>
              <td>{disclaimer.disclaimer}</td>
              <td>{disclaimer.updatedBy}</td>
              <td>
                <DateFormat format="MM/DD/YYYY">{disclaimer.updated}</DateFormat>
              </td>
              <td>
                {isNationalUser && (
                  <>
                    <button id={`edit-btn-${disclaimer.id}`} type="button" className={styles.tableBtn}>
                      <FontAwesomeIcon icon={faPencilAlt} onClick={() => setDisclaimerForEdit(disclaimer)} />
                    </button>
                    <button id={`delete-btn-${disclaimer.id}`} type="button" className={styles.tableBtn} onClick={() => setDisclaimerForDelete(disclaimer)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </SettingsTable>
      {isNationalUser && (
        <button id="new-image-btn" type="button" className={styles.addBtn} onClick={() => setOpenModal(true)}>
          <FontAwesomeIcon icon={faPlusCircle} className={styles.addBtnIcon} /> Add New Disclaimer
        </button>
      )}
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <DisclaimerModal onClose={() => setOpenModal(false)} onCreate={onCreate} />
      </Modal>
      <Modal isOpen={disclaimerForEdit !== undefined} onClose={() => setDisclaimerForEdit(undefined)}>
        <DisclaimerModal disclaimer={disclaimerForEdit} onClose={() => setDisclaimerForEdit(undefined)} onUpdate={onUpdate} />
      </Modal>
      <Modal isOpen={disclaimerForDelete !== undefined} onClose={() => setDisclaimerForDelete(undefined)}>
        <ModalHeader title="Delete Disclaimer" onClose={() => setDisclaimerForDelete(undefined)} />
        <ModalBody>
          <>Are you sure you want to delete this disclaimer?</>
        </ModalBody>
        <ModalFooter>
          <Button id="del-modal-btn" variant="primary" onClick={() => handleOnDelete(disclaimerForDelete as Disclaimer)}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DisclaimersComponent;
