import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { CustomUser } from '../../models/user/User';
import useUserManager from '../../hooks/useUserManager';
import Wrapper from '../layout/Wrapper';
import SignInRedirect from './SignInRedirect';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<CustomUser | null>();
  const userMgr = useUserManager;

  useEffect(() => {
    (async () => {
      const fetchedUser = await userMgr.getUser();
      setUser(fetchedUser);
      setLoaded(true);
    })();
  }, []);

  if (!loaded) {
    return (
      <Wrapper>
        <h1>Loading...</h1>
      </Wrapper>
    );
  }

  if (user && user.access_token) {
    return <Route {...rest}>{children}</Route>;
  }

  return <SignInRedirect />;
};

export default PrivateRoute;
