import Axios from 'axios';
import DealerResponse from '../../models/dis/DealersResponse';

const getDealersByRegionCode = (regionCode: string) => {
  const params = {
    brandId: '1',
    resultsFormat: 'json',
    resultsMode: 'starOnly',
    resultsMax: '999',
    includePuertoRico: 'false',
    searchMode: 'filterOnly',
    regionCode,
  };

  const headers = {
    'x-api-key': process.env.REACT_APP_DIS_API_KEY,
  };

  const url = `${process.env.REACT_APP_DIS_API_URL}/disrest/getDealers`;
  return Axios.get<DealerResponse>(url, { headers, params });
};

export default getDealersByRegionCode;
