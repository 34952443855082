import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import SelectOption from '../../../../../components/common/CustomSelect/SelectOption';
import Constants from '../../../../../constants/Constants';
import { Offer, OfferCategory, OfferServiceSpecialsCategory, PricingOption } from '../../../../../gql/generated';
import { IDealer } from '../../../../../models/dealers/IDealer';
import InferredType from '../../../../../models/user/InferredType';
import UserType from '../../../../../models/user/UserType';
import getStringValue from '../../../../../utils/tools/getStringValue';
import stringArrayToMap from '../../../../../utils/tools/stringArrayToMap';
import getCouponCodeError from '../../utils/getCouponCodeError';
import getPricingAmountError from '../../utils/getPricingAmountError';
import getSelectOptions from '../../utils/getSelectOptions';

export type UseInformationProps = {
  categories?: OfferCategory[];
  dealers: IDealer[];
  dealersLoading: boolean;
  offer?: Offer;
  onSave?: (offer: any, redirect: boolean, doSave: boolean) => void;
  pricingOptions?: PricingOption[];
  serviceSpecialsCategories?: OfferServiceSpecialsCategory[];
  userType?: InferredType | null;
};

const useInformationComponent = ({ dealers, dealersLoading, offer, pricingOptions, categories, serviceSpecialsCategories, onSave, userType }: UseInformationProps) => {
  // form variables
  const amountRef = useRef<any>();
  const [categoryId, setCategoryId] = useState<string>(getStringValue(offer?.categoryId));
  const [serviceSpecialsCategoryId, setServiceSpecialsCategoryId] = useState<string>(getStringValue(offer?.serviceSpecialsCategoryId));
  const [title, setTitle] = useState<string>(getStringValue(offer?.title));
  const [subTitle, setSubtitle] = useState<string>(getStringValue(offer?.subTitle));
  const [pricingOptionId, setPricingOptionId] = useState<string>(getStringValue(offer?.pricingOptionId));
  const [amount, setAmount] = useState<string>(getStringValue(offer?.amount));
  const [couponCode, setCouponCode] = useState<string | undefined>(getStringValue(offer?.couponCode));
  const [startDate, setStartDate] = useState<Date>(offer ? dayjs(offer.startDate).toDate() : dayjs().toDate());
  const [endDate, setEndDate] = useState<Date>(offer ? dayjs(offer.endDate).toDate() : dayjs().toDate());
  const [submitted, setSubmitted] = useState(false);

  // dealer state
  const [dealerSelectMap, setDealerSelectMap] = useState<{ [code: string]: boolean }>(offer ? stringArrayToMap(offer.dealerCodes) : {});

  // validation
  const [dateValidation, setDateValidation] = useState(true);

  // select options
  const [categoryList, setCategoryList] = useState<SelectOption[]>([]);
  const [serviceSpecialsCategoryList, setServiceSpecialsCategoryList] = useState<SelectOption[]>([]);
  const [pricingOptionsList, setPricingOptionsList] = useState<SelectOption[]>([]);
  const [dealerOptionsList, setDealerOptionsList] = useState<SelectOption[]>([]);

  // options properties
  const [amountEnable, setAmountEnable] = useState(true);
  const [doSave, setDoSave] = useState(false);
  const isComplimentary = (value: string) => value === Constants.PricingOptions.COMPLIMENTARY;

  const pricingAmountError = getPricingAmountError(pricingOptionId, pricingOptionsList, amount);
  const couponCodeError = getCouponCodeError(couponCode);

  useEffect(() => {
    const catList = getSelectOptions(categories);
    setCategoryList(catList);

    const specCatList = getSelectOptions(serviceSpecialsCategories);
    setServiceSpecialsCategoryList(specCatList);

    const poList = getSelectOptions(pricingOptions);
    setPricingOptionsList(poList);

    // disable amount input if selected pricing option is not selected, or it is complementary
    const found = !offer?.pricingOptionId || poList.find(item => item.value === pricingOptionId && isComplimentary(item.label));
    setAmountEnable(!found);
  }, []);

  useEffect(() => {
    if (!dealersLoading) {
      const dlrSet = new Set<string>();
      dealers.forEach(dlr => {
        dlrSet.add(dlr.region);
      });
      const dlrList = Array.from(dlrSet).map(dlr => ({
        value: dlr,
        label: dlr,
      }));
      setDealerOptionsList([{ value: '', label: 'ALL DEALERS' }, ...dlrList]);
    }
  }, [dealersLoading]);

  const handleDealerSelect = (list: { [code: string]: boolean }) => {
    setDoSave(true);
    setDealerSelectMap(list);
  };

  // dropdown handlers
  const handlePricingOptionSelect = (opt: SelectOption | null) => {
    const poEnable = !isComplimentary(opt?.label ?? '') && !!opt?.value;
    setAmountEnable(poEnable);
    setPricingOptionId(opt?.value ?? '');

    if (!poEnable) {
      setAmount('');
      amountRef.current.value = '';
    }

    setDoSave(true);
    setSubmitted(false);
  };

  const hasSelectedDealers = () => {
    return Object.values(dealerSelectMap).some(val => val);
  };

  const isAmountValid = !amountEnable || (userType?.type === UserType.NATIONAL && !pricingOptionId) || (amountEnable && !!amount && !pricingAmountError);

  const isTitleExceeded = title.length > 50;
  const isSubtitleExceeded = subTitle.length > 50;

  const isValid = () => {
    return (
      !!title &&
      !!subTitle &&
      !!categoryId &&
      hasSelectedDealers() &&
      (!!pricingOptionId || userType?.type === UserType.NATIONAL) &&
      isAmountValid &&
      (!couponCode || (!!couponCode && !couponCodeError)) &&
      !!startDate &&
      !!endDate &&
      dateValidation &&
      !isTitleExceeded &&
      !isSubtitleExceeded
    );
  };

  const handleOnSave = (redirect = false) => {
    setSubmitted(true);
    if (onSave && isValid()) {
      // populate regionCodes from selected dealers
      const regionCodes = new Set<string>();
      dealers.forEach(dealer => {
        if (dealerSelectMap[dealer.dealerCode]) {
          regionCodes.add(dealer.regionCode);
        }
      });

      onSave(
        {
          id: offer?.id,
          rev: offer?.rev,
          categoryId,
          serviceSpecialsCategoryId,
          title,
          subTitle,
          dealerCodes: Object.keys(dealerSelectMap).filter(key => dealerSelectMap[key]),
          regionCodes: Array.from(regionCodes),
          pricingOptionId,
          amount,
          couponCode,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          type: '',
          isDeleted: false,
        },
        redirect,
        doSave
      );
    }
  };

  return {
    amount,
    amountEnable,
    amountRef,
    categoryId,
    categoryList,
    couponCode,
    couponCodeError,
    dateValidation,
    dealerOptionsList,
    dealerSelectMap,
    endDate,
    handleDealerSelect,
    handleOnSave,
    handlePricingOptionSelect,
    isAmountValid,
    isSubtitleExceeded,
    isTitleExceeded,
    pricingAmountError,
    pricingOptionId,
    pricingOptionsList,
    serviceSpecialsCategoryId,
    serviceSpecialsCategoryList,
    setAmount,
    setCategoryId,
    setCouponCode,
    setDateValidation,
    setDoSave,
    setEndDate,
    setServiceSpecialsCategoryId,
    setStartDate,
    setSubtitle,
    setTitle,
    startDate,
    subTitle,
    submitted,
    title,
  };
};

export default useInformationComponent;
