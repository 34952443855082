import React, { useEffect, useState } from 'react';
import Button from '../../../../components/common/Button';
import ButtonGroup from '../../../../components/common/ButtonGroup';
import DealersComponent from '../../../../components/common/Dealers/DealersComponent';
import FlexBox from '../../../../components/common/FlexBox';
import Input from '../../../../components/common/Input';
import LabelContainer from '../../../../components/common/LabelContainer';
import ModalBody from '../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../components/common/Modal/ModalHeader';
import PageMessage from '../../../../components/common/PageMessage';
import SelectOption from '../../../../components/common/CustomSelect/SelectOption';
import UserType from '../../../../models/user/UserType';
import ValidationText from '../../../../components/common/ValidationText';
import getAllDealers from '../../../../webapi/dis/getAllDealers';
import getDealersByDealerCode from '../../../../webapi/dis/getDealersByDealerCode';
import getDealersByRegionCode from '../../../../webapi/dis/getDealersByRegionCode';
import getStringValue from '../../../../utils/tools/getStringValue';
import stringArrayToMap from '../../../../utils/tools/stringArrayToMap';
import transformDISResponse from '../../../../utils/tools/transformDISResponse';
import withUser, { WithUserProps } from '../../../../hoc/withUser';
import { DealerGroupType } from '../../../../models/dealerGroup/DealerGroup';
import { IDealer } from '../../../../models/dealers/IDealer';
import { Scalars } from '../../../../gql/generated';
import styles from './styles.module.scss';

interface DealerGroupModalProps extends WithUserProps {
  dealerGroup?: DealerGroupType;
  onClose: () => void;
  onCreate?: (name: string, dealers: string[]) => void;
  onUpdate?: (dealerGroupID: string, rev: string, name: string, dealers: string[]) => void;
}

const DealerGroupModal = ({ dealerGroup, onCreate, onClose, onUpdate, user, userType }: DealerGroupModalProps) => {
  const [dataError, setDataError] = useState<boolean>(false);
  const [dealerOptionsList, setDealerOptionsList] = useState<SelectOption[]>([]);
  const [dealerSelectMap, setDealerSelectMap] = useState<{ [code: string]: boolean }>(dealerGroup ? stringArrayToMap(dealerGroup.dealers) : {});
  const [dealers, setDealers] = useState<IDealer[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [name, setName] = useState<string>(getStringValue(dealerGroup?.name));
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [hasSelectedDealers, setHasSelectedDealers] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (userType?.type === UserType.REGIONAL) {
          const regionCode = (user?.profile.tmscorporateregioncode as string).substring(0, 2);
          const dealersResponse = await getDealersByRegionCode(regionCode);
          setDealers(transformDISResponse(dealersResponse.data));
          setIsLoaded(true);
        } else if (userType?.type === UserType.DEALER) {
          const dealersResponse = await getDealersByDealerCode(user?.profile.primary_dealer_number || '');
          setDealers(transformDISResponse(dealersResponse.data));
          setIsLoaded(true);
        } else {
          const dealersResponse = await getAllDealers();
          setDealers(transformDISResponse(dealersResponse.data));
          setIsLoaded(true);
        }
      } catch {
        setDataError(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const dlrSet = new Set<string>();
      dealers.forEach(dlr => {
        dlrSet.add(dlr.region);
      });
      const dlrList = Array.from(dlrSet).map(dlr => ({
        value: dlr,
        label: dlr,
      }));

      setDealerOptionsList([{ value: '', label: 'ALL DEALERS' }, ...dlrList]);
    }
  }, [isLoaded]);

  useEffect(() => {
    setHasSelectedDealers(Object.values(dealerSelectMap).some(val => val));
  }, [dealerSelectMap]);

  const handleDealerSelect = (list: { [code: string]: boolean }) => {
    setDealerSelectMap(list);
  };

  const createDealerGroupHandler = async () => {
    const dealerSelected = Object.keys(dealerSelectMap).filter(keys => dealerSelectMap[keys] === true);
    if (onCreate && !dealerGroup && name && hasSelectedDealers) {
      onCreate(name, dealerSelected);
      onClose();
    }

    if (onUpdate && dealerGroup && dealerGroup?.id && name && hasSelectedDealers) {
      const dealerGroupRev: Scalars['ID'] = dealerGroup.rev;
      onUpdate(dealerGroup?.id, dealerGroupRev, name, dealerSelected);
      onClose();
    }
    setSubmitted(true);
  };

  return (
    <div className={styles.dealerGroupModal}>
      <ModalHeader title={`${!dealerGroup ? 'Add' : 'Edit'} Dealer Group`} onClose={onClose} />
      <ModalBody fixHeighMiddle>
        {dataError && <PageMessage message="Service Error. Please contact your administrator." />}
        {!dataError && (
          <>
            <FlexBox>
              <FlexBox className={styles.dealerGroupName}>
                <LabelContainer label="Dealer Group Name" vertical>
                  <Input
                    autoFocus
                    defaultValue={name}
                    error={submitted && !name}
                    id="dealer-group-name-input"
                    onChange={e => {
                      setName(e.currentTarget.value);
                    }}
                  />
                </LabelContainer>
              </FlexBox>
              <div className={styles.errorMessage}> {submitted && !name && <ValidationText>Name is required</ValidationText>}</div>
            </FlexBox>

            <DealersComponent
              dealerOptionsList={dealerOptionsList}
              dealers={dealers}
              dealersLoading={!isLoaded}
              initialDealerCodes={dealerGroup?.dealers}
              onDealerSelect={handleDealerSelect}
              showDealerGroupButton={false}
              submitted={submitted}
            />
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-close-btn" onClick={onClose} variant="primary">
            Close
          </Button>
          <Button disabled={dataError} id="modal-save-btn" onClick={createDealerGroupHandler} variant="primary">
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div>
  );
};

export default withUser(DealerGroupModal);
