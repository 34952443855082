import React from 'react';
import { Offer } from '../../../../../../../gql/generated';
import getS3ImageUrl from '../../../../../images/utils/getS3ImageUrl';
import styles from './styles.module.scss';

interface OfferDetailProps {
  offer: Offer;
}

const OfferDetail = ({ offer }: OfferDetailProps) => {
  return (
    <div className={styles.offerDetail}>
      <h2>Offer: {offer.title}</h2>
      <img src={getS3ImageUrl(offer.detailsImage ? offer.detailsImage : '')} alt="Offer card detail" />
      {offer.disclaimers?.map(disclaimer => (
        <p key={disclaimer} className={styles.disclaimer}>
          {disclaimer}
        </p>
      ))}
    </div>
  );
};

export default OfferDetail;
