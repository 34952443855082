import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import DataLoadingController from '../../../components/Auth/DataLoadingController';
import MainLayout from '../../../components/layout/MainLayout';
import Wrapper from '../../../components/layout/Wrapper';
import {
  Disclaimer,
  FetchDisclaimersDocument,
  useCreateDisclaimerMutation,
  useDeleteDisclaimerMutation,
  useFetchDisclaimersQuery,
  useUpdateDisclaimerMutation,
} from '../../../gql/generated';
import withUser, { WithUserProps } from '../../../hoc/withUser';
import SettingsContainer from '../components/SettingsContainer';
import SettingsContent from '../components/SettingsContent';
import SettingsNav from '../components/SettingsNav';
import settingsMenu from '../settingsMenu';
import DisclaimersComponent from './components/DisclaimersComponent';

const DisclaimersController = ({ user }: WithUserProps) => {
  const { data: disclaimersData, loading: disclaimersLoading, error: disclaimersError } = useFetchDisclaimersQuery({});
  const [isNationalUser, setIsNationalUser] = useState(false);

  const [deleteDisclaimer] = useDeleteDisclaimerMutation({ refetchQueries: [{ query: FetchDisclaimersDocument }] });
  const [updateDisclaimer] = useUpdateDisclaimerMutation({ refetchQueries: [{ query: FetchDisclaimersDocument }] });
  const [createDisclaimer] = useCreateDisclaimerMutation({ refetchQueries: [{ query: FetchDisclaimersDocument }] });

  useEffect(() => {
    const roles: string[] = typeof user?.profile.roles === 'string' ? user?.profile.roles.split(',') : user?.profile.roles?.join(',').split(',') ?? [];
    if (roles.includes('TOOMS_National_user')) {
      setIsNationalUser(true);
    }
  }, []);

  const createDisclaimerHandler = async (title: string, disclaimer: string) => {
    try {
      await createDisclaimer({
        variables: {
          title,
          disclaimer,
        },
      });
      toast.success('Disclaimer successfully added');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const updateDisclaimerHandler = async (id: string, rev: string, title: string, disclaimer: string) => {
    try {
      await updateDisclaimer({
        variables: {
          id,
          rev,
          title,
          disclaimer,
        },
      });
      toast.success('Disclaimer succesfully updated');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const deleteDisclaimerHandler = async (disclaimer: Disclaimer) => {
    if (disclaimer) {
      try {
        await deleteDisclaimer({ variables: { id: disclaimer.id, rev: disclaimer.rev } });
        toast.success('Disclaimer successfully deleted');
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  return (
    <MainLayout>
      <Wrapper>
        <DataLoadingController
          component={
            <SettingsContainer>
              <SettingsNav activeLink="disclaimers" settingsMenu={settingsMenu} />
              <SettingsContent>
                <DisclaimersComponent
                  disclaimers={disclaimersData?.disclaimers as Disclaimer[]}
                  isNationalUser={isNationalUser}
                  onCreate={createDisclaimerHandler}
                  onDelete={deleteDisclaimerHandler}
                  onUpdate={updateDisclaimerHandler}
                />
              </SettingsContent>
            </SettingsContainer>
          }
          apolloErrors={[disclaimersError]}
          isLoaded={!disclaimersLoading}
        />
      </Wrapper>
    </MainLayout>
  );
};

export default withUser(DisclaimersController);
