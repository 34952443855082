import { Offer } from '../../gql/generated';
import { IOfferUpdate } from '../../models/offers/IOffer';

const transformOfferForUpdate = (offer: Offer): IOfferUpdate => {
  return {
    amount: offer.amount || '',
    categoryId: offer.categoryId,
    consumerIds: offer.consumerIds as string[],
    couponCode: offer.couponCode as string,
    dealerCodes: offer.dealerCodes as string[],
    description: offer.description as string,
    detailsImageId: offer.detailsImage as string,
    disclaimers: offer.disclaimers as string[],
    endDate: offer.endDate,
    id: offer.id,
    isDeleted: offer.isDeleted as boolean,
    pricingOptionId: offer.pricingOptionId || '',
    regionCodes: offer.regionCodes as string[],
    rev: offer.rev,
    serviceSpecialsCategoryId: offer.serviceSpecialsCategoryId || '',
    startDate: offer.startDate,
    subTitle: offer.subTitle,
    thumbnailImageId: offer.thumbnailImageId as string,
    title: offer.title,
    type: offer.type,
  };
};

export default transformOfferForUpdate;
