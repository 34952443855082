import React, { useEffect } from 'react';
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AuthCodeHandler from './components/Auth/AuthCodeHandler';
import { PrivateRoute } from './components/Auth/PrivateRoute';
import SignInRedirect from './components/Auth/SignInRedirect';
import SignOutRedirect from './components/Auth/SignOutRedirect';
import SilentRenew from './components/Auth/SilentRenew';
import UserProfile from './components/Auth/UserProfile';
import PageMessage from './components/common/PageMessage';
import MainLayout from './components/layout/MainLayout';
import Wrapper from './components/layout/Wrapper';
import useUserProfile from './hooks/useUserProfile';
import UserType from './models/user/UserType';
import DashboardController from './modules/dashboard/DashboardController';
import DealerGroupController from './modules/dealerGroup/DealerGroupController';
import ViewImagesController from './modules/offer/images/ViewImagesController';
import CreateInformationController from './modules/offer/information/CreateInformationController';
import ViewInformationController from './modules/offer/information/ViewInformationController';
import ViewMarketingController from './modules/offer/marketing/ViewMarketingController';
import ReportsController from './modules/reports/ReportsController';
import DisclaimersController from './modules/settings/disclaimers/DisclaimersController';
import HelpController from './modules/settings/help/HelpController';
import ImageLibraryController from './modules/settings/imageLibrary/ImageLibraryController';
import SettingsController from './modules/settings/SettingsController';
import WelcomeController from './modules/welcome/WelcomeController';

interface RoutesProps {
  isSessionExpired: boolean;
}

const Routes: React.FC<RoutesProps> = ({ isSessionExpired }: RoutesProps) => {
  const history = useHistory();
  const [, userType] = useUserProfile();

  useEffect(() => {
    if (isSessionExpired) {
      history.push('signout');
    }
  });

  return (
    <Switch>
      <Route exact path="/code">
        <AuthCodeHandler onSuccess={() => history.replace('/welcome')} onError={() => history.replace('/error')} />
      </Route>
      <Route exact path="/signin">
        <SignInRedirect />
      </Route>
      <Route exact path="/signout">
        <SignOutRedirect />
      </Route>
      <Route exact path="/signedout">
        <Wrapper>
          <h1>Signed Out</h1>
          <Link to="/signin">Sign In Again</Link>
        </Wrapper>
      </Route>
      <Route exact path="/user/profile">
        <UserProfile />
      </Route>
      <Route exact path="/error">
        <Wrapper>
          <h1>Error</h1>
          <p>You should probably check the console</p>
        </Wrapper>
      </Route>
      <Route exact path="/silentrenew">
        <SilentRenew />
      </Route>
      <PrivateRoute exact path="/welcome">
        <WelcomeController />
      </PrivateRoute>
      <PrivateRoute exact path="/dashboard">
        <DashboardController />
      </PrivateRoute>
      <PrivateRoute exact path="/createOffer/info">
        <CreateInformationController />
      </PrivateRoute>
      <Redirect from="/createOffer" to="/createOffer/info" />
      <PrivateRoute exact path="/viewOffer/info/:id">
        <ViewInformationController />
      </PrivateRoute>
      <PrivateRoute exact path="/viewOffer/marketing/:id">
        <ViewMarketingController />
      </PrivateRoute>
      <PrivateRoute exact path="/viewOffer/images/:id">
        <ViewImagesController />
      </PrivateRoute>
      <PrivateRoute exact path="/dealerGroup">
        <DealerGroupController />
      </PrivateRoute>
      <PrivateRoute exact path="/reports">
        {userType?.type !== UserType.DEALER ? (
          <ReportsController />
        ) : (
          <MainLayout>
            <PageMessage message="Not Authorized" />
          </MainLayout>
        )}
      </PrivateRoute>
      <PrivateRoute exact path="/settings">
        <SettingsController />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/imageLibrary">
        <ImageLibraryController />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/help">
        <HelpController />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/disclaimers">
        <DisclaimersController />
      </PrivateRoute>
      <Redirect from="/viewOffer" to="/viewOffer/info" />
      <Redirect from="/" to="/signin" />
    </Switch>
  );
};

export default Routes;
