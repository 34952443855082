import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Consumer = {
  __typename?: 'Consumer';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateDataInput = {
  data: Scalars['String'];
};

export type CreateDataResult = {
  __typename?: 'CreateDataResult';
  success: Scalars['Boolean'];
};

export type CreateDealerGroupInput = {
  name: Scalars['String'];
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealers: Array<Maybe<Scalars['String']>>;
};

export type CreateDealerGroupResult = {
  __typename?: 'CreateDealerGroupResult';
  success: Scalars['Boolean'];
  dealerGroup: DealerGroup;
};

export type CreateDisclaimerInput = {
  title: Scalars['String'];
  disclaimer: Scalars['String'];
};

export type CreateDisclaimerResult = {
  __typename?: 'CreateDisclaimerResult';
  success: Scalars['Boolean'];
  disclaimer: Disclaimer;
};

export type CreateImageInput = {
  type: Scalars['String'];
  fileName: Scalars['String'];
  name: Scalars['String'];
  offerId?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
};

export type CreateImageResult = {
  __typename?: 'CreateImageResult';
  success: Scalars['Boolean'];
  image: Image;
};

export type CreateOfferInput = {
  type: Scalars['String'];
  categoryId: Scalars['ID'];
  serviceSpecialsCategoryId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  subTitle: Scalars['String'];
  regionCodes: Array<Maybe<Scalars['String']>>;
  dealerCodes: Array<Maybe<Scalars['String']>>;
  pricingOptionId: Scalars['String'];
  amount?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CreateOfferResult = {
  __typename?: 'CreateOfferResult';
  success: Scalars['Boolean'];
  offer: Offer;
};

export type CreateReportInput = {
  userType: Scalars['String'];
  type: Scalars['String'];
  kind: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
};

export type CreateReportResult = {
  __typename?: 'CreateReportResult';
  success: Scalars['Boolean'];
};

export type DealerGroup = {
  __typename?: 'DealerGroup';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  dealers: Array<Maybe<Scalars['String']>>;
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type DeleteDealerGroupInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteDealerGroupResult = {
  __typename?: 'DeleteDealerGroupResult';
  success: Scalars['Boolean'];
};

export type DeleteDisclaimerInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteDisclaimerResult = {
  __typename?: 'DeleteDisclaimerResult';
  success: Scalars['Boolean'];
};

export type DeleteImageInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteImageResult = {
  __typename?: 'DeleteImageResult';
  success: Scalars['Boolean'];
  offerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeleteOfferInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteOfferResult = {
  __typename?: 'DeleteOfferResult';
  success: Scalars['Boolean'];
};

export type Disclaimer = {
  __typename?: 'Disclaimer';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  type: Scalars['String'];
  title: Scalars['String'];
  disclaimer: Scalars['String'];
  created?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type DownloadUserGuide = {
  __typename?: 'DownloadUserGuide';
  url: Scalars['String'];
};

export type ExecutionDetails = {
  __typename?: 'ExecutionDetails';
  name: Scalars['String'];
  status: Scalars['String'];
  executionArn?: Maybe<Scalars['String']>;
  input?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  stopDate?: Maybe<Scalars['Int']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
  fileName: Scalars['String'];
  type: Scalars['String'];
  offerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  s3Key: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  createDealerGroup: CreateDealerGroupResult;
  deleteDealerGroup: DeleteDealerGroupResult;
  updateDealerGroup: UpdateDealerGroupResult;
  createDisclaimer: CreateDisclaimerResult;
  deleteDisclaimer: DeleteDisclaimerResult;
  updateDisclaimer: UpdateDisclaimerResult;
  createOffer: CreateOfferResult;
  updateOffer: UpdateOfferResult;
  deleteOffer: DeleteOfferResult;
  updateOfferStatus: UpdateOfferStatusResult;
  createImage: CreateImageResult;
  updateImage: UpdateImageResult;
  deleteImage: DeleteImageResult;
  publishOffers: PublishResult;
  createData: CreateDataResult;
  createReport: CreateReportResult;
};


export type MutationCreateDealerGroupArgs = {
  input: CreateDealerGroupInput;
};


export type MutationDeleteDealerGroupArgs = {
  input: DeleteDealerGroupInput;
};


export type MutationUpdateDealerGroupArgs = {
  input: UpdateDealerGroupInput;
};


export type MutationCreateDisclaimerArgs = {
  input: CreateDisclaimerInput;
};


export type MutationDeleteDisclaimerArgs = {
  input: DeleteDisclaimerInput;
};


export type MutationUpdateDisclaimerArgs = {
  input: UpdateDisclaimerInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationUpdateOfferArgs = {
  input: UpdateOfferInput;
};


export type MutationDeleteOfferArgs = {
  input: DeleteOfferInput;
};


export type MutationUpdateOfferStatusArgs = {
  input: UpdateOfferStatusInput;
};


export type MutationCreateImageArgs = {
  input: CreateImageInput;
};


export type MutationUpdateImageArgs = {
  input: UpdateImageInput;
};


export type MutationDeleteImageArgs = {
  input: DeleteImageInput;
};


export type MutationPublishOffersArgs = {
  input: PublishInput;
};


export type MutationCreateDataArgs = {
  input: CreateDataInput;
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};

export type Offer = {
  __typename?: 'Offer';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  type: Scalars['String'];
  categoryId: Scalars['ID'];
  serviceSpecialsCategoryId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  subTitle: Scalars['String'];
  regionCodes: Array<Maybe<Scalars['String']>>;
  dealerCodes: Array<Maybe<Scalars['String']>>;
  pricingOptionId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  thumbnailImageId?: Maybe<Scalars['String']>;
  thumbnailImage?: Maybe<Scalars['String']>;
  detailsImageId?: Maybe<Scalars['String']>;
  detailsImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimers?: Maybe<Array<Maybe<Scalars['String']>>>;
  consumerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type OfferCategory = {
  __typename?: 'OfferCategory';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
  userType: Array<Maybe<Scalars['String']>>;
};

export type OfferMetrics = {
  __typename?: 'OfferMetrics';
  totalOffers: Scalars['Int'];
  nationalOffers: Scalars['Int'];
  regionalOffers: Scalars['Int'];
  dealerOffers: Scalars['Int'];
};

export type OfferServiceSpecialsCategory = {
  __typename?: 'OfferServiceSpecialsCategory';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
  userType: Array<Maybe<Scalars['String']>>;
};

export type OfferTotals = {
  __typename?: 'OfferTotals';
  totalOffers: Scalars['Int'];
  nationalOffers: Scalars['Int'];
  regionalOffers: Scalars['Int'];
  dealerOffers: Scalars['Int'];
  expiredOffers: Scalars['Int'];
};

export type PricingOption = {
  __typename?: 'PricingOption';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
};

export type PublishInput = {
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
};

export type PublishLog = {
  __typename?: 'PublishLog';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  executionArn: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type PublishResult = {
  __typename?: 'PublishResult';
  success: Scalars['Boolean'];
  publishLogId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  offerCategory?: Maybe<OfferCategory>;
  offerCategories?: Maybe<Array<Maybe<OfferCategory>>>;
  offerServiceSpecialsCategory?: Maybe<OfferServiceSpecialsCategory>;
  offerServiceSpecialsCategories?: Maybe<Array<Maybe<OfferServiceSpecialsCategory>>>;
  pricingOption?: Maybe<PricingOption>;
  pricingOptions?: Maybe<Array<Maybe<PricingOption>>>;
  consumer?: Maybe<Consumer>;
  consumers?: Maybe<Array<Maybe<Consumer>>>;
  dealerGroup?: Maybe<DealerGroup>;
  dealerGroups?: Maybe<Array<Maybe<DealerGroup>>>;
  disclaimer?: Maybe<Disclaimer>;
  disclaimers?: Maybe<Array<Maybe<Disclaimer>>>;
  downloadUserGuide?: Maybe<DownloadUserGuide>;
  offers?: Maybe<Array<Maybe<Offer>>>;
  offer?: Maybe<Offer>;
  offerMetrics: OfferMetrics;
  offerTotals: OfferTotals;
  images?: Maybe<Array<Maybe<Image>>>;
  image?: Maybe<Image>;
  presignedUploadUrl?: Maybe<Scalars['String']>;
  publishLogs?: Maybe<Array<Maybe<PublishLog>>>;
  publishStatus: ExecutionDetails;
  reports?: Maybe<Array<Report>>;
  presignedReportDownloadUrl: Scalars['String'];
};


export type QueryOfferCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryOfferCategoriesArgs = {
  type: Scalars['String'];
};


export type QueryOfferServiceSpecialsCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryOfferServiceSpecialsCategoriesArgs = {
  type: Scalars['String'];
};


export type QueryPricingOptionArgs = {
  id: Scalars['ID'];
};


export type QueryPricingOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
};


export type QueryConsumerArgs = {
  id: Scalars['ID'];
};


export type QueryConsumersArgs = {
  first?: Maybe<Scalars['Int']>;
};


export type QueryDealerGroupArgs = {
  id: Scalars['String'];
};


export type QueryDealerGroupsArgs = {
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
};


export type QueryDisclaimerArgs = {
  id: Scalars['String'];
};


export type QueryOffersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  active: Scalars['Boolean'];
};


export type QueryOfferArgs = {
  id: Scalars['String'];
};


export type QueryOfferMetricsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryOfferTotalsArgs = {
  userType: Scalars['String'];
};


export type QueryImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
};


export type QueryImageArgs = {
  id: Scalars['String'];
};


export type QueryPresignedUploadUrlArgs = {
  type: Scalars['String'];
  fileName: Scalars['String'];
  offerId?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
};


export type QueryPublishLogsArgs = {
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
};


export type QueryPublishStatusArgs = {
  id: Scalars['String'];
};


export type QueryReportsArgs = {
  userType: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
};


export type QueryPresignedReportDownloadUrlArgs = {
  userType: Scalars['String'];
  reportId: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  rev: Scalars['ID'];
  userType: Scalars['String'];
  type: Scalars['String'];
  kind: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  s3Key: Scalars['String'];
  createdDate: Scalars['String'];
  createdBy: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
};

export type UpdateDealerGroupInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  dealers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateDealerGroupResult = {
  __typename?: 'UpdateDealerGroupResult';
  success: Scalars['Boolean'];
  dealerGroup: DealerGroup;
};

export type UpdateDisclaimerInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
};

export type UpdateDisclaimerResult = {
  __typename?: 'UpdateDisclaimerResult';
  success: Scalars['Boolean'];
  disclaimer: Disclaimer;
};

export type UpdateImageInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateImageResult = {
  __typename?: 'UpdateImageResult';
  success: Scalars['Boolean'];
  image: Image;
};

export type UpdateOfferInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
  categoryId?: Maybe<Scalars['ID']>;
  serviceSpecialsCategoryId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  regionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealerCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  pricingOptionId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  thumbnailImageId?: Maybe<Scalars['String']>;
  detailsImageId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimers?: Maybe<Array<Maybe<Scalars['String']>>>;
  consumerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateOfferResult = {
  __typename?: 'UpdateOfferResult';
  success: Scalars['Boolean'];
  offer: Offer;
};

export type UpdateOfferStatusInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
  status: Scalars['String'];
};

export type UpdateOfferStatusResult = {
  __typename?: 'UpdateOfferStatusResult';
  success: Scalars['Boolean'];
  offer: Offer;
};


export type FetchConsumersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;


export type FetchConsumersQuery = (
  { __typename?: 'Query' }
  & { consumers?: Maybe<Array<Maybe<(
    { __typename?: 'Consumer' }
    & ConsumerFieldsFragment
  )>>> }
);

export type ConsumerFieldsFragment = (
  { __typename?: 'Consumer' }
  & Pick<Consumer, 'id' | 'rev' | 'name'>
);

export type FetchDashboardDataQueryVariables = Exact<{
  categoryType: Scalars['String'];
}>;


export type FetchDashboardDataQuery = (
  { __typename?: 'Query' }
  & { active?: Maybe<Array<Maybe<(
    { __typename?: 'Offer' }
    & DashboardOfferFieldsFragment
  )>>>, expired?: Maybe<Array<Maybe<(
    { __typename?: 'Offer' }
    & DashboardOfferFieldsFragment
  )>>>, offerCategories?: Maybe<Array<Maybe<(
    { __typename?: 'OfferCategory' }
    & OfferCategoryFieldsFragment
  )>>> }
);

export type DashboardOfferFieldsFragment = (
  { __typename?: 'Offer' }
  & Pick<Offer, 'id' | 'rev' | 'type' | 'categoryId' | 'title' | 'startDate' | 'endDate' | 'status' | 'regionCodes' | 'dealerCodes' | 'isDeleted'>
);

export type CreateDealerGroupMutationVariables = Exact<{
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  dealers: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type CreateDealerGroupMutation = (
  { __typename?: 'Mutation' }
  & { createDealerGroup: (
    { __typename?: 'CreateDealerGroupResult' }
    & Pick<CreateDealerGroupResult, 'success'>
    & { dealerGroup: (
      { __typename?: 'DealerGroup' }
      & DealerGroupFieldsFragment
    ) }
  ) }
);

export type DeleteDealerGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteDealerGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteDealerGroup: (
    { __typename?: 'DeleteDealerGroupResult' }
    & Pick<DeleteDealerGroupResult, 'success'>
  ) }
);

export type FetchDealerGroupByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchDealerGroupByIdQuery = (
  { __typename?: 'Query' }
  & { dealerGroup?: Maybe<(
    { __typename?: 'DealerGroup' }
    & DealerGroupFieldsFragment
  )> }
);

export type FetchDealerGroupsQueryVariables = Exact<{
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
}>;


export type FetchDealerGroupsQuery = (
  { __typename?: 'Query' }
  & { dealerGroups?: Maybe<Array<Maybe<(
    { __typename?: 'DealerGroup' }
    & DealerGroupFieldsFragment
  )>>> }
);

export type DealerGroupFieldsFragment = (
  { __typename?: 'DealerGroup' }
  & Pick<DealerGroup, 'id' | 'rev' | 'name' | 'type' | 'regionCode' | 'dealers' | 'updated' | 'updatedBy'>
);

export type UpdateDealerGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  dealers?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateDealerGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateDealerGroup: (
    { __typename?: 'UpdateDealerGroupResult' }
    & Pick<UpdateDealerGroupResult, 'success'>
    & { dealerGroup: (
      { __typename?: 'DealerGroup' }
      & DealerGroupFieldsFragment
    ) }
  ) }
);

export type CreateDisclaimerMutationVariables = Exact<{
  title: Scalars['String'];
  disclaimer: Scalars['String'];
}>;


export type CreateDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { createDisclaimer: (
    { __typename?: 'CreateDisclaimerResult' }
    & Pick<CreateDisclaimerResult, 'success'>
    & { disclaimer: (
      { __typename?: 'Disclaimer' }
      & DisclaimerFieldsFragment
    ) }
  ) }
);

export type DeleteDisclaimerMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { deleteDisclaimer: (
    { __typename?: 'DeleteDisclaimerResult' }
    & Pick<DeleteDisclaimerResult, 'success'>
  ) }
);

export type FetchDisclaimerByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchDisclaimerByIdQuery = (
  { __typename?: 'Query' }
  & { disclaimer?: Maybe<(
    { __typename?: 'Disclaimer' }
    & DisclaimerFieldsFragment
  )> }
);

export type FetchDisclaimersQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchDisclaimersQuery = (
  { __typename?: 'Query' }
  & { disclaimers?: Maybe<Array<Maybe<(
    { __typename?: 'Disclaimer' }
    & DisclaimerFieldsFragment
  )>>> }
);

export type DisclaimerFieldsFragment = (
  { __typename?: 'Disclaimer' }
  & Pick<Disclaimer, 'id' | 'rev' | 'title' | 'type' | 'disclaimer' | 'created' | 'updated' | 'updatedBy'>
);

export type UpdateDisclaimerMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  rev: Scalars['ID'];
}>;


export type UpdateDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { updateDisclaimer: (
    { __typename?: 'UpdateDisclaimerResult' }
    & Pick<UpdateDisclaimerResult, 'success'>
    & { disclaimer: (
      { __typename?: 'Disclaimer' }
      & DisclaimerFieldsFragment
    ) }
  ) }
);

export type CreateImageMutationVariables = Exact<{
  type: Scalars['String'];
  fileName: Scalars['String'];
  name: Scalars['String'];
  offerId?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
}>;


export type CreateImageMutation = (
  { __typename?: 'Mutation' }
  & { createImage: (
    { __typename?: 'CreateImageResult' }
    & Pick<CreateImageResult, 'success'>
    & { image: (
      { __typename?: 'Image' }
      & ImageFieldsFragment
    ) }
  ) }
);

export type DeleteImageMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteImage: (
    { __typename?: 'DeleteImageResult' }
    & Pick<DeleteImageResult, 'success'>
  ) }
);

export type FetchImageByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchImageByIdQuery = (
  { __typename?: 'Query' }
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )> }
);

export type FetchImagesQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type FetchImagesQuery = (
  { __typename?: 'Query' }
  & { images?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>>> }
);

export type ImageFieldsFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'id' | 'rev' | 'type' | 'fileName' | 'name' | 'offerIds' | 's3Key' | 'regionCode' | 'dealerCode' | 'updated' | 'updatedBy'>
);

export type GetPresignedUrlQueryVariables = Exact<{
  type: Scalars['String'];
  fileName: Scalars['String'];
  offerId?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
}>;


export type GetPresignedUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'presignedUploadUrl'>
);

export type UpdateImageMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}>;


export type UpdateImageMutation = (
  { __typename?: 'Mutation' }
  & { updateImage: (
    { __typename?: 'UpdateImageResult' }
    & Pick<UpdateImageResult, 'success'>
    & { image: (
      { __typename?: 'Image' }
      & ImageFieldsFragment
    ) }
  ) }
);

export type FetchOfferCategoriesQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type FetchOfferCategoriesQuery = (
  { __typename?: 'Query' }
  & { offerCategories?: Maybe<Array<Maybe<(
    { __typename?: 'OfferCategory' }
    & OfferCategoryFieldsFragment
  )>>> }
);

export type OfferCategoryFieldsFragment = (
  { __typename?: 'OfferCategory' }
  & Pick<OfferCategory, 'id' | 'rev' | 'name' | 'userType'>
);

export type FetchOfferMetricsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type FetchOfferMetricsQuery = (
  { __typename?: 'Query' }
  & { offerMetrics: (
    { __typename?: 'OfferMetrics' }
    & OfferMetricsFieldsFragment
  ) }
);

export type OfferMetricsFieldsFragment = (
  { __typename?: 'OfferMetrics' }
  & Pick<OfferMetrics, 'totalOffers' | 'nationalOffers' | 'regionalOffers' | 'dealerOffers'>
);

export type FetchOfferServiceSpecialsCategoriesQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type FetchOfferServiceSpecialsCategoriesQuery = (
  { __typename?: 'Query' }
  & { offerServiceSpecialsCategories?: Maybe<Array<Maybe<(
    { __typename?: 'OfferServiceSpecialsCategory' }
    & OfferServiceSpecialsCategoryFieldsFragment
  )>>> }
);

export type OfferServiceSpecialsCategoryFieldsFragment = (
  { __typename?: 'OfferServiceSpecialsCategory' }
  & Pick<OfferServiceSpecialsCategory, 'id' | 'rev' | 'name' | 'userType'>
);

export type FetchOfferTotalsQueryVariables = Exact<{
  userType: Scalars['String'];
}>;


export type FetchOfferTotalsQuery = (
  { __typename?: 'Query' }
  & { offerTotals: (
    { __typename?: 'OfferTotals' }
    & OfferTotalsFieldsFragment
  ) }
);

export type OfferTotalsFieldsFragment = (
  { __typename?: 'OfferTotals' }
  & Pick<OfferTotals, 'totalOffers' | 'nationalOffers' | 'regionalOffers' | 'dealerOffers' | 'expiredOffers'>
);

export type CreateOfferMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  serviceSpecialsCategoryId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: Scalars['String'];
  subTitle: Scalars['String'];
  regionCodes: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  dealerCodes: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  pricingOptionId: Scalars['String'];
  amount?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type CreateOfferMutation = (
  { __typename?: 'Mutation' }
  & { createOffer: (
    { __typename?: 'CreateOfferResult' }
    & Pick<CreateOfferResult, 'success'>
    & { offer: (
      { __typename?: 'Offer' }
      & OfferFieldsFragment
    ) }
  ) }
);

export type DeleteOfferMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteOfferMutation = (
  { __typename?: 'Mutation' }
  & { deleteOffer: (
    { __typename?: 'DeleteOfferResult' }
    & Pick<DeleteOfferResult, 'success'>
  ) }
);

export type DownloadUserGuideQueryVariables = Exact<{ [key: string]: never; }>;


export type DownloadUserGuideQuery = (
  { __typename?: 'Query' }
  & { downloadUserGuide?: Maybe<(
    { __typename?: 'DownloadUserGuide' }
    & Pick<DownloadUserGuide, 'url'>
  )> }
);

export type FetchOfferByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchOfferByIdQuery = (
  { __typename?: 'Query' }
  & { offer?: Maybe<(
    { __typename?: 'Offer' }
    & OfferFieldsFragment
  )> }
);

export type FetchOffersQueryVariables = Exact<{
  active: Scalars['Boolean'];
}>;


export type FetchOffersQuery = (
  { __typename?: 'Query' }
  & { offers?: Maybe<Array<Maybe<(
    { __typename?: 'Offer' }
    & OfferFieldsFragment
  )>>> }
);

export type OfferFieldsFragment = (
  { __typename?: 'Offer' }
  & Pick<Offer, 'id' | 'rev' | 'type' | 'categoryId' | 'serviceSpecialsCategoryId' | 'title' | 'subTitle' | 'regionCodes' | 'dealerCodes' | 'pricingOptionId' | 'amount' | 'couponCode' | 'startDate' | 'endDate' | 'thumbnailImageId' | 'thumbnailImage' | 'detailsImageId' | 'detailsImage' | 'description' | 'disclaimers' | 'consumerIds' | 'status' | 'isDeleted'>
);

export type PublishOffersMutationVariables = Exact<{
  type: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
}>;


export type PublishOffersMutation = (
  { __typename?: 'Mutation' }
  & { publishOffers: (
    { __typename?: 'PublishResult' }
    & Pick<PublishResult, 'success'>
  ) }
);

export type UpdateOfferMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  categoryId?: Maybe<Scalars['ID']>;
  serviceSpecialsCategoryId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  regionCodes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  dealerCodes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  pricingOptionId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  thumbnailImageId?: Maybe<Scalars['String']>;
  detailsImageId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimers?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  consumerIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateOfferMutation = (
  { __typename?: 'Mutation' }
  & { updateOffer: (
    { __typename?: 'UpdateOfferResult' }
    & Pick<UpdateOfferResult, 'success'>
    & { offer: (
      { __typename?: 'Offer' }
      & OfferFieldsFragment
    ) }
  ) }
);

export type UpdateOfferStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  status: Scalars['String'];
}>;


export type UpdateOfferStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOfferStatus: (
    { __typename?: 'UpdateOfferStatusResult' }
    & Pick<UpdateOfferStatusResult, 'success'>
    & { offer: (
      { __typename?: 'Offer' }
      & OfferFieldsFragment
    ) }
  ) }
);

export type FetchPricingOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;


export type FetchPricingOptionsQuery = (
  { __typename?: 'Query' }
  & { pricingOptions?: Maybe<Array<Maybe<(
    { __typename?: 'PricingOption' }
    & PricingOptionFieldsFragment
  )>>> }
);

export type PricingOptionFieldsFragment = (
  { __typename?: 'PricingOption' }
  & Pick<PricingOption, 'id' | 'rev' | 'name'>
);

export type CreateReportMutationVariables = Exact<{
  userType: Scalars['String'];
  type: Scalars['String'];
  kind: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'CreateReportResult' }
    & Pick<CreateReportResult, 'success'>
  ) }
);

export type FetchReportsQueryVariables = Exact<{
  userType: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  dealerCode?: Maybe<Scalars['String']>;
}>;


export type FetchReportsQuery = (
  { __typename?: 'Query' }
  & { reports?: Maybe<Array<(
    { __typename?: 'Report' }
    & ReportFieldsFragment
  )>> }
);

export type ReportFieldsFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'rev' | 'userType' | 'type' | 'kind' | 'name' | 'startDate' | 'endDate' | 's3Key' | 'regionCode' | 'dealerCode' | 'createdDate' | 'createdBy'>
);

export type GetPresignedReportDownloadUrlQueryVariables = Exact<{
  userType: Scalars['String'];
  reportId: Scalars['String'];
}>;


export type GetPresignedReportDownloadUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'presignedReportDownloadUrl'>
);

export const ConsumerFieldsFragmentDoc = gql`
    fragment ConsumerFields on Consumer {
  id
  rev
  name
}
    `;
export const DashboardOfferFieldsFragmentDoc = gql`
    fragment DashboardOfferFields on Offer {
  id
  rev
  type
  categoryId
  title
  startDate
  endDate
  status
  regionCodes
  dealerCodes
  isDeleted
}
    `;
export const DealerGroupFieldsFragmentDoc = gql`
    fragment DealerGroupFields on DealerGroup {
  id
  rev
  name
  type
  regionCode
  dealers
  updated
  updatedBy
}
    `;
export const DisclaimerFieldsFragmentDoc = gql`
    fragment DisclaimerFields on Disclaimer {
  id
  rev
  title
  type
  disclaimer
  created
  updated
  updatedBy
}
    `;
export const ImageFieldsFragmentDoc = gql`
    fragment ImageFields on Image {
  id
  rev
  type
  fileName
  name
  offerIds
  s3Key
  regionCode
  dealerCode
  updated
  updatedBy
}
    `;
export const OfferCategoryFieldsFragmentDoc = gql`
    fragment OfferCategoryFields on OfferCategory {
  id
  rev
  name
  userType
}
    `;
export const OfferMetricsFieldsFragmentDoc = gql`
    fragment OfferMetricsFields on OfferMetrics {
  totalOffers
  nationalOffers
  regionalOffers
  dealerOffers
}
    `;
export const OfferServiceSpecialsCategoryFieldsFragmentDoc = gql`
    fragment OfferServiceSpecialsCategoryFields on OfferServiceSpecialsCategory {
  id
  rev
  name
  userType
}
    `;
export const OfferTotalsFieldsFragmentDoc = gql`
    fragment OfferTotalsFields on OfferTotals {
  totalOffers
  nationalOffers
  regionalOffers
  dealerOffers
  expiredOffers
}
    `;
export const OfferFieldsFragmentDoc = gql`
    fragment OfferFields on Offer {
  id
  rev
  type
  categoryId
  serviceSpecialsCategoryId
  title
  subTitle
  regionCodes
  dealerCodes
  pricingOptionId
  amount
  couponCode
  startDate
  endDate
  thumbnailImageId
  thumbnailImage
  detailsImageId
  detailsImage
  description
  disclaimers
  consumerIds
  status
  isDeleted
}
    `;
export const PricingOptionFieldsFragmentDoc = gql`
    fragment PricingOptionFields on PricingOption {
  id
  rev
  name
}
    `;
export const ReportFieldsFragmentDoc = gql`
    fragment ReportFields on Report {
  id
  rev
  userType
  type
  kind
  name
  startDate
  endDate
  s3Key
  regionCode
  dealerCode
  createdDate
  createdBy
}
    `;
export const FetchConsumersDocument = gql`
    query fetchConsumers($first: Int) {
  consumers(first: $first) {
    ...ConsumerFields
  }
}
    ${ConsumerFieldsFragmentDoc}`;

/**
 * __useFetchConsumersQuery__
 *
 * To run a query within a React component, call `useFetchConsumersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConsumersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConsumersQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFetchConsumersQuery(baseOptions?: Apollo.QueryHookOptions<FetchConsumersQuery, FetchConsumersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchConsumersQuery, FetchConsumersQueryVariables>(FetchConsumersDocument, options);
      }
export function useFetchConsumersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchConsumersQuery, FetchConsumersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchConsumersQuery, FetchConsumersQueryVariables>(FetchConsumersDocument, options);
        }
export type FetchConsumersQueryHookResult = ReturnType<typeof useFetchConsumersQuery>;
export type FetchConsumersLazyQueryHookResult = ReturnType<typeof useFetchConsumersLazyQuery>;
export type FetchConsumersQueryResult = Apollo.QueryResult<FetchConsumersQuery, FetchConsumersQueryVariables>;
export const FetchDashboardDataDocument = gql`
    query fetchDashboardData($categoryType: String!) {
  active: offers(active: true) {
    ...DashboardOfferFields
  }
  expired: offers(active: false) {
    ...DashboardOfferFields
  }
  offerCategories(type: $categoryType) {
    ...OfferCategoryFields
  }
}
    ${DashboardOfferFieldsFragmentDoc}
${OfferCategoryFieldsFragmentDoc}`;

/**
 * __useFetchDashboardDataQuery__
 *
 * To run a query within a React component, call `useFetchDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardDataQuery({
 *   variables: {
 *      categoryType: // value for 'categoryType'
 *   },
 * });
 */
export function useFetchDashboardDataQuery(baseOptions: Apollo.QueryHookOptions<FetchDashboardDataQuery, FetchDashboardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDashboardDataQuery, FetchDashboardDataQueryVariables>(FetchDashboardDataDocument, options);
      }
export function useFetchDashboardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDashboardDataQuery, FetchDashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDashboardDataQuery, FetchDashboardDataQueryVariables>(FetchDashboardDataDocument, options);
        }
export type FetchDashboardDataQueryHookResult = ReturnType<typeof useFetchDashboardDataQuery>;
export type FetchDashboardDataLazyQueryHookResult = ReturnType<typeof useFetchDashboardDataLazyQuery>;
export type FetchDashboardDataQueryResult = Apollo.QueryResult<FetchDashboardDataQuery, FetchDashboardDataQueryVariables>;
export const CreateDealerGroupDocument = gql`
    mutation createDealerGroup($type: String!, $regionCode: String, $name: String!, $dealers: [String]!) {
  createDealerGroup(
    input: {type: $type, regionCode: $regionCode, name: $name, dealers: $dealers}
  ) {
    success
    dealerGroup {
      ...DealerGroupFields
    }
  }
}
    ${DealerGroupFieldsFragmentDoc}`;
export type CreateDealerGroupMutationFn = Apollo.MutationFunction<CreateDealerGroupMutation, CreateDealerGroupMutationVariables>;

/**
 * __useCreateDealerGroupMutation__
 *
 * To run a mutation, you first call `useCreateDealerGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealerGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealerGroupMutation, { data, loading, error }] = useCreateDealerGroupMutation({
 *   variables: {
 *      type: // value for 'type'
 *      regionCode: // value for 'regionCode'
 *      name: // value for 'name'
 *      dealers: // value for 'dealers'
 *   },
 * });
 */
export function useCreateDealerGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateDealerGroupMutation, CreateDealerGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDealerGroupMutation, CreateDealerGroupMutationVariables>(CreateDealerGroupDocument, options);
      }
export type CreateDealerGroupMutationHookResult = ReturnType<typeof useCreateDealerGroupMutation>;
export type CreateDealerGroupMutationResult = Apollo.MutationResult<CreateDealerGroupMutation>;
export type CreateDealerGroupMutationOptions = Apollo.BaseMutationOptions<CreateDealerGroupMutation, CreateDealerGroupMutationVariables>;
export const DeleteDealerGroupDocument = gql`
    mutation deleteDealerGroup($id: ID!, $rev: ID!) {
  deleteDealerGroup(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteDealerGroupMutationFn = Apollo.MutationFunction<DeleteDealerGroupMutation, DeleteDealerGroupMutationVariables>;

/**
 * __useDeleteDealerGroupMutation__
 *
 * To run a mutation, you first call `useDeleteDealerGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealerGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealerGroupMutation, { data, loading, error }] = useDeleteDealerGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteDealerGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDealerGroupMutation, DeleteDealerGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDealerGroupMutation, DeleteDealerGroupMutationVariables>(DeleteDealerGroupDocument, options);
      }
export type DeleteDealerGroupMutationHookResult = ReturnType<typeof useDeleteDealerGroupMutation>;
export type DeleteDealerGroupMutationResult = Apollo.MutationResult<DeleteDealerGroupMutation>;
export type DeleteDealerGroupMutationOptions = Apollo.BaseMutationOptions<DeleteDealerGroupMutation, DeleteDealerGroupMutationVariables>;
export const FetchDealerGroupByIdDocument = gql`
    query fetchDealerGroupById($id: String!) {
  dealerGroup(id: $id) {
    ...DealerGroupFields
  }
}
    ${DealerGroupFieldsFragmentDoc}`;

/**
 * __useFetchDealerGroupByIdQuery__
 *
 * To run a query within a React component, call `useFetchDealerGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDealerGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDealerGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchDealerGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchDealerGroupByIdQuery, FetchDealerGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDealerGroupByIdQuery, FetchDealerGroupByIdQueryVariables>(FetchDealerGroupByIdDocument, options);
      }
export function useFetchDealerGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDealerGroupByIdQuery, FetchDealerGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDealerGroupByIdQuery, FetchDealerGroupByIdQueryVariables>(FetchDealerGroupByIdDocument, options);
        }
export type FetchDealerGroupByIdQueryHookResult = ReturnType<typeof useFetchDealerGroupByIdQuery>;
export type FetchDealerGroupByIdLazyQueryHookResult = ReturnType<typeof useFetchDealerGroupByIdLazyQuery>;
export type FetchDealerGroupByIdQueryResult = Apollo.QueryResult<FetchDealerGroupByIdQuery, FetchDealerGroupByIdQueryVariables>;
export const FetchDealerGroupsDocument = gql`
    query fetchDealerGroups($type: String!, $regionCode: String) {
  dealerGroups(type: $type, regionCode: $regionCode) {
    ...DealerGroupFields
  }
}
    ${DealerGroupFieldsFragmentDoc}`;

/**
 * __useFetchDealerGroupsQuery__
 *
 * To run a query within a React component, call `useFetchDealerGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDealerGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDealerGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      regionCode: // value for 'regionCode'
 *   },
 * });
 */
export function useFetchDealerGroupsQuery(baseOptions: Apollo.QueryHookOptions<FetchDealerGroupsQuery, FetchDealerGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDealerGroupsQuery, FetchDealerGroupsQueryVariables>(FetchDealerGroupsDocument, options);
      }
export function useFetchDealerGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDealerGroupsQuery, FetchDealerGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDealerGroupsQuery, FetchDealerGroupsQueryVariables>(FetchDealerGroupsDocument, options);
        }
export type FetchDealerGroupsQueryHookResult = ReturnType<typeof useFetchDealerGroupsQuery>;
export type FetchDealerGroupsLazyQueryHookResult = ReturnType<typeof useFetchDealerGroupsLazyQuery>;
export type FetchDealerGroupsQueryResult = Apollo.QueryResult<FetchDealerGroupsQuery, FetchDealerGroupsQueryVariables>;
export const UpdateDealerGroupDocument = gql`
    mutation updateDealerGroup($id: ID!, $rev: ID!, $name: String, $dealers: [String]) {
  updateDealerGroup(input: {id: $id, rev: $rev, name: $name, dealers: $dealers}) {
    success
    dealerGroup {
      ...DealerGroupFields
    }
  }
}
    ${DealerGroupFieldsFragmentDoc}`;
export type UpdateDealerGroupMutationFn = Apollo.MutationFunction<UpdateDealerGroupMutation, UpdateDealerGroupMutationVariables>;

/**
 * __useUpdateDealerGroupMutation__
 *
 * To run a mutation, you first call `useUpdateDealerGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealerGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealerGroupMutation, { data, loading, error }] = useUpdateDealerGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      name: // value for 'name'
 *      dealers: // value for 'dealers'
 *   },
 * });
 */
export function useUpdateDealerGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDealerGroupMutation, UpdateDealerGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDealerGroupMutation, UpdateDealerGroupMutationVariables>(UpdateDealerGroupDocument, options);
      }
export type UpdateDealerGroupMutationHookResult = ReturnType<typeof useUpdateDealerGroupMutation>;
export type UpdateDealerGroupMutationResult = Apollo.MutationResult<UpdateDealerGroupMutation>;
export type UpdateDealerGroupMutationOptions = Apollo.BaseMutationOptions<UpdateDealerGroupMutation, UpdateDealerGroupMutationVariables>;
export const CreateDisclaimerDocument = gql`
    mutation createDisclaimer($title: String!, $disclaimer: String!) {
  createDisclaimer(input: {title: $title, disclaimer: $disclaimer}) {
    success
    disclaimer {
      ...DisclaimerFields
    }
  }
}
    ${DisclaimerFieldsFragmentDoc}`;
export type CreateDisclaimerMutationFn = Apollo.MutationFunction<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>;

/**
 * __useCreateDisclaimerMutation__
 *
 * To run a mutation, you first call `useCreateDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisclaimerMutation, { data, loading, error }] = useCreateDisclaimerMutation({
 *   variables: {
 *      title: // value for 'title'
 *      disclaimer: // value for 'disclaimer'
 *   },
 * });
 */
export function useCreateDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>(CreateDisclaimerDocument, options);
      }
export type CreateDisclaimerMutationHookResult = ReturnType<typeof useCreateDisclaimerMutation>;
export type CreateDisclaimerMutationResult = Apollo.MutationResult<CreateDisclaimerMutation>;
export type CreateDisclaimerMutationOptions = Apollo.BaseMutationOptions<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>;
export const DeleteDisclaimerDocument = gql`
    mutation deleteDisclaimer($id: ID!, $rev: ID!) {
  deleteDisclaimer(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteDisclaimerMutationFn = Apollo.MutationFunction<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>;

/**
 * __useDeleteDisclaimerMutation__
 *
 * To run a mutation, you first call `useDeleteDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisclaimerMutation, { data, loading, error }] = useDeleteDisclaimerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>(DeleteDisclaimerDocument, options);
      }
export type DeleteDisclaimerMutationHookResult = ReturnType<typeof useDeleteDisclaimerMutation>;
export type DeleteDisclaimerMutationResult = Apollo.MutationResult<DeleteDisclaimerMutation>;
export type DeleteDisclaimerMutationOptions = Apollo.BaseMutationOptions<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>;
export const FetchDisclaimerByIdDocument = gql`
    query fetchDisclaimerById($id: String!) {
  disclaimer(id: $id) {
    ...DisclaimerFields
  }
}
    ${DisclaimerFieldsFragmentDoc}`;

/**
 * __useFetchDisclaimerByIdQuery__
 *
 * To run a query within a React component, call `useFetchDisclaimerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDisclaimerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDisclaimerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchDisclaimerByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchDisclaimerByIdQuery, FetchDisclaimerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDisclaimerByIdQuery, FetchDisclaimerByIdQueryVariables>(FetchDisclaimerByIdDocument, options);
      }
export function useFetchDisclaimerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDisclaimerByIdQuery, FetchDisclaimerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDisclaimerByIdQuery, FetchDisclaimerByIdQueryVariables>(FetchDisclaimerByIdDocument, options);
        }
export type FetchDisclaimerByIdQueryHookResult = ReturnType<typeof useFetchDisclaimerByIdQuery>;
export type FetchDisclaimerByIdLazyQueryHookResult = ReturnType<typeof useFetchDisclaimerByIdLazyQuery>;
export type FetchDisclaimerByIdQueryResult = Apollo.QueryResult<FetchDisclaimerByIdQuery, FetchDisclaimerByIdQueryVariables>;
export const FetchDisclaimersDocument = gql`
    query fetchDisclaimers {
  disclaimers {
    ...DisclaimerFields
  }
}
    ${DisclaimerFieldsFragmentDoc}`;

/**
 * __useFetchDisclaimersQuery__
 *
 * To run a query within a React component, call `useFetchDisclaimersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDisclaimersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDisclaimersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchDisclaimersQuery(baseOptions?: Apollo.QueryHookOptions<FetchDisclaimersQuery, FetchDisclaimersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDisclaimersQuery, FetchDisclaimersQueryVariables>(FetchDisclaimersDocument, options);
      }
export function useFetchDisclaimersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDisclaimersQuery, FetchDisclaimersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDisclaimersQuery, FetchDisclaimersQueryVariables>(FetchDisclaimersDocument, options);
        }
export type FetchDisclaimersQueryHookResult = ReturnType<typeof useFetchDisclaimersQuery>;
export type FetchDisclaimersLazyQueryHookResult = ReturnType<typeof useFetchDisclaimersLazyQuery>;
export type FetchDisclaimersQueryResult = Apollo.QueryResult<FetchDisclaimersQuery, FetchDisclaimersQueryVariables>;
export const UpdateDisclaimerDocument = gql`
    mutation updateDisclaimer($id: ID!, $title: String, $disclaimer: String, $rev: ID!) {
  updateDisclaimer(
    input: {id: $id, title: $title, disclaimer: $disclaimer, rev: $rev}
  ) {
    success
    disclaimer {
      ...DisclaimerFields
    }
  }
}
    ${DisclaimerFieldsFragmentDoc}`;
export type UpdateDisclaimerMutationFn = Apollo.MutationFunction<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;

/**
 * __useUpdateDisclaimerMutation__
 *
 * To run a mutation, you first call `useUpdateDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisclaimerMutation, { data, loading, error }] = useUpdateDisclaimerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      disclaimer: // value for 'disclaimer'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useUpdateDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>(UpdateDisclaimerDocument, options);
      }
export type UpdateDisclaimerMutationHookResult = ReturnType<typeof useUpdateDisclaimerMutation>;
export type UpdateDisclaimerMutationResult = Apollo.MutationResult<UpdateDisclaimerMutation>;
export type UpdateDisclaimerMutationOptions = Apollo.BaseMutationOptions<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;
export const CreateImageDocument = gql`
    mutation createImage($type: String!, $fileName: String!, $name: String!, $offerId: String, $dealerCode: String, $regionCode: String) {
  createImage(
    input: {type: $type, fileName: $fileName, name: $name, offerId: $offerId, dealerCode: $dealerCode, regionCode: $regionCode}
  ) {
    success
    image {
      ...ImageFields
    }
  }
}
    ${ImageFieldsFragmentDoc}`;
export type CreateImageMutationFn = Apollo.MutationFunction<CreateImageMutation, CreateImageMutationVariables>;

/**
 * __useCreateImageMutation__
 *
 * To run a mutation, you first call `useCreateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageMutation, { data, loading, error }] = useCreateImageMutation({
 *   variables: {
 *      type: // value for 'type'
 *      fileName: // value for 'fileName'
 *      name: // value for 'name'
 *      offerId: // value for 'offerId'
 *      dealerCode: // value for 'dealerCode'
 *      regionCode: // value for 'regionCode'
 *   },
 * });
 */
export function useCreateImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageMutation, CreateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageMutation, CreateImageMutationVariables>(CreateImageDocument, options);
      }
export type CreateImageMutationHookResult = ReturnType<typeof useCreateImageMutation>;
export type CreateImageMutationResult = Apollo.MutationResult<CreateImageMutation>;
export type CreateImageMutationOptions = Apollo.BaseMutationOptions<CreateImageMutation, CreateImageMutationVariables>;
export const DeleteImageDocument = gql`
    mutation deleteImage($id: ID!, $rev: ID!) {
  deleteImage(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteImageMutationFn = Apollo.MutationFunction<DeleteImageMutation, DeleteImageMutationVariables>;

/**
 * __useDeleteImageMutation__
 *
 * To run a mutation, you first call `useDeleteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImageMutation, { data, loading, error }] = useDeleteImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteImageMutation, DeleteImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteImageMutation, DeleteImageMutationVariables>(DeleteImageDocument, options);
      }
export type DeleteImageMutationHookResult = ReturnType<typeof useDeleteImageMutation>;
export type DeleteImageMutationResult = Apollo.MutationResult<DeleteImageMutation>;
export type DeleteImageMutationOptions = Apollo.BaseMutationOptions<DeleteImageMutation, DeleteImageMutationVariables>;
export const FetchImageByIdDocument = gql`
    query fetchImageById($id: String!) {
  image(id: $id) {
    ...ImageFields
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useFetchImageByIdQuery__
 *
 * To run a query within a React component, call `useFetchImageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchImageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchImageByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchImageByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchImageByIdQuery, FetchImageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchImageByIdQuery, FetchImageByIdQueryVariables>(FetchImageByIdDocument, options);
      }
export function useFetchImageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchImageByIdQuery, FetchImageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchImageByIdQuery, FetchImageByIdQueryVariables>(FetchImageByIdDocument, options);
        }
export type FetchImageByIdQueryHookResult = ReturnType<typeof useFetchImageByIdQuery>;
export type FetchImageByIdLazyQueryHookResult = ReturnType<typeof useFetchImageByIdLazyQuery>;
export type FetchImageByIdQueryResult = Apollo.QueryResult<FetchImageByIdQuery, FetchImageByIdQueryVariables>;
export const FetchImagesDocument = gql`
    query fetchImages($type: String!) {
  images(type: $type) {
    ...ImageFields
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useFetchImagesQuery__
 *
 * To run a query within a React component, call `useFetchImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchImagesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useFetchImagesQuery(baseOptions: Apollo.QueryHookOptions<FetchImagesQuery, FetchImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchImagesQuery, FetchImagesQueryVariables>(FetchImagesDocument, options);
      }
export function useFetchImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchImagesQuery, FetchImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchImagesQuery, FetchImagesQueryVariables>(FetchImagesDocument, options);
        }
export type FetchImagesQueryHookResult = ReturnType<typeof useFetchImagesQuery>;
export type FetchImagesLazyQueryHookResult = ReturnType<typeof useFetchImagesLazyQuery>;
export type FetchImagesQueryResult = Apollo.QueryResult<FetchImagesQuery, FetchImagesQueryVariables>;
export const GetPresignedUrlDocument = gql`
    query getPresignedUrl($type: String!, $fileName: String!, $offerId: String, $dealerCode: String, $regionCode: String) {
  presignedUploadUrl(
    type: $type
    fileName: $fileName
    offerId: $offerId
    dealerCode: $dealerCode
    regionCode: $regionCode
  )
}
    `;

/**
 * __useGetPresignedUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedUrlQuery({
 *   variables: {
 *      type: // value for 'type'
 *      fileName: // value for 'fileName'
 *      offerId: // value for 'offerId'
 *      dealerCode: // value for 'dealerCode'
 *      regionCode: // value for 'regionCode'
 *   },
 * });
 */
export function useGetPresignedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, options);
      }
export function useGetPresignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, options);
        }
export type GetPresignedUrlQueryHookResult = ReturnType<typeof useGetPresignedUrlQuery>;
export type GetPresignedUrlLazyQueryHookResult = ReturnType<typeof useGetPresignedUrlLazyQuery>;
export type GetPresignedUrlQueryResult = Apollo.QueryResult<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>;
export const UpdateImageDocument = gql`
    mutation updateImage($id: ID!, $rev: ID!, $fileName: String, $name: String!) {
  updateImage(input: {id: $id, rev: $rev, fileName: $fileName, name: $name}) {
    success
    image {
      ...ImageFields
    }
  }
}
    ${ImageFieldsFragmentDoc}`;
export type UpdateImageMutationFn = Apollo.MutationFunction<UpdateImageMutation, UpdateImageMutationVariables>;

/**
 * __useUpdateImageMutation__
 *
 * To run a mutation, you first call `useUpdateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImageMutation, { data, loading, error }] = useUpdateImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      fileName: // value for 'fileName'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateImageMutation, UpdateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateImageMutation, UpdateImageMutationVariables>(UpdateImageDocument, options);
      }
export type UpdateImageMutationHookResult = ReturnType<typeof useUpdateImageMutation>;
export type UpdateImageMutationResult = Apollo.MutationResult<UpdateImageMutation>;
export type UpdateImageMutationOptions = Apollo.BaseMutationOptions<UpdateImageMutation, UpdateImageMutationVariables>;
export const FetchOfferCategoriesDocument = gql`
    query fetchOfferCategories($type: String!) {
  offerCategories(type: $type) {
    ...OfferCategoryFields
  }
}
    ${OfferCategoryFieldsFragmentDoc}`;

/**
 * __useFetchOfferCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchOfferCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfferCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfferCategoriesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useFetchOfferCategoriesQuery(baseOptions: Apollo.QueryHookOptions<FetchOfferCategoriesQuery, FetchOfferCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOfferCategoriesQuery, FetchOfferCategoriesQueryVariables>(FetchOfferCategoriesDocument, options);
      }
export function useFetchOfferCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOfferCategoriesQuery, FetchOfferCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOfferCategoriesQuery, FetchOfferCategoriesQueryVariables>(FetchOfferCategoriesDocument, options);
        }
export type FetchOfferCategoriesQueryHookResult = ReturnType<typeof useFetchOfferCategoriesQuery>;
export type FetchOfferCategoriesLazyQueryHookResult = ReturnType<typeof useFetchOfferCategoriesLazyQuery>;
export type FetchOfferCategoriesQueryResult = Apollo.QueryResult<FetchOfferCategoriesQuery, FetchOfferCategoriesQueryVariables>;
export const FetchOfferMetricsDocument = gql`
    query fetchOfferMetrics($startDate: String!, $endDate: String!) {
  offerMetrics(startDate: $startDate, endDate: $endDate) {
    ...OfferMetricsFields
  }
}
    ${OfferMetricsFieldsFragmentDoc}`;

/**
 * __useFetchOfferMetricsQuery__
 *
 * To run a query within a React component, call `useFetchOfferMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfferMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfferMetricsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useFetchOfferMetricsQuery(baseOptions: Apollo.QueryHookOptions<FetchOfferMetricsQuery, FetchOfferMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOfferMetricsQuery, FetchOfferMetricsQueryVariables>(FetchOfferMetricsDocument, options);
      }
export function useFetchOfferMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOfferMetricsQuery, FetchOfferMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOfferMetricsQuery, FetchOfferMetricsQueryVariables>(FetchOfferMetricsDocument, options);
        }
export type FetchOfferMetricsQueryHookResult = ReturnType<typeof useFetchOfferMetricsQuery>;
export type FetchOfferMetricsLazyQueryHookResult = ReturnType<typeof useFetchOfferMetricsLazyQuery>;
export type FetchOfferMetricsQueryResult = Apollo.QueryResult<FetchOfferMetricsQuery, FetchOfferMetricsQueryVariables>;
export const FetchOfferServiceSpecialsCategoriesDocument = gql`
    query fetchOfferServiceSpecialsCategories($type: String!) {
  offerServiceSpecialsCategories(type: $type) {
    ...OfferServiceSpecialsCategoryFields
  }
}
    ${OfferServiceSpecialsCategoryFieldsFragmentDoc}`;

/**
 * __useFetchOfferServiceSpecialsCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchOfferServiceSpecialsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfferServiceSpecialsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfferServiceSpecialsCategoriesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useFetchOfferServiceSpecialsCategoriesQuery(baseOptions: Apollo.QueryHookOptions<FetchOfferServiceSpecialsCategoriesQuery, FetchOfferServiceSpecialsCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOfferServiceSpecialsCategoriesQuery, FetchOfferServiceSpecialsCategoriesQueryVariables>(FetchOfferServiceSpecialsCategoriesDocument, options);
      }
export function useFetchOfferServiceSpecialsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOfferServiceSpecialsCategoriesQuery, FetchOfferServiceSpecialsCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOfferServiceSpecialsCategoriesQuery, FetchOfferServiceSpecialsCategoriesQueryVariables>(FetchOfferServiceSpecialsCategoriesDocument, options);
        }
export type FetchOfferServiceSpecialsCategoriesQueryHookResult = ReturnType<typeof useFetchOfferServiceSpecialsCategoriesQuery>;
export type FetchOfferServiceSpecialsCategoriesLazyQueryHookResult = ReturnType<typeof useFetchOfferServiceSpecialsCategoriesLazyQuery>;
export type FetchOfferServiceSpecialsCategoriesQueryResult = Apollo.QueryResult<FetchOfferServiceSpecialsCategoriesQuery, FetchOfferServiceSpecialsCategoriesQueryVariables>;
export const FetchOfferTotalsDocument = gql`
    query fetchOfferTotals($userType: String!) {
  offerTotals(userType: $userType) {
    ...OfferTotalsFields
  }
}
    ${OfferTotalsFieldsFragmentDoc}`;

/**
 * __useFetchOfferTotalsQuery__
 *
 * To run a query within a React component, call `useFetchOfferTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfferTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfferTotalsQuery({
 *   variables: {
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useFetchOfferTotalsQuery(baseOptions: Apollo.QueryHookOptions<FetchOfferTotalsQuery, FetchOfferTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOfferTotalsQuery, FetchOfferTotalsQueryVariables>(FetchOfferTotalsDocument, options);
      }
export function useFetchOfferTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOfferTotalsQuery, FetchOfferTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOfferTotalsQuery, FetchOfferTotalsQueryVariables>(FetchOfferTotalsDocument, options);
        }
export type FetchOfferTotalsQueryHookResult = ReturnType<typeof useFetchOfferTotalsQuery>;
export type FetchOfferTotalsLazyQueryHookResult = ReturnType<typeof useFetchOfferTotalsLazyQuery>;
export type FetchOfferTotalsQueryResult = Apollo.QueryResult<FetchOfferTotalsQuery, FetchOfferTotalsQueryVariables>;
export const CreateOfferDocument = gql`
    mutation createOffer($categoryId: ID!, $serviceSpecialsCategoryId: ID, $title: String!, $type: String!, $subTitle: String!, $regionCodes: [String]!, $dealerCodes: [String]!, $pricingOptionId: String!, $amount: String, $couponCode: String, $startDate: String!, $endDate: String!) {
  createOffer(
    input: {categoryId: $categoryId, serviceSpecialsCategoryId: $serviceSpecialsCategoryId, title: $title, type: $type, subTitle: $subTitle, regionCodes: $regionCodes, dealerCodes: $dealerCodes, pricingOptionId: $pricingOptionId, amount: $amount, couponCode: $couponCode, startDate: $startDate, endDate: $endDate}
  ) {
    success
    offer {
      ...OfferFields
    }
  }
}
    ${OfferFieldsFragmentDoc}`;
export type CreateOfferMutationFn = Apollo.MutationFunction<CreateOfferMutation, CreateOfferMutationVariables>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      serviceSpecialsCategoryId: // value for 'serviceSpecialsCategoryId'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      subTitle: // value for 'subTitle'
 *      regionCodes: // value for 'regionCodes'
 *      dealerCodes: // value for 'dealerCodes'
 *      pricingOptionId: // value for 'pricingOptionId'
 *      amount: // value for 'amount'
 *      couponCode: // value for 'couponCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateOfferMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CreateOfferDocument, options);
      }
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<CreateOfferMutation, CreateOfferMutationVariables>;
export const DeleteOfferDocument = gql`
    mutation deleteOffer($id: ID!, $rev: ID!) {
  deleteOffer(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteOfferMutationFn = Apollo.MutationFunction<DeleteOfferMutation, DeleteOfferMutationVariables>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteOfferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfferMutation, DeleteOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfferMutation, DeleteOfferMutationVariables>(DeleteOfferDocument, options);
      }
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<DeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<DeleteOfferMutation, DeleteOfferMutationVariables>;
export const DownloadUserGuideDocument = gql`
    query downloadUserGuide {
  downloadUserGuide {
    url
  }
}
    `;

/**
 * __useDownloadUserGuideQuery__
 *
 * To run a query within a React component, call `useDownloadUserGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadUserGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadUserGuideQuery({
 *   variables: {
 *   },
 * });
 */
export function useDownloadUserGuideQuery(baseOptions?: Apollo.QueryHookOptions<DownloadUserGuideQuery, DownloadUserGuideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadUserGuideQuery, DownloadUserGuideQueryVariables>(DownloadUserGuideDocument, options);
      }
export function useDownloadUserGuideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadUserGuideQuery, DownloadUserGuideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadUserGuideQuery, DownloadUserGuideQueryVariables>(DownloadUserGuideDocument, options);
        }
export type DownloadUserGuideQueryHookResult = ReturnType<typeof useDownloadUserGuideQuery>;
export type DownloadUserGuideLazyQueryHookResult = ReturnType<typeof useDownloadUserGuideLazyQuery>;
export type DownloadUserGuideQueryResult = Apollo.QueryResult<DownloadUserGuideQuery, DownloadUserGuideQueryVariables>;
export const FetchOfferByIdDocument = gql`
    query fetchOfferById($id: String!) {
  offer(id: $id) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useFetchOfferByIdQuery__
 *
 * To run a query within a React component, call `useFetchOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchOfferByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchOfferByIdQuery, FetchOfferByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOfferByIdQuery, FetchOfferByIdQueryVariables>(FetchOfferByIdDocument, options);
      }
export function useFetchOfferByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOfferByIdQuery, FetchOfferByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOfferByIdQuery, FetchOfferByIdQueryVariables>(FetchOfferByIdDocument, options);
        }
export type FetchOfferByIdQueryHookResult = ReturnType<typeof useFetchOfferByIdQuery>;
export type FetchOfferByIdLazyQueryHookResult = ReturnType<typeof useFetchOfferByIdLazyQuery>;
export type FetchOfferByIdQueryResult = Apollo.QueryResult<FetchOfferByIdQuery, FetchOfferByIdQueryVariables>;
export const FetchOffersDocument = gql`
    query fetchOffers($active: Boolean!) {
  offers(active: $active) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useFetchOffersQuery__
 *
 * To run a query within a React component, call `useFetchOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOffersQuery({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useFetchOffersQuery(baseOptions: Apollo.QueryHookOptions<FetchOffersQuery, FetchOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOffersQuery, FetchOffersQueryVariables>(FetchOffersDocument, options);
      }
export function useFetchOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOffersQuery, FetchOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOffersQuery, FetchOffersQueryVariables>(FetchOffersDocument, options);
        }
export type FetchOffersQueryHookResult = ReturnType<typeof useFetchOffersQuery>;
export type FetchOffersLazyQueryHookResult = ReturnType<typeof useFetchOffersLazyQuery>;
export type FetchOffersQueryResult = Apollo.QueryResult<FetchOffersQuery, FetchOffersQueryVariables>;
export const PublishOffersDocument = gql`
    mutation publishOffers($type: String!, $regionCode: String, $dealerCode: String) {
  publishOffers(
    input: {type: $type, regionCode: $regionCode, dealerCode: $dealerCode}
  ) {
    success
  }
}
    `;
export type PublishOffersMutationFn = Apollo.MutationFunction<PublishOffersMutation, PublishOffersMutationVariables>;

/**
 * __usePublishOffersMutation__
 *
 * To run a mutation, you first call `usePublishOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOffersMutation, { data, loading, error }] = usePublishOffersMutation({
 *   variables: {
 *      type: // value for 'type'
 *      regionCode: // value for 'regionCode'
 *      dealerCode: // value for 'dealerCode'
 *   },
 * });
 */
export function usePublishOffersMutation(baseOptions?: Apollo.MutationHookOptions<PublishOffersMutation, PublishOffersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishOffersMutation, PublishOffersMutationVariables>(PublishOffersDocument, options);
      }
export type PublishOffersMutationHookResult = ReturnType<typeof usePublishOffersMutation>;
export type PublishOffersMutationResult = Apollo.MutationResult<PublishOffersMutation>;
export type PublishOffersMutationOptions = Apollo.BaseMutationOptions<PublishOffersMutation, PublishOffersMutationVariables>;
export const UpdateOfferDocument = gql`
    mutation updateOffer($id: ID!, $rev: ID!, $categoryId: ID, $serviceSpecialsCategoryId: ID, $title: String, $subTitle: String, $regionCodes: [String], $dealerCodes: [String], $pricingOptionId: String, $amount: String, $couponCode: String, $startDate: String, $endDate: String, $thumbnailImageId: String, $detailsImageId: String, $description: String, $disclaimers: [String], $consumerIds: [String], $isDeleted: Boolean) {
  updateOffer(
    input: {id: $id, rev: $rev, categoryId: $categoryId, serviceSpecialsCategoryId: $serviceSpecialsCategoryId, title: $title, subTitle: $subTitle, regionCodes: $regionCodes, dealerCodes: $dealerCodes, pricingOptionId: $pricingOptionId, amount: $amount, couponCode: $couponCode, startDate: $startDate, endDate: $endDate, thumbnailImageId: $thumbnailImageId, detailsImageId: $detailsImageId, description: $description, disclaimers: $disclaimers, consumerIds: $consumerIds, isDeleted: $isDeleted}
  ) {
    success
    offer {
      ...OfferFields
    }
  }
}
    ${OfferFieldsFragmentDoc}`;
export type UpdateOfferMutationFn = Apollo.MutationFunction<UpdateOfferMutation, UpdateOfferMutationVariables>;

/**
 * __useUpdateOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferMutation, { data, loading, error }] = useUpdateOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      categoryId: // value for 'categoryId'
 *      serviceSpecialsCategoryId: // value for 'serviceSpecialsCategoryId'
 *      title: // value for 'title'
 *      subTitle: // value for 'subTitle'
 *      regionCodes: // value for 'regionCodes'
 *      dealerCodes: // value for 'dealerCodes'
 *      pricingOptionId: // value for 'pricingOptionId'
 *      amount: // value for 'amount'
 *      couponCode: // value for 'couponCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      thumbnailImageId: // value for 'thumbnailImageId'
 *      detailsImageId: // value for 'detailsImageId'
 *      description: // value for 'description'
 *      disclaimers: // value for 'disclaimers'
 *      consumerIds: // value for 'consumerIds'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useUpdateOfferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferMutation, UpdateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(UpdateOfferDocument, options);
      }
export type UpdateOfferMutationHookResult = ReturnType<typeof useUpdateOfferMutation>;
export type UpdateOfferMutationResult = Apollo.MutationResult<UpdateOfferMutation>;
export type UpdateOfferMutationOptions = Apollo.BaseMutationOptions<UpdateOfferMutation, UpdateOfferMutationVariables>;
export const UpdateOfferStatusDocument = gql`
    mutation updateOfferStatus($id: ID!, $rev: ID!, $status: String!) {
  updateOfferStatus(input: {id: $id, rev: $rev, status: $status}) {
    success
    offer {
      ...OfferFields
    }
  }
}
    ${OfferFieldsFragmentDoc}`;
export type UpdateOfferStatusMutationFn = Apollo.MutationFunction<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>;

/**
 * __useUpdateOfferStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOfferStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferStatusMutation, { data, loading, error }] = useUpdateOfferStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateOfferStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>(UpdateOfferStatusDocument, options);
      }
export type UpdateOfferStatusMutationHookResult = ReturnType<typeof useUpdateOfferStatusMutation>;
export type UpdateOfferStatusMutationResult = Apollo.MutationResult<UpdateOfferStatusMutation>;
export type UpdateOfferStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>;
export const FetchPricingOptionsDocument = gql`
    query fetchPricingOptions($first: Int) {
  pricingOptions(first: $first) {
    ...PricingOptionFields
  }
}
    ${PricingOptionFieldsFragmentDoc}`;

/**
 * __useFetchPricingOptionsQuery__
 *
 * To run a query within a React component, call `useFetchPricingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPricingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPricingOptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFetchPricingOptionsQuery(baseOptions?: Apollo.QueryHookOptions<FetchPricingOptionsQuery, FetchPricingOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPricingOptionsQuery, FetchPricingOptionsQueryVariables>(FetchPricingOptionsDocument, options);
      }
export function useFetchPricingOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPricingOptionsQuery, FetchPricingOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPricingOptionsQuery, FetchPricingOptionsQueryVariables>(FetchPricingOptionsDocument, options);
        }
export type FetchPricingOptionsQueryHookResult = ReturnType<typeof useFetchPricingOptionsQuery>;
export type FetchPricingOptionsLazyQueryHookResult = ReturnType<typeof useFetchPricingOptionsLazyQuery>;
export type FetchPricingOptionsQueryResult = Apollo.QueryResult<FetchPricingOptionsQuery, FetchPricingOptionsQueryVariables>;
export const CreateReportDocument = gql`
    mutation createReport($userType: String!, $type: String!, $kind: String!, $startDate: String!, $endDate: String!, $regionCode: String) {
  createReport(
    input: {userType: $userType, type: $type, kind: $kind, startDate: $startDate, endDate: $endDate, regionCode: $regionCode}
  ) {
    success
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      userType: // value for 'userType'
 *      type: // value for 'type'
 *      kind: // value for 'kind'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      regionCode: // value for 'regionCode'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const FetchReportsDocument = gql`
    query fetchReports($userType: String!, $regionCode: String, $dealerCode: String) {
  reports(userType: $userType, regionCode: $regionCode, dealerCode: $dealerCode) {
    ...ReportFields
  }
}
    ${ReportFieldsFragmentDoc}`;

/**
 * __useFetchReportsQuery__
 *
 * To run a query within a React component, call `useFetchReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReportsQuery({
 *   variables: {
 *      userType: // value for 'userType'
 *      regionCode: // value for 'regionCode'
 *      dealerCode: // value for 'dealerCode'
 *   },
 * });
 */
export function useFetchReportsQuery(baseOptions: Apollo.QueryHookOptions<FetchReportsQuery, FetchReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchReportsQuery, FetchReportsQueryVariables>(FetchReportsDocument, options);
      }
export function useFetchReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchReportsQuery, FetchReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchReportsQuery, FetchReportsQueryVariables>(FetchReportsDocument, options);
        }
export type FetchReportsQueryHookResult = ReturnType<typeof useFetchReportsQuery>;
export type FetchReportsLazyQueryHookResult = ReturnType<typeof useFetchReportsLazyQuery>;
export type FetchReportsQueryResult = Apollo.QueryResult<FetchReportsQuery, FetchReportsQueryVariables>;
export const GetPresignedReportDownloadUrlDocument = gql`
    query getPresignedReportDownloadUrl($userType: String!, $reportId: String!) {
  presignedReportDownloadUrl(userType: $userType, reportId: $reportId)
}
    `;

/**
 * __useGetPresignedReportDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedReportDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedReportDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedReportDownloadUrlQuery({
 *   variables: {
 *      userType: // value for 'userType'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetPresignedReportDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPresignedReportDownloadUrlQuery, GetPresignedReportDownloadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresignedReportDownloadUrlQuery, GetPresignedReportDownloadUrlQueryVariables>(GetPresignedReportDownloadUrlDocument, options);
      }
export function useGetPresignedReportDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresignedReportDownloadUrlQuery, GetPresignedReportDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresignedReportDownloadUrlQuery, GetPresignedReportDownloadUrlQueryVariables>(GetPresignedReportDownloadUrlDocument, options);
        }
export type GetPresignedReportDownloadUrlQueryHookResult = ReturnType<typeof useGetPresignedReportDownloadUrlQuery>;
export type GetPresignedReportDownloadUrlLazyQueryHookResult = ReturnType<typeof useGetPresignedReportDownloadUrlLazyQuery>;
export type GetPresignedReportDownloadUrlQueryResult = Apollo.QueryResult<GetPresignedReportDownloadUrlQuery, GetPresignedReportDownloadUrlQueryVariables>;