const tabs = [
  {
    tab: 'info',
    text: 'Offer Information',
  },
  {
    tab: 'images',
    text: 'Images',
  },
  {
    tab: 'marketing',
    text: 'Marketing',
  },
];

export default tabs;
