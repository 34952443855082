import { Maybe } from 'graphql/jsutils/Maybe';
import { OfferCategory } from '../../../gql/generated';
import getStringValue from '../../../utils/tools/getStringValue';

const transformOfferCategeories = (offerCategories: Maybe<Array<Maybe<OfferCategory>>> | undefined) => {
  const offerCats: OfferCategory[] | undefined = offerCategories?.map(cat => ({
    id: getStringValue(cat?.id),
    rev: getStringValue(cat?.rev),
    name: getStringValue(cat?.name),
    userType: cat?.userType || [],
  }));
  return offerCats || [];
};

export default transformOfferCategeories;
