import React, { useState } from 'react';
import cx from 'clsx';
import Button from '../../Button';
import ModalBody from '../../Modal/ModalBody';
import ModalFooter from '../../Modal/ModalFooter';
import ModalHeader from '../../Modal/ModalHeader';
import ValidationText from '../../ValidationText';
import { DealerGroupsType, DealerGroupType } from '../../../../models/dealerGroup/DealerGroup';
import { useFetchDealerGroupsQuery } from '../../../../gql/generated';
import withUser, { WithUserProps } from '../../../../hoc/withUser';
import styles from './styles.module.scss';

interface DealerGroupModalProps extends WithUserProps {
  onClose: () => void;
  onSelect: (currentDealerGroup: DealerGroupType) => void;
}

const DealerGroupModal = ({ onClose, onSelect, user, userType }: DealerGroupModalProps) => {
  const { data, loading, error } = useFetchDealerGroupsQuery({ variables: { type: userType?.type || '', regionCode: user?.profile.tmscorporateregioncode } });
  const [currentDealerGroup, setCurrentDealerGroup] = useState<DealerGroupType | undefined>(undefined);
  const [submited, setSubmited] = useState<boolean>(false);

  const handleSelectDealerGroup = (selectedDealerGroup: DealerGroupType) => {
    if (selectedDealerGroup?.id === currentDealerGroup?.id) {
      setCurrentDealerGroup(undefined);
    } else {
      setCurrentDealerGroup(selectedDealerGroup);
    }
  };

  const handleSaveDealerGroup = () => {
    setSubmited(true);

    if (currentDealerGroup) {
      onSelect(currentDealerGroup);
      onClose();
    }
  };
  const dealerGroups: DealerGroupsType = data?.dealerGroups;

  return (
    <>
      <ModalHeader title="Dealer Groups" onClose={onClose} />
      <ModalBody fixHeightLarge>
        {loading && <p>Loading...</p>}
        {error && <p>Service Error. Please contact your administrator.</p>}
        {!loading && !error && (
          <>
            <div className={styles.dealerGroupList}>
              <ul>
                {dealerGroups?.map(dealerGroup => {
                  const selected = dealerGroup?.id === currentDealerGroup?.id;
                  return (
                    <li className={cx(selected && styles.dealerGroupSelect)} role="presentation" key={dealerGroup?.id} onClick={() => handleSelectDealerGroup(dealerGroup)}>
                      {dealerGroup?.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            {!currentDealerGroup && submited && <ValidationText>Please select dealer group</ValidationText>}
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <Button id="del-modal-btn" variant="primary" onClick={handleSaveDealerGroup}>
          Select
        </Button>
      </ModalFooter>
    </>
  );
};

export default withUser(DealerGroupModal);
