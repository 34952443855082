import React, { useEffect } from 'react';
import useUserManager from '../../hooks/useUserManager';
import Wrapper from '../layout/Wrapper';

const SignInRedirect: React.FC = () => {
  const userMgr = useUserManager;

  useEffect(() => {
    userMgr.signinRedirect();
  }, []);

  return (
    <Wrapper>
      <h1>Redirecting...</h1>
    </Wrapper>
  );
};

export default SignInRedirect;
