import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import ButtonGroup from '../../../../../components/common/ButtonGroup';
import Input from '../../../../../components/common/Input';
import LabelContainer from '../../../../../components/common/LabelContainer';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import Textarea from '../../../../../components/common/Textarea';
import ValidationText from '../../../../../components/common/ValidationText';
import { Disclaimer, Scalars } from '../../../../../gql/generated';
import getStringValue from '../../../../../utils/tools/getStringValue';
import styles from './styles.module.scss';

interface DisclaimerModalProps {
  disclaimer?: Disclaimer | undefined;
  onClose: () => void;
  onCreate?: (title: string, disclaimer: string) => void;
  onUpdate?: (disclaimerID: string, rev: string, title: string, disclaimerText: string) => void;
}

const DisclaimerModal = ({ disclaimer, onCreate, onClose, onUpdate }: DisclaimerModalProps) => {
  const [title, setTitle] = useState(getStringValue(disclaimer?.title));
  const [disclaimerText, setDisclaimerText] = useState(getStringValue(disclaimer?.disclaimer));
  const [submitted, setSubmitted] = useState(false);

  const createDisclaimerHandler = async () => {
    setSubmitted(true);

    if (onCreate && !disclaimer && title && disclaimerText) {
      onCreate(title, disclaimerText);
      onClose();
    }

    if (onUpdate && disclaimer && disclaimer?.id && title && disclaimerText) {
      const disclaimerRev: Scalars['ID'] = disclaimer.rev;
      onUpdate(disclaimer?.id, disclaimerRev, title, disclaimerText);
      onClose();
    }
  };

  return (
    <div className={styles.disclaimerModal}>
      <ModalHeader title={`${!disclaimer ? 'Add' : 'Edit'} Disclaimer`} onClose={onClose} />
      <ModalBody>
        <div className={styles.nameEnabledContainer}>
          <LabelContainer vertical label="Disclaimer Name">
            <Input autoFocus defaultValue={title} error={submitted && !title} id="input-name" onBlur={e => setTitle(e.currentTarget.value)} />
            {submitted && !title && <ValidationText>Title is required</ValidationText>}
          </LabelContainer>
        </div>
        <div className={styles.disclaimerTextContainer}>
          <LabelContainer vertical label="Disclaimer">
            <Textarea className={styles.textarea} defaultValue={disclaimerText} onChange={e => setDisclaimerText(e.currentTarget.value)} />
            {!disclaimerText && submitted && <ValidationText>Disclaimer is required</ValidationText>}
          </LabelContainer>
        </div>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-close-btn" onClick={() => onClose()} variant="primary">
            Close
          </Button>
          <Button id="modal-save-btn" onClick={() => createDisclaimerHandler()} variant="primary">
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div>
  );
};

export default DisclaimerModal;
