import React from 'react';
import styles from './styles.module.scss';

interface PageHeaderProps {
  children?: React.ReactNode;
}

const PageHeader = ({ children }: PageHeaderProps) => {
  return <section className={styles.pageHeader}>{children}</section>;
};

export default PageHeader;
