import cx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface CheckboxProps {
  id?: string;
  checked?: boolean;
  label?: string;
  labelCss?: string;
  variant?: 'default' | 'solid';
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

const Checkbox = ({ id, checked, label, labelCss, variant = 'default', disabled, onChange = () => {} }: CheckboxProps) => {
  const htmlFor = `${id}-chbox-input`;

  const getCss = () => {
    return cx(styles.checkbox, variant === 'default' && styles.default, variant === 'solid' && styles.solid);
  };

  return (
    <label id={id} htmlFor={htmlFor} className={getCss()}>
      <input id={htmlFor} type="checkbox" checked={checked} onChange={e => onChange(e.currentTarget.checked)} disabled={disabled} />
      <span className={styles.control} />
      {label && <span className={cx(styles.label, labelCss)}>{label}</span>}
    </label>
  );
};

export default Checkbox;
