import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/common/Button';
import ButtonGroup from '../../../../../components/common/ButtonGroup';
import CustomDatePicker from '../../../../../components/common/CustomDatePicker';
import CustomSelect from '../../../../../components/common/CustomSelect';
import DealersComponent from '../../../../../components/common/Dealers/DealersComponent';
import FlexBox from '../../../../../components/common/FlexBox';
import Input from '../../../../../components/common/Input';
import InputContainer from '../../../../../components/common/InputContainer';
import LabelContainer from '../../../../../components/common/LabelContainer';
import ValidationText from '../../../../../components/common/ValidationText';
import UserType from '../../../../../models/user/UserType';
import areDatesValid from '../../../../../utils/validation/areDatesValid';
import Modal from '../../../../../components/common/Modal';
import PreviewOfferModal from '../../../marketing/components/PreviewOfferModal';
import useInformationComponent, { UseInformationProps } from './useInformationComponent';

type InformationProps = {
  readOnly?: boolean;
  readOnlyDealers?: boolean;
} & UseInformationProps;

const InformationComponent = ({
  categories,
  dealers,
  dealersLoading,
  offer,
  onSave,
  pricingOptions,
  readOnly,
  readOnlyDealers,
  serviceSpecialsCategories,
  userType,
}: InformationProps) => {
  const {
    amount,
    amountEnable,
    amountRef,
    categoryId,
    categoryList,
    couponCode,
    couponCodeError,
    dateValidation,
    dealerOptionsList,
    dealerSelectMap,
    endDate,
    handleDealerSelect,
    handleOnSave,
    handlePricingOptionSelect,
    isAmountValid,
    isSubtitleExceeded,
    isTitleExceeded,
    pricingAmountError,
    pricingOptionId,
    pricingOptionsList,
    serviceSpecialsCategoryId,
    serviceSpecialsCategoryList,
    setAmount,
    setCategoryId,
    setCouponCode,
    setDateValidation,
    setDoSave,
    setEndDate,
    setServiceSpecialsCategoryId,
    setStartDate,
    setSubtitle,
    setTitle,
    startDate,
    subTitle,
    submitted,
    title,
  } = useInformationComponent({
    categories,
    dealers,
    dealersLoading,
    offer,
    onSave,
    pricingOptions,
    serviceSpecialsCategories,
    userType,
  });
  const [openModal, setOpenModal] = useState(false);

  useEffect(()=>  {
    if(title || categoryId || serviceSpecialsCategoryId || subTitle || couponCode) {
      handleOnSave(false)
    }

  }, [title, categoryId, pricingOptionId, serviceSpecialsCategoryId, subTitle, amount, couponCode, startDate, endDate, dealerSelectMap])
  
  let timeout: any;

  const setTimeoutOnInput = (e: any, setter: any) => {
    clearTimeout(timeout);
    const changedValue = e.currentTarget.value
    timeout = setTimeout(async () => {
      setter(changedValue);
      setDoSave(true);
    }, 1500);
  }

  return (
    <>
      <FlexBox>
        <LabelContainer label="Category">
          <CustomSelect
            id="category-dd"
            value={categoryId}
            options={categoryList}
            onChange={({ value }) => {
              setDoSave(true);
              setCategoryId(value);
            }}
            error={submitted && !categoryId}
            disabled={readOnly}
            isSearchable={false}
          />
        </LabelContainer>
        <LabelContainer label="Service Specials Category" serviceSpecialsCategory>
          <CustomSelect
            id="serviceSpecialsCategories-dd"
            value={serviceSpecialsCategoryId}
            options={serviceSpecialsCategoryList}
            onChange={({ value }) => {
              setDoSave(true);
              setServiceSpecialsCategoryId(value);
            }}
            disabled={readOnly}
            isSearchable={false}
          />
        </LabelContainer>
      </FlexBox>

      <FlexBox>
        <LabelContainer label="Offer Title">
          <Input
            id="offer-title-input"
            defaultValue={title}
            onBlur={e => {
              setDoSave(true);
              setTitle(e.currentTarget.value);
            }}
            error={submitted && (!title || isTitleExceeded)}
            disabled={readOnly}
            onChange={(e)=> { 
              setTimeoutOnInput(e, setTitle)
            }}
          />
        </LabelContainer>
        <LabelContainer label="Offer Sub Title">
          <Input
            id="offer-sub-title-input"
            defaultValue={subTitle}
            onBlur={e => {
              setDoSave(true);
              setSubtitle(e.currentTarget.value);
            }}
            error={submitted && (!subTitle || isSubtitleExceeded)}
            disabled={readOnly}
            onChange={(e) => setTimeoutOnInput(e, setSubtitle)}
          />
        </LabelContainer>
        {(isTitleExceeded || isSubtitleExceeded) && submitted && <ValidationText>Offer Title or Sub Title exceeds 50 Characters</ValidationText>}
      </FlexBox>

      <DealersComponent
        dealers={dealers}
        initialDealerCodes={offer?.dealerCodes}
        dealersLoading={dealersLoading}
        dealerOptionsList={dealerOptionsList}
        submitted={submitted}
        readOnly={readOnlyDealers}
        onDealerSelect={handleDealerSelect}
        showDealerGroupButton
      />

      <FlexBox>
        <LabelContainer label="Pricing Option">
          <CustomSelect
            id="pricing-option-dd"
            value={pricingOptionId}
            options={pricingOptionsList}
            onChange={handlePricingOptionSelect}
            error={submitted && !pricingOptionId && userType?.type !== UserType.NATIONAL}
            disabled={readOnly}
            isSearchable={false}
            isClearable={userType?.type === UserType.NATIONAL}
          />
        </LabelContainer>
        <LabelContainer label="Amount">
          <Input
            id="amount-input"
            ref={amountRef}
            defaultValue={amount}
            onBlur={e => {
              setDoSave(true);
              setAmount(e.currentTarget.value);
            }}
            error={submitted && !isAmountValid}
            disabled={readOnly || !amountEnable}
            onChange={(e) => setTimeoutOnInput(e, setAmount)}
            
          />
        </LabelContainer>
        {submitted && !isAmountValid && <ValidationText>{pricingAmountError}</ValidationText>}
      </FlexBox>

      <FlexBox>
        <LabelContainer label="Coupon Code">
          <Input
            id="coupon-code-input"
            defaultValue={couponCode}
            onBlur={e => {
              setDoSave(true);
              setCouponCode(e.currentTarget.value);
            }}
            error={!!couponCodeError}
            disabled={readOnly}
            onChange={(e) => setTimeoutOnInput(e, setCouponCode)} 
          />
        </LabelContainer>

        {submitted && couponCodeError && <ValidationText>{couponCodeError}</ValidationText>}
      </FlexBox>

      <FlexBox>
        <InputContainer label="Start Date">
          <CustomDatePicker
            id="start-date-picker"
            selected={startDate}
            error={submitted && !startDate && dateValidation}
            onChange={date => {
              if (date) {
                setDoSave(true);
                setStartDate(date);
                setDateValidation(areDatesValid(date, endDate));
              }
            }}
            disabled={readOnly}
          />
        </InputContainer>
        <InputContainer label="End Date">
          <CustomDatePicker
            id="end-date-picker"
            selected={endDate}
            error={submitted && !endDate && dateValidation}
            onChange={date => {
              if (date) {
                setDoSave(true);
                setEndDate(date);
                setDateValidation(areDatesValid(startDate, date));
              }
            }}
            disabled={readOnly}
          />
        </InputContainer>
      </FlexBox>

      {!dateValidation && (
        <FlexBox>
          <ValidationText>Start date cannot be after end date</ValidationText>
        </FlexBox>
      )}

      {(!readOnly || !readOnlyDealers) && (
        <FlexBox justifyContent="flex-end">
          <ButtonGroup>
            <Button id="save-btn" variant="primary" onClick={handleOnSave}>
              Save
            </Button>
            {offer?.id && (
              <Button id="preview-btn" variant="primary" onClick={() => setOpenModal(true)}>
                Preview
              </Button>
            )}
            <Button id="next-btn" variant="primary" onClick={() => handleOnSave(true)}>
              Next
            </Button>
          </ButtonGroup>
        </FlexBox>
      )}

      {offer?.id && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="lg">
          <PreviewOfferModal onClose={() => setOpenModal(false)} offer={offer} />
        </Modal>
      )}
    </>
  );
};

export default InformationComponent;
