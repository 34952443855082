import { ApolloError } from '@apollo/client';
import React, { ReactNode } from 'react';
import useUserManager from '../../hooks/useUserManager';
import PageMessage from '../common/PageMessage';
import Wrapper from '../layout/Wrapper';

interface DataLoadingControllerProps {
  component: ReactNode;
  loadingComponent?: ReactNode;
  isLoaded: boolean;
  apolloErrors?: (ApolloError | undefined)[];
  error?: boolean; // use for other errors like 3rd services
}

const DataLoadingController = ({ component, loadingComponent, isLoaded, error, apolloErrors }: DataLoadingControllerProps) => {
  const userMgr = useUserManager;

  if (!isLoaded) {
    return loadingComponent ? <>{loadingComponent}</> : <PageMessage message="Loading..." />;
  }

  let hasError = error;

  if (apolloErrors) {
    let is401 = false;
    apolloErrors.forEach(err => {
      if (err) {
        hasError = true;
        const networkError = err.networkError as any;
        if (networkError && networkError.statusCode === 401) {
          is401 = true;
        }
      }
    });

    if (is401) {
      userMgr.signinRedirect();
      return (
        <Wrapper>
          <h1>Redirecting...</h1>
        </Wrapper>
      );
    }
  }

  if (hasError) {
    return <PageMessage message="Service Error. Please contact your administrator." />;
  }

  return <>{component}</>;
};

export default DataLoadingController;
