import { faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Button from '../../../../../components/common/Button';
import DateFormat from '../../../../../components/common/DateFormat';
import Modal from '../../../../../components/common/Modal';
import ModalBody from '../../../../../components/common/Modal/ModalBody';
import ModalFooter from '../../../../../components/common/Modal/ModalFooter';
import ModalHeader from '../../../../../components/common/Modal/ModalHeader';
import { Image } from '../../../../../gql/generated';
import getS3ImageUrl from '../../../../offer/images/utils/getS3ImageUrl';
import ImageModal from '../ImageModal';
import SettingsTable from '../../../components/SettingsTable';
import styles from './styles.module.scss';

interface ImageLibraryComponentProps {
  images: Image[];
  onDelete: (image: Image) => void;
  onAdd: (name: string, file: File) => void;
  onUpdate: (name: string, image: Image) => void;
}

const ImageLibraryComponent = ({ images, onDelete, onAdd, onUpdate }: ImageLibraryComponentProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteModalImage, setDeleteModalImage] = useState<Image | null>(null);
  const [editModalImage, setEditModalImage] = useState<Image | null>(null);

  const handleOnDelete = (image: Image) => {
    onDelete(image);
    setDeleteModalImage(null);
  };

  const isEnabled = (image: Image | null) => {
    return image?.offerIds ? image.offerIds?.length > 0 : false;
  };

  return (
    <div className={styles.image}>
      <SettingsTable>
        <thead>
          <tr>
            <td>Image Name</td>
            <td>Image</td>
            <td>Status</td>
            <td>Modified By</td>
            <td>Modified Date</td>
            <td aria-label="empty-td" />
          </tr>
        </thead>

        <tbody>
          {images.map(item => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>
                <div className={styles.imageContainer}>
                  <img alt={item.fileName} src={getS3ImageUrl(item.s3Key)} />
                </div>
              </td>
              <td>{isEnabled(item) ? 'Enabled' : ''}</td>
              <td>{item.updatedBy}</td>
              <td>
                <DateFormat format="MM/DD/YYYY">{item.updated}</DateFormat>
              </td>
              <td>
                <button id={`edit-btn-${item.id}`} type="button" className={styles.tableBtn}>
                  <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditModalImage(item)} />
                </button>
                <button id={`delete-btn-${item.id}`} type="button" className={styles.tableBtn} onClick={() => setDeleteModalImage(item)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </SettingsTable>
      <button id="new-image-btn" type="button" className={styles.addBtn} onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={faPlusCircle} className={styles.addBtnIcon} /> Add New Image
      </button>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <ImageModal onClose={() => setOpenModal(false)} onAdd={onAdd} />
      </Modal>
      <Modal isOpen={editModalImage !== null} onClose={() => setEditModalImage(null)}>
        <ImageModal image={editModalImage} onClose={() => setEditModalImage(null)} onUpdate={onUpdate} />
      </Modal>
      <Modal isOpen={deleteModalImage !== null} onClose={() => setDeleteModalImage(null)}>
        <ModalHeader title="Delete Image" onClose={() => setDeleteModalImage(null)} />
        <ModalBody>
          {isEnabled(deleteModalImage) ? (
            <>Deleting this image will require all offers using this image to select a new image if republished</>
          ) : (
            <>
              Are you sure you want to delete <b>{deleteModalImage?.name}?</b>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button id="del-modal-btn" variant="primary" onClick={() => handleOnDelete(deleteModalImage as Image)}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ImageLibraryComponent;
