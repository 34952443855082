import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';

interface CustomDatePickerProps {
  id?: string;
  selected?: Date;
  error?: boolean;
  disabled?: boolean;
  onChange: (date?: Date) => void;
}

const CustomDatePicker = ({ id, selected, error, disabled, onChange }: CustomDatePickerProps) => {
  return (
    <div className={cx(styles.customDatePicker, error && styles.error)}>
      <DatePicker
        id={id}
        selected={selected}
        onChange={date => {
          onChange(date as Date);
        }}
        dateFormat="yyyy-MM-dd"
        disabled={disabled}
      />
      <FontAwesomeIcon className={styles.icon} icon={faCalendarAlt} />
    </div>
  );
};

export default CustomDatePicker;
