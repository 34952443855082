import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ModalFooterProps {
  children?: ReactNode;
}

const ModalFooter = ({ children }: ModalFooterProps) => {
  return <div className={styles.modalFooter}>{children}</div>;
};

export default ModalFooter;
