import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface DealerGroupTableProps {
  children?: ReactNode;
  className?: string;
  validationMessage?: ReactNode;
}

const DealerGroupTable = ({ className, children, validationMessage }: DealerGroupTableProps) => {
  return (
    <div className={styles.container}>
      <table className={cx(className, styles.dealerGroupTable)}>{children}</table>
      {validationMessage}
    </div>
  );
};

export default DealerGroupTable;
