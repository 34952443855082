import dayjs from 'dayjs';
import React, { useState } from 'react';
import CustomDatePicker from '../../../../components/common/CustomDatePicker';
import CustomSelect from '../../../../components/common/CustomSelect';
import FlexBox from '../../../../components/common/FlexBox';
import InputContainer from '../../../../components/common/InputContainer';
import SectionHeader from '../../../../components/common/SectionHeader';
import Wrapper from '../../../../components/layout/Wrapper';
import Constants from '../../../../constants/Constants';
import REGIONS from '../../../../constants/Regions';
import { Report } from '../../../../gql/generated';
import UserType from '../../../../models/user/UserType';
import ReportButton from '../ReportButton';
import styles from './styles.module.scss';

const typeOptions = [
  {
    value: 'National',
    label: 'National Reports',
  },
  {
    value: 'Regional',
    label: 'Regional Reports',
  },
];

const regionOptions = [
  ...Object.values(REGIONS).map(item => ({
    value: item.regionCode,
    label: item.regionCode,
  })),
];

const generateFileName = (report: Report) => {
  return report?.name.replace('.xlsx', '') || '';
};

interface ReportsComponentProps {
  reports: Report[];
  isNational: boolean;
  onGenerateReport?: (type: string, kind: string, startDate: string, endDate: string, regionCode?: string) => void;
  downloadReport?: (reportId: string, name: string) => void;
}

const ReportsComponent = ({ reports, isNational, onGenerateReport, downloadReport }: ReportsComponentProps) => {
  const [typeOption, setTypeOption] = useState(isNational ? typeOptions[0] : typeOptions[1]);
  const [regionOption, setRegionOption] = useState(regionOptions[0]);
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());

  const handleGenerateReport = (kind: string) => {
    if (onGenerateReport) {
      const sDate = dayjs(startDate).format('YYYY-MM-DD');
      const eDate = dayjs(endDate).format('YYYY-MM-DD');

      const typeCode = typeOption.value === Constants.ReportTypes.NATIONAL ? 'NAT' : regionOption.label;
      onGenerateReport(typeOption.value, kind, sDate, eDate, typeCode);
    }
  };

  const handleDownloadReport = (reportId: string, name: string) => {
    if (downloadReport) {
      downloadReport(reportId, name);
    }
  };

  const typeOptionsProcessed = typeOptions.filter(item => {
    return isNational || item.value !== UserType.NATIONAL;
  });

  return (
    <Wrapper>
      <section className={styles.selectSection}>
        <FlexBox>
          <InputContainer>
            <CustomSelect
              id="typeOptions"
              value={typeOption.value}
              className={styles.select}
              options={typeOptionsProcessed}
              onChange={option => setTypeOption(option)}
              isSearchable={false}
            />
          </InputContainer>
          {typeOption.value === Constants.ReportTypes.NATIONAL && (
            <InputContainer>
              <CustomSelect id="nationalOptions" value="all" className={styles.select} options={[{ value: 'all', label: 'ALL REGIONS' }]} isSearchable={false} />
            </InputContainer>
          )}
          {typeOption.value === Constants.ReportTypes.REGIONAL && (
            <InputContainer>
              <CustomSelect
                id="regionOptions"
                value={regionOption.value}
                className={styles.select}
                options={regionOptions}
                onChange={option => setRegionOption(option)}
                isSearchable={false}
              />
            </InputContainer>
          )}
        </FlexBox>

        <FlexBox>
          <InputContainer label="Start Date">
            <CustomDatePicker
              id="start-date-picker"
              selected={startDate}
              onChange={date => {
                if (date) {
                  setStartDate(date);
                }
              }}
            />
          </InputContainer>
          <InputContainer label="End Date">
            <CustomDatePicker
              id="end-date-picker"
              selected={endDate}
              onChange={date => {
                if (date) {
                  setEndDate(date);
                }
              }}
            />
          </InputContainer>
        </FlexBox>
      </section>

      {/* Report buttons */}
      <section>
        <SectionHeader className={styles.sectionHeader}>Reports</SectionHeader>
        <div className={styles.sectionContent}>
          <ReportButton
            id="allActiveBtn"
            onClick={() => {
              handleGenerateReport(Constants.ReportKinds.ALL_ACTIVE_OFFERS);
            }}
          >
            All Active Offers Report
          </ReportButton>
        </div>
      </section>

      {/* Generated Reports */}
      <section>
        <SectionHeader className={styles.sectionHeader}>Generated Reports</SectionHeader>
        <div className={styles.sectionContent}>
          {reports.map(report => (
            <ReportButton key={report.id} id={`${report.kind}-${report.id}`} onClick={() => handleDownloadReport(report.id, `${generateFileName(report)}.xlsx`)}>
              {generateFileName(report)}
            </ReportButton>
          ))}
        </div>
      </section>
    </Wrapper>
  );
};

export default ReportsComponent;
