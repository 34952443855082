import React from 'react';
import { NavLink } from 'react-router-dom';
import PageTitle from '../../../../components/common/PageTitle';
import OfferTab from '../OfferTab';
import OfferTabs from '../OfferTabs';

interface OfferHeadingProps {
  title: string;
  baseUrl: string;
  tabs: { tab: string; text: string }[];
  selectedTab: 'marketing' | 'info' | 'images';
  offerId?: string;
  disableTabs?: boolean;
}

const OfferHeading = ({ title, baseUrl, tabs, selectedTab, offerId, disableTabs }: OfferHeadingProps) => {
  const currentOfferId = offerId ? `/${offerId}` : '';

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <OfferTabs id="offer-tabs">
        {tabs.map(({ tab, text }) => (
          <OfferTab selected={tab === selectedTab} key={tab}>
            {disableTabs || tab === selectedTab ? text : <NavLink to={`${baseUrl}/${tab}${currentOfferId}`}>{text}</NavLink>}
          </OfferTab>
        ))}
      </OfferTabs>
    </>
  );
};

export default OfferHeading;
