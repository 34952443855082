import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface DealersTableProps {
  className?: string;
  children?: ReactNode;
  validationMessage?: ReactNode;
}

const DealersTable = ({ className, children, validationMessage }: DealersTableProps) => {
  return (
    <div className={styles.container}>
      <table className={cx(className, styles.dealersTable)}>{children}</table>
      {validationMessage}
    </div>
  );
};

export default DealersTable;
