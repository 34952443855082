import Axios from 'axios';
import DealerResponse from '../../models/dis/DealersResponse';

const getAllDealers = () => {
  const params = {
    brandId: '1',
    searchMode: 'proximityOnly',
    resultsFormat: 'json',
    includePuertoRico: 'false',
  };

  const headers = {
    'x-api-key': process.env.REACT_APP_DIS_API_KEY,
  };

  const url = `${process.env.REACT_APP_DIS_API_URL}/disrest/getAllDealers`;
  return Axios.get<DealerResponse>(url, { headers, params });
};

export default getAllDealers;
