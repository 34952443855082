import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface DealersSearchProps {
  children?: ReactNode;
}

const DealersSearch = ({ children }: DealersSearchProps) => {
  return (
    <div className={styles.dealersSearch}>
      <span className={styles.label}>Select Dealers</span>
      {children}
    </div>
  );
};

export default DealersSearch;
