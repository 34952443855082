const getOfferText = (pricingOptionId: string, ammount: string) => {
  // Dollar amount/discount
  if (pricingOptionId === '2' || pricingOptionId === '1') {
    return `$${ammount}`;
  }

  // Percentage Ammount
  if (pricingOptionId === '3') {
    return `${ammount}% off`;
  }

  return ammount;
};

export default getOfferText;
