import React, { useEffect, useState } from 'react';
import BlockUI from '../../BlockUI';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import CustomSelect from '../../CustomSelect';
import DealersSearch from '../DealersSearch';
import DealersTable from '../DealersTable';
import FlexBox from '../../FlexBox';
import Input from '../../Input';
import Modal from '../../Modal';
import SelectDealerGroupModal from '../SelectDealerGroupModal';
import SelectOption from '../../CustomSelect/SelectOption';
import UserType from '../../../../models/user/UserType';
import ValidationText from '../../ValidationText';
import stringArrayToMap from '../../../../utils/tools/stringArrayToMap';
import withUser, { WithUserProps } from '../../../../hoc/withUser';
import { DealerGroupType, DealersInGroupType } from '../../../../models/dealerGroup/DealerGroup';
import { IDealer } from '../../../../models/dealers/IDealer';
import styles from './styles.module.scss';

interface DealersSectionProps extends WithUserProps {
  dealerOptionsList: SelectOption[];
  dealers: IDealer[];
  dealersLoading: boolean;
  initialDealerCodes?: DealersInGroupType; // information values
  onDealerSelect: (list: { [code: string]: boolean }) => void;
  readOnly?: boolean;
  showDealerGroupButton: boolean;
  submitted: boolean;
}

const DealersSection = ({
  dealerOptionsList,
  dealers,
  dealersLoading,
  initialDealerCodes = [],
  onDealerSelect,
  readOnly,
  showDealerGroupButton,
  submitted,
  userType,
}: DealersSectionProps) => {
  const [dealerDD, setDealerDD] = useState<string | undefined>('');
  const [dealerQuery, setDealerQuery] = useState('');
  const [dealerSelectMap, setDealerSelectMap] = useState<{ [code: string]: boolean }>(stringArrayToMap(initialDealerCodes));
  const [filteredDealers, setFilteredDealers] = useState<IDealer[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showAppliedDealers, setShowAppliedDealers] = useState<boolean>(false);
  const [isAllChboxChecked, setIsAllChboxChecked] = useState(false);

  useEffect(() => {
    if (!dealersLoading) {
      setFilteredDealers([...dealers]);
    }
  }, [dealersLoading]);

  // dealer search filters
  const handleDealerFilterQuery = (filter: string) => {
    const filtered = dealers.filter(dealer => {
      const valueString = Object.values(dealer).join().toLowerCase();
      return valueString.indexOf(filter.toLowerCase()) !== -1 && (!dealerDD || dealer.region === dealerDD);
    });

    setDealerQuery(filter);
    setFilteredDealers(filtered);
  };

  const handleOnDealerSelect = ({ value }: SelectOption) => {
    const filtered = dealers.filter(dealer => {
      const valueString = Object.values(dealer).join().toLowerCase();
      return (!dealerQuery || valueString.indexOf(dealerQuery.toLowerCase()) !== -1) && (!value || dealer.region === value);
    });

    setDealerDD(value);
    setFilteredDealers(filtered);
  };

  const hasSelectedDealers = () => {
    return Object.values(dealerSelectMap).some(val => val);
  };

  const handleDealerOnCheck = (checked: boolean, dealerCode: string) => {
    setDealerSelectMap({ ...dealerSelectMap, [dealerCode]: checked });
    onDealerSelect({ ...dealerSelectMap, [dealerCode]: checked });
  };

  const handleDealerSelectAll = (checked: boolean) => {
    setIsAllChboxChecked(!isAllChboxChecked);

    const list: { [code: string]: boolean } = { ...dealerSelectMap };
    filteredDealers.forEach(dealer => {
      list[dealer.dealerCode] = checked;
    });

    setDealerSelectMap(list);
    onDealerSelect(list);
  };
  const onSelect = (currentDealerGroup: DealerGroupType) => {
    if (currentDealerGroup) {
      setDealerSelectMap(stringArrayToMap(currentDealerGroup?.dealers));
      onDealerSelect(stringArrayToMap(currentDealerGroup?.dealers));
    }
  };

  const handeleShowAppliedDealers = () => {
    setShowAppliedDealers(!showAppliedDealers);
  };

  const dealersList = showAppliedDealers ? filteredDealers.filter(dealer => dealerSelectMap[dealer.dealerCode]) : filteredDealers;
  const numberOfDealersSelected = dealers.filter(dealer => dealerSelectMap[dealer.dealerCode]).length;
  const numberOfFilteredDealersSelected = filteredDealers.filter(dealer => dealerSelectMap[dealer.dealerCode]).length;

  useEffect(() => {
    if (numberOfFilteredDealersSelected !== filteredDealers.length) {
      setIsAllChboxChecked(false);
    }
  }, [numberOfFilteredDealersSelected, JSON.stringify(filteredDealers)]);

  return (
    <>
      <FlexBox>
        <DealersSearch>
          <CustomSelect id="dealers-dd" value={dealerDD} onChange={handleOnDealerSelect} options={dealerOptionsList} disabled={dealersLoading} isSearchable={false} />
          <Input id="dealers-input" onChange={e => handleDealerFilterQuery(e.currentTarget.value)} disabled={dealersLoading} />
        </DealersSearch>
        {userType?.type !== UserType.DEALER && showDealerGroupButton && (
          <Button id="dealer-group-btn" variant="primary" onClick={() => setOpenModal(true)} className={styles.dealerGroupBtn} disabled={readOnly || dealersLoading}>
            Select Dealer Group
          </Button>
        )}
      </FlexBox>

      <BlockUI blocking={dealersLoading} showLoader>
        <DealersTable
          validationMessage={
            submitted &&
            !hasSelectedDealers() && (
              <FlexBox>
                <ValidationText>Please select one or more dealers</ValidationText>
              </FlexBox>
            )
          }
        >
          <thead>
            <tr>
              <td>
                <Checkbox id="all-chbox" checked={isAllChboxChecked} onChange={handleDealerSelectAll} disabled={readOnly} />
              </td>
              <td>Region</td>
              <td>Dealer Code</td>
              <td>Dealer Name</td>
              <td>Street</td>
              <td>City</td>
              <td>State</td>
              <td>Zip Code</td>
            </tr>
          </thead>
          <tbody>
            {dealersList.map(dealer => (
              <tr key={dealer.dealerCode}>
                <td>
                  <Checkbox
                    id={`${dealer.dealerCode}-chbox`}
                    checked={dealerSelectMap[dealer.dealerCode] || false}
                    onChange={val => handleDealerOnCheck(val, dealer.dealerCode)}
                    disabled={readOnly}
                  />
                </td>
                <td>{dealer.region}</td>
                <td>{dealer.dealerCode}</td>
                <td>{dealer.dealerName}</td>
                <td>{dealer.street}</td>
                <td>{dealer.city}</td>
                <td>{dealer.state}</td>
                <td>{dealer.zipCode}</td>
              </tr>
            ))}
          </tbody>
        </DealersTable>
        <div className={styles.appliedDealers}>
          <div className={styles.showAppliedDealers}>
            <Checkbox id="showAppliedDealers" checked={showAppliedDealers} onChange={handeleShowAppliedDealers} />
            <p>Show Applied Dealers</p>
          </div>
          <p>Total Number of Dealers Applied: {numberOfDealersSelected}</p>
        </div>
      </BlockUI>
      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <SelectDealerGroupModal onClose={() => setOpenModal(false)} onSelect={onSelect} />
        </Modal>
      )}
    </>
  );
};

export default withUser(DealersSection);
